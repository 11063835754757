import { GlobalAction } from ".";
import { UserServices } from "../services";

export const USER_REQUEST = 'USER_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILURE = 'USER_FAILURE';

export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const ADD_PASSWORD_REQUEST = 'ADD_PASSWORD_REQUEST';
export const ADD_PASSWORD_SUCCESS = 'ADD_PASSWORD_SUCCESS';
export const ADD_PASSWORD_FAILURE = 'ADD_PASSWORD_FAILURE';

export const UPDATE_BILLING_ADDRESS_REQUEST = 'UPDATE_BILLING_ADDRESS_REQUEST';
export const UPDATE_BILLING_ADDRESS_SUCCESS = 'UPDATE_BILLING_ADDRESS_SUCCESS';
export const UPDATE_BILLING_ADDRESS_FAILURE = 'UPDATE_BILLING_ADDRESS_FAILURE';

export const GET_BILLING_ADDRESS_REQUEST = 'GET_BILLING_ADDRESS_REQUEST';
export const GET_BILLING_ADDRESS_SUCCESS = 'GET_BILLING_ADDRESS_SUCCESS';
export const GET_BILLING_ADDRESS_FAILURE = 'GET_BILLING_ADDRESS_FAILURE';

export const GET_ACTIVITY_LOG_REQUEST = 'GET_ACTIVITY_LOG_REQUEST';
export const GET_ACTIVITY_LOG_SUCCESS = 'GET_ACTIVITY_LOG_SUCCESS';
export const GET_ACTIVITY_LOG_FAILURE = 'GET_ACTIVITY_LOG_FAILURE';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAILURE = 'USER_UPDATE_FAILURE';

export const CHECK_BRAND_ACCESS_TOKEN_REQUEST = 'CHECK_BRAND_ACCESS_TOKEN_REQUEST';
export const CHECK_BRAND_ACCESS_TOKEN_SUCCESS = 'CHECK_BRAND_ACCESS_TOKEN_SUCCESS';
export const CHECK_BRAND_ACCESS_TOKEN_FAILURE = 'CHECK_BRAND_ACCESS_TOKEN_FAILURE';

export const UserAction = {

    /**
    * Action for get user details
    * @author Akshay N 
    * @created_at 30 May 2022
    */
    getUserDetails: () => {
        return dispatch => {
            dispatch(request());
            dispatch(GlobalAction.pageLoader(true));
            return UserServices.getUserDetails()
                .then(
                    response => { 
                        dispatch(GlobalAction.pageLoader(false)); 
                        dispatch(dispatch(GlobalAction.setTheme(response.data.is_dark_mode)));
                        return dispatch(success(response)); },
                    error => { return dispatch(failure(error)); }
                );
        };

        function request() { return { type: USER_REQUEST } }
        function success(response) { return { type: USER_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: USER_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
    /**
     * Action for delete account
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    deleteAccount: () => {
        return dispatch => {
            dispatch(GlobalAction.pageLoader(true));
            return UserServices.deleteAccount()
                .then(
                    response => { dispatch(GlobalAction.pageLoader(false)); return response; },
                    error => { return error; }
                );
        };

    },
    /**
     * Action for change password
     * @param {Object} params - The params which are used for change password api.
     * @param {string} params.current_password - Current password of the brand user
     * @param {string} params.new_password - New password the brand user
     * @param {string} params.confirm_password - Password re-entered by the brand user
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    changePassword: (params) => {
        return dispatch => {
            dispatch(request(params));
            return UserServices.changePassword(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CHANGE_PASSWORD_REQUEST, request: request } }
        function success(request, response) { return { type: CHANGE_PASSWORD_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CHANGE_PASSWORD_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action for add password
     * @param {Object} params - The params which are used for change password api.
     * @param {string} params.new_password - New password the brand user
     * @param {string} params.confirm_password - Password re-entered by the brand user
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    addPassword: (params) => {
        return dispatch => {
            dispatch(request(params));
            return UserServices.addPassword(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: ADD_PASSWORD_REQUEST, request: request } }
        function success(request, response) { return { type: ADD_PASSWORD_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: ADD_PASSWORD_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action for add billing address
     * @param {Object} params - The params which are used for the api
     * @param {String} params.address
     * @param {String} params.city
     * @param {String} params.state
     * @param {String} params.country
     * @param {String} params.pincode
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    updateBillingAddress: (params) => {
        return dispatch => {
            dispatch(request(params));
            return UserServices.updateBillingAddress(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: UPDATE_BILLING_ADDRESS_REQUEST, request: request } }
        function success(request, response) { return { type: UPDATE_BILLING_ADDRESS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: UPDATE_BILLING_ADDRESS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to get billing address
     * @param {Object} - null
     * @author 
     * @created_at 30 May 2022
    */
    getBillingAddress: () => {
        return dispatch => {
            dispatch(request());
            return UserServices.getBillingAddress()
                .then(
                    response => { return dispatch(success(response)); },
                    error => { return dispatch(failure(error)); }
                );
        };

        function request() { return { type: GET_BILLING_ADDRESS_REQUEST } }
        function success(response) { return { type: GET_BILLING_ADDRESS_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: GET_BILLING_ADDRESS_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to get Activity list 
     * @param {Object} - null
     * @author 
     * @created_at 30 May 2022
    */
    getActivityList: (params) => {
        return dispatch => {
            dispatch(request(params));
            return UserServices.getActivityList(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: GET_ACTIVITY_LOG_REQUEST, request: request } }
        function success(request, response) { return { type: GET_ACTIVITY_LOG_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: GET_ACTIVITY_LOG_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    /**
     * Action to update theme
     * @author Akshay 
     */
    updateTheme: () => {
        return dispatch => {
            dispatch(request());
            return UserServices.updateTheme()
                .then(
                    response => { dispatch(dispatch(GlobalAction.setTheme(response.data.is_dark_mode))); return dispatch(success(response)); },
                    error => { return dispatch(failure(error)); }
                );
        };
        function request() { return { type: USER_UPDATE_REQUEST } }
        function success(response) { return { type: USER_UPDATE_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: USER_UPDATE_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };

    },

    
    /**
     * Action to check brand access token
     * @param {Object} - null
     * @author 
     * @created_at 30 May 2022
    */
    checkBrandAccessToken: () => {
        return dispatch => {
            dispatch(request());
            return UserServices.checkBrandAccessToken()
                .then(
                    response => { return dispatch(success(response)); },
                    error => { return dispatch(failure(error)); }
                );
        };

        function request() { return { type: CHECK_BRAND_ACCESS_TOKEN_REQUEST } }
        function success(response) { return { type: CHECK_BRAND_ACCESS_TOKEN_SUCCESS, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(error) { return { type: CHECK_BRAND_ACCESS_TOKEN_FAILURE, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
};





