import React from "react";
import { InfluencerCard } from "../../influencer";
// import { RibbonContainer, LeftCornerRibbon } from "react-ribbons";
import { RibbonContainer } from "react-ribbons";
import { Grid } from "@mui/material";


function GiveawayWinnerCard({ giveaway_winner, isLoading }) {

    if (isLoading)
        return 'Loading'

    if (!isLoading && giveaway_winner && Object.keys(giveaway_winner).length > 0) {
        return (
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} >
                <RibbonContainer className="custom-class">
                    <InfluencerCard influencer={giveaway_winner.influencer} />
                    {/* <LeftCornerRibbon backgroundColor="#EB102F" color="#f0f0f0" fontFamily="Arial">
                        Winner
                    </LeftCornerRibbon> */}
                </RibbonContainer>
            </Grid>
        );
    }

    if (!isLoading && giveaway_winner && Object.keys(giveaway_winner).length === 0) {
        return '';
    }
}

export { GiveawayWinnerCard }