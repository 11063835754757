

// import { formatPhoneNumberIntl } from "react-phone-number-input";

import { PostAuthRoutes, PreAuthRoutes } from "../routes";

const preRegisterRoutes = (brand) => {
    if (brand.status === 'insta_register_completed') {
        return { pathname: PreAuthRoutes('create_account').path, search: `?aKey=${brand.approval_key}` }
    } else if (brand.status === 'account_created') {
        return { pathname: PreAuthRoutes('billing_address').path, search: `?aKey=${brand.approval_key}` }
    } else if (brand.status === 'active') {
        return { pathname: PostAuthRoutes('home').path, search: `` }
    } else {
        return { pathname: PreAuthRoutes('login').path, search: `` }
    }
}

export { preRegisterRoutes }