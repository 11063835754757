import { AccountBalance } from "@mui/icons-material";
import { Box, Button, Divider, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getCode, getNames } from "country-list";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormInput, FormPhoneInput, FormSelect, Modal, ModalBody, ModalFooter, ModalFooterCloseBtn, ModalFooterSaveBtn, ModalHeader } from "../../../../_components";
import { handleInputChange, validateForm } from "../../../../_helpers";
import { validate } from ".";
import { PaymentAction } from "../../../../redux/actions";

const inputs = { account_holder_name: '', routing_number: '', confirm_routing_number: '', account_number: '', confirm_account_number: '', account_holder_type: 'individual', name: '', address: '', city: '', state: '', pincode: '', country: '', country_code: '', email: '', mobile_number: '' }


const useStyles = makeStyles((theme) => ({
    bankDetailsGrid: {
        paddingBottom: theme.spacing(2)
    }
}));

// Get country select list
const getCountrySelectList = () => {
    const countries = getNames();
    return countries.map(country => ({
        value: country,
        label: country
    }));
}


function AddUpdateBankDetails() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);

    const classes = useStyles();

    const { user } = useSelector((state) => state.UserReducer);

    const addUpdateBankDetails = (params) => dispatch(PaymentAction.addUpdateBankDetails(params));
    const getPaymentDetails = () => dispatch(PaymentAction.getPaymentDetails());

    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, country: '' });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });

    useEffect(() => {
        setData({
            ...inputs,
            name: user.name,
            card_holder: user.name,
            email: user.email,
            mobile_number: user.mobile_number,
            country: ((user.billing_address) ? (user.billing_address.country) : ''),
            country_code: ((user.billing_address) ? (getCode(user.billing_address.country)) : ''),
            address: ((user.billing_address) ? (user.billing_address.address) : ''),
            city: ((user.billing_address) ? (user.billing_address.city) : ''),
            pincode: ((user.billing_address) ? (user.billing_address.pincode) : ''),
            state: ((user.billing_address) ? (user.billing_address.state) : '')
        })
    }, [user])


    /**
    * function description
    * @param Null
    * @return view
    * @author Akshay N
    * @created_at 
    */
    const handleChange = (e) => {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        if (name === 'country') {
            data['country_code'] = value ? getCode(value) : '';
        }
        setData({ ...data });
    }

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        setAction({ ...action, isSubmitted: true, isProcessing: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ ...action, isSubmitted: true, isProcessing: false });
            return;
        }

        formData.append("account_holder_name", data.account_holder_name);
        formData.append("routing_number", data.routing_number);
        formData.append('account_number', data.account_number);
        formData.append("account_holder_type", data.account_holder_type);
        formData.append("name", data.name);
        formData.append('address', data.address);
        formData.append('city', data.city);
        formData.append('state', data.state);
        formData.append('pincode', data.pincode);
        formData.append('country', data.country);
        formData.append('country_code', data.country_code);
        formData.append('mobile_number', data.mobile_number);
        dispatchAddUpdateBankDetails(data);
    }

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    const dispatchAddUpdateBankDetails = (formData) => {
        addUpdateBankDetails(formData).then((response) => {
            if (response.status === 1) {
                setShow(false);
                setAction({ isSubmitted: false, isProcessing: false });
                setErrors({ ...response.errors })
                getPaymentDetails();
            } else {
                setAction({ isSubmitted: true, isProcessing: false });
                setErrors({ ...response.errors })
            }
        });
    }

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    const addUpdateCardModal = (e) => {
        e.preventDefault();
        setData({
            ...inputs,
            name: user.name,
            card_holder: user.name,
            email: user.email,
            mobile_number: user.mobile_number,
            country: ((user.billing_address) ? (user.billing_address.country) : ''),
            country_code: ((user.billing_address) ? (getCode(user.billing_address.country)) : ''),
            address: ((user.billing_address) ? (user.billing_address.address) : ''),
            city: ((user.billing_address) ? (user.billing_address.city) : ''),
            pincode: ((user.billing_address) ? (user.billing_address.pincode) : ''),
            state: ((user.billing_address) ? (user.billing_address.state) : '')
        });
        setShow(true);
    }

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    const onClose = () => {
        setShow(false);
        setErrors({});
        getPaymentDetails();
    }

    return (
        <Box>

            <Modal isDialogOpen={show} size={`xl`} onClose={onClose} className={`AddUpdateBankDetails`}>
                <form onSubmit={handleSubmit} noValidate >
                    <ModalHeader id="importCoupon" title="Add Bank Account Details" onClose={onClose} />
                    <ModalBody>
                        <Grid container spacing={2} className={classes.bankDetailsGrid}>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="Account Holder Name"
                                    name="account_holder_name"
                                    tabIndex={1}
                                    value={data.account_holder_name}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.account_holder_name)) ? (errors.account_holder_name) : ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Bank Account Type</FormLabel>
                                    <RadioGroup row aria-label="position" name="position" defaultValue="top">
                                        <FormControlLabel value="individual" onChange={handleChange} name={'account_holder_type'} checked={data.account_holder_type === 'individual'} control={<Radio color="primary" />} label="Personal" />
                                        <FormControlLabel value="company" onChange={handleChange} name={'account_holder_type'} checked={data.account_holder_type === 'company'} control={<Radio color="primary" />} label="Business" />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="Routing Number"
                                    name="routing_number"
                                    tabIndex={2}
                                    value={data.routing_number}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.routing_number)) ? (errors.routing_number) : ''}
                                    maxLength={9}
                                    maskType={'digit-only'}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="Confirm Routing Number"
                                    name="confirm_routing_number"
                                    tabIndex={3}
                                    value={data.confirm_routing_number}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.confirm_routing_number)) ? (errors.confirm_routing_number) : ''}
                                    maxLength={9}
                                    maskType={'digit-only'}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="Account Number"
                                    name="account_number"
                                    tabIndex={4}
                                    value={data.account_number}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.account_number)) ? (errors.account_number) : ''}
                                    maxLength={17}
                                    maskType={'digit-only'}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="Confirm Account Number"
                                    name="confirm_account_number"
                                    tabIndex={5}
                                    value={data.confirm_account_number}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.confirm_account_number)) ? (errors.confirm_account_number) : ''}
                                    maxLength={17}
                                    maskType={'digit-only'}
                                />
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid spacing={2} container style={{ marginTop: `20px` }} >
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="Full Name"
                                    name="name"
                                    tabIndex={6}
                                    value={data.name}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.name)) ? (errors.name) : ''}
                                    maxLength={100}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="Address"
                                    name="address"
                                    tabIndex={7}
                                    value={data.address}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.address)) ? (errors.address) : ''}
                                    maxLength={100}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="City"
                                    name="city"
                                    tabIndex={9}
                                    value={data.city}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.city)) ? (errors.city) : ''}
                                    maxLength={100}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="State"
                                    name="state"
                                    tabIndex={10}
                                    value={data.state}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.state)) ? (errors.state) : ''}
                                    maxLength={100}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="Pincode"
                                    name="pincode"
                                    tabIndex={11}
                                    value={data.pincode}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.pincode)) ? (errors.pincode) : ''}
                                    maskType={'digit-only'}
                                    maxLength={10}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormSelect
                                    label="Country"
                                    name="country"
                                    onChange={handleChange}
                                    value={data.country}
                                    error={(action.isSubmitted && (errors.country)) ? (errors.country) : ''}
                                    data={getCountrySelectList()}
                                    tabIndex={12}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormPhoneInput
                                    tabIndex={13}
                                    label='Mobile Number'
                                    name='mobile_number'
                                    value={data.mobile_number}
                                    error={action.isSubmitted && errors.mobile_number ? errors.mobile_number : ''}
                                    onChange={handleChange}
                                    maskType={`digit-only`}
                                    inputProps={{ maxLength: 12 }}
                                    autoComplete={`mobile_number`}
                                    placeholder="Mobile number"

                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Box className="col-md-12">
                                    {/* <p>Terms and Conditions for Brands are at <a href="https://guruclub.wpengine.com/terms-of-service-brands" target="_blank" rel="noopener noreferrer">https://guruclub.wpengine.com/terms-of-service-brands</a></p> */}
                                </Box>
                            </Grid>
                        </Grid>
                    </ModalBody>
                    <ModalFooter>
                        <ModalFooterSaveBtn tabIndex={14} loading={action.isProcessing} text="Ok" />
                        <ModalFooterCloseBtn tabIndex={15} onClose={onClose} text="Close" />
                    </ModalFooter>
                </form>
            </Modal>
            <Button style={{ marginTop: '10px' }} variant="contained" color="primary" startIcon={<AccountBalance>Add</AccountBalance>} onClick={addUpdateCardModal}>Add Bank Details</Button>
        </Box>
    )
}

export { AddUpdateBankDetails }

