import { Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, Skeleton } from '@mui/material';
import { styled } from "@mui/material/styles";
import React from 'react';
import couponSvg from './coupon-svg.svg'

//Initialize Colors
const colors = { 'active': '#2B4FA3', 'inactive': '#cd4e4f', 'processing': '#cd4e4f', 'done': '#555' }

//Initialize promo card style
const PromoCard = styled(({ ...props }) => (<Card {...props} />))`
        padding: 0;
        height:150px;
        background-color: ${props => (colors[props.status])};
` ;

function CouponCodeCardLoader({ statusUpdateModal, ...props }) {

    var rows = [];
    for (var i = 0; i < 20; i++) {
        rows.push(
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} key={i}    {...props}>
                <PromoCard {...props} >
                    <CardActionArea style={{ height: `100%` }}>
                        <CardMedia style={{ position: "absolute", padding: 0, bottom: 0, right: 0, top: 0, width: "40%", opacity: '0.1' }} component="img" image={couponSvg} />
                        <CardContent>
                            <Skeleton animation="wave" height={10} width="90%" style={{ marginBottom: 5 }} />
                            <Skeleton animation="wave" height={10} width="70%" style={{ marginBottom: 5 }} />
                        </CardContent>
                    </CardActionArea>
                    <CardActions style={{ backgroundColor: '#0000002e', borderBottomLeftRadius: `0.75rem`, borderBottomRightRadius: `0.75rem` }} >
                        <Skeleton animation="wave" height={10} width="20%" style={{ marginBottom: 5 }} />
                        <Skeleton animation="wave" height={10} width="20%" style={{ marginBottom: 5 }} />
                    </CardActions>
                </PromoCard>
            </Grid>
        );
    }

    return <React.Fragment > {rows} </React.Fragment>
}

export { CouponCodeCardLoader };
