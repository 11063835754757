import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { handleInputChange, validateForm } from "../../../_helpers";
import { validate } from ".";
import { FormInput } from "../../../_components/form";
import { Grid } from "@mui/material";
import { Modal, ModalBody, ModalFooter, ModalFooterCloseBtn, ModalFooterSaveBtn, ModalHeader, SweetAlert } from "../../../_components";
import { WalletAction } from "../../../redux/actions";

// Initialize form input values to null
const inputs = { amount: '' };

function CreateWallet({ ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();
    let { search } = useLocation();
    
    const params = new URLSearchParams(search);
    const sessionId = params.get('session_id'); // story id from url

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });

    /** Initialize values from props */
    const { show, closeModal } = props;

    /** Redux actions and state */
    const { create_brand_wallet_loading: isLoading, create_wallet_checkout_session_loading: isCheckoutLoading } = useSelector((state) => state.WalletReducer);
    const createBrandWallet = (params) => dispatch(WalletAction.createBrandWallet(params));
    const createWalletCheckoutSession = (params) => dispatch(WalletAction.createWalletCheckoutSession(params));
    const getWalletChart = () => dispatch(WalletAction.getWalletChart());
    const getWalletMonthlyCredit = (params) => dispatch(WalletAction.getWalletMonthlyCredit(params));
    const getWalletTotalCredit = (params) => dispatch(WalletAction.getWalletTotalCredit(params));

    /**
     * function to close wallet model
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    const onClose = () => {
        closeModal(false);
    }

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     * @created_at 14 June 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit entered values
     * @param {object} e form object
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    function handleSubmit(e) {
        e.preventDefault();
        const formData = new FormData();
        
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        dispatchAction(formData);

    }

    const dispatchAction = async (formData) => {
        createWalletCheckoutSession(data).then((result) => {
            closeModal(false);
            if (result.status === 1) {
                window.location.href = result.data;
            }
        });
    }

    useEffect(() => {
        if (sessionId) {
            let timerInterval;
            SweetAlert.fire({
                title: 'Wallet update initiated',
                text: "Please wait...",
                icon: 'warning',
                showCancelButton: false,
                reverseButtons: false,
                allowOutsideClick: false,
                showConfirmButton: false,
            });

            setTimeout(() => {
                createBrandWallet({ session_id: sessionId }).then((result) => {
                    SweetAlert.fire({
                        title: `${result.message}`,
                        icon: 'success',
                        html: "Money added to your wallet",
                        timer: 2000,
                        timerProgressBar: true,
                        didOpen: () => {
                            SweetAlert.showLoading()
                            const b = SweetAlert.getHtmlContainer().querySelector('b');
                            console.log(b);
                            timerInterval = setInterval(() => { b.textContent = SweetAlert.getTimerLeft() }, 200)
                        },
                        willClose: () => {
                            clearInterval(timerInterval)

                            const queryParams = new URLSearchParams(search)

                            if (queryParams.has('success')) {
                                queryParams.delete('success')
                            }
                            if (queryParams.has('session_id')) {
                                queryParams.delete('session_id')
                            }
                            history.replace({
                                search: queryParams.toString(),
                            })
                                /** Redux reducer actions */

                            getWalletChart();
                            getWalletMonthlyCredit({type:"monthly"});
                            getWalletTotalCredit({type:"total"});


                        }
                    })
                });
            }, 2000);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionId])

    return (
        <Modal isDialogOpen={show} onClose={onClose} maxWidth='sm'>
            <form onSubmit={handleSubmit} noValidate >
                <ModalHeader id="createWallet" onClose={onClose} title={`Add Money`} />
                <ModalBody>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormInput
                                tabIndex={2}
                                label='Amount (RS)'
                                name='amount'
                                value={data.amount}
                                error={action.isSubmitted && errors.amount ? errors.amount : ''}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                    <ModalFooter justifyContent={'center'}>
                        <ModalFooterSaveBtn tabIndex={4} text='Confirm' onClick={handleSubmit} loading={isLoading || isCheckoutLoading}/>
                        <ModalFooterCloseBtn tabIndex={3} onClose={onClose} />
                    </ModalFooter>
                </ModalBody>
            </form>
        </Modal>
    )
}

export { CreateWallet };
