import React from 'react'

import Carousel from 'react-material-ui-carousel'

const MediaCarousel = ({ ...props }) => {
	

	return (
		<Carousel navButtonsAlwaysVisible={true} indicators={false} autoPlay={false} animation={'slide'} duration={700} width="100%" {...props}>
            {props.children}
        </Carousel>
	);
};

export { MediaCarousel };
