import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Chip, Stack, Typography } from '@mui/material';
import { styled } from "@mui/material/styles";
import React from 'react';
import couponSvg from './coupon-svg.svg'
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Linkify from 'react-linkify';
import { GlobalAction, SearchAction } from '../../../redux/actions';
import { PostAuthRoutes } from '../../../routes';
import { useState } from 'react';
import { RedeemCouponModal } from '../redeem-coupon-modal';

/** Initialize colors */
const colors = { 'active': '#2B4FA3', 'inactive': '#cd4e4f', 'processing': '#cd4e4f', 'done': '#00000087' }

/** Initialize CouponCode card style */
const CouponCard = styled(({ ...props }) => (<Card {...props} />))`
        padding: 0;
        background-color: ${props => (colors[props.status])};
        & .MuiButtonBase-root{
            &:hover{
                border-radius:12px;
            }
        }
` ;

function CouponCodeCard({ statusUpdateModal, height = `150px`, hasAction = true, canChangeStatus = true, isClickable = false, canRedeem = false, ...props }) {

    /** Initialize values from props */
    const { coupon_code, status, _id, story, description, completed_at, promo, category, redeemed_at } = props;

    /** Initialize plugins and variables */
    let history = useHistory();
    const dispatch = useDispatch();

    /** Redux actions and state */
    const notify = (msg) => dispatch(GlobalAction.showToastMessage(msg));
    const redeemCoupon = (params) => dispatch(SearchAction.redeemCoupon(params));
    const giveawayRedeemCoupon = (params) => dispatch(SearchAction.giveawayRedeemCoupon(params));

    /** Initialize and declare state */
    const [showRedeemCouponModal, setShowRedeemCouponModal] = useState(false);
    const [redeemData, setRedeemData] = useState({ coupon_id: '', promo_id: '', remark: '' });

    /**
     * function to change status
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    function handleStatusChangeClick() {
        setTimeout(() => {
            statusUpdateModal({ id: _id, status: status, item: coupon_code });
        }, 100);
    }

    /**
     * function to open/close redeem coupon Update modal
     * @param {string} null 
     * @return view
     * @author
     * @created_at 21 June 2022
     */
    const redeemCouponModal = ({ coupon_id, promo_id, remark }) => {
        setRedeemData({ coupon_id: coupon_id, promo_id: promo_id, remark: remark });
        setShowRedeemCouponModal(!showRedeemCouponModal);
    }

    /**
     * function to update support center status
     * @param {string} id - Id of the data which we need to update.
     * @return view
     * @author 
     * @created_at 21 June 2022
     */
    const redeemCouponAction = async () => {
        if (category && category === 'giveaway') {
            await giveawayRedeemCoupon(redeemData).then((result) => {
                if (result.status === 1) {
                    dispatch({ type: 'GIVEAWAY_REDEEM_COUPON', data: result.data})
                    setRedeemData({ coupon_id: '', promo_id: '', remark: '' });
                    setShowRedeemCouponModal(!showRedeemCouponModal);
                }
            });
        } else {
            await redeemCoupon(redeemData).then((result) => {
                if (result.status === 1) {
                    dispatch({ type: 'PROMO_REDEEM_COUPON', data: result.data})
                    setRedeemData({ coupon_id: '', promo_id: '', remark: '' });
                    setShowRedeemCouponModal(!showRedeemCouponModal);
                }
            });
        }
    }
    /**
     * function to handle click to direct to story details page
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    function handleOnCouponClick() {
        if (isClickable) {
            if (story._id) {
                history.push({
                    pathname: PostAuthRoutes('stories_detail').path,
                    search: `?sId=${story._id}`
                });
            } else
                notify('Coupon code is not allocated.');
        }
    }

    /**
     * function to handle click to direct to story details page
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    function handleOnRedeemCouponClick() {
        redeemCouponModal({coupon_id: _id, promo_id: promo._id, remark: ''});
    }

    return (
        <React.Fragment>
            <CouponCard {...props} style={{ height: height }}>
                <CardActionArea style={{ height: `100%` }} onClick={handleOnCouponClick}>
                    <CardMedia style={{ position: "absolute", padding: 0, bottom: 0, right: 0, top: 0, width: "40%", opacity: '0.1' }} component="img" image={couponSvg} />
                    <CardContent style={{ display: `flex`, alignItems: `center`, paddingBottom: 0, pt: 0 }}>
                        <Stack direction="column" justifyContent="center" alignItems="flex-start">
                            <Typography variant={`h4`} color={`#fff`} style={{ cursor: "pointer" }}>{coupon_code} {(!canChangeStatus || !hasAction) && <Chip size={`small`} label={status.toUpperCase()} />}</Typography>
                            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
                                <a target="blank" href={decoratedHref} key={key}>
                                    {decoratedText}
                                </a>
                            )}>
                                <Typography variant={`caption`} color={`#fff`} style={{ paddingTop: 2, paddingBottom: 3 }}>{description}</Typography></Linkify>
                            {promo && promo.name && <Typography variant={`body2`} sx={{ fontWeight: 500 }} >{promo.name}</Typography>}
                            {completed_at && <Typography variant={`caption`} sx={{ fontWeight: 500, mt: `2px` }} >{completed_at}</Typography>}
                            {redeemed_at && <Typography variant={`caption`} sx={{ fontWeight: 500, mt: `2px` }} >{redeemed_at}</Typography>}

                        </Stack>
                    </CardContent>
                </CardActionArea>
                {
                    hasAction &&
                    (
                        <CardActions style={{ backgroundColor: '#0000002e', borderBottomLeftRadius: `0.75rem`, borderBottomRightRadius: `0.75rem` }} >
                            {canChangeStatus && <Button size="small" style={{ color: `#fff` }} disabled={(status === 'active' || status === 'inactive') ? false : true} onClick={handleStatusChangeClick}>{status.toUpperCase()}</Button>}
                            <Button size="small" style={{ color: `#fff` }} disabled={(status === 'active' || status === 'inactive') ? false : true} onClick={handleOnCouponClick}>Details</Button>
                            {(canRedeem && (status === 'done')) && <Button size="small" style={{ color: `#fff` }} disabled={(status === 'done') ? false : true} onClick={() => handleOnRedeemCouponClick()}>Redeem Coupon</Button>}
                        </CardActions>
                    )
                }
            </CouponCard>
            <RedeemCouponModal show={showRedeemCouponModal} closeModal={redeemCouponModal} data={redeemData} redeemCoupon={redeemCouponAction} />
        </React.Fragment>
    );
}

export { CouponCodeCard };
