import React from 'react'
import { useHistory } from 'react-router-dom'
import { Instagram, Email, Phone, CheckCircle } from '@mui/icons-material'
import { Box, CardActionArea, CardHeader, CardMedia, Chip, Grid, Typography } from '@mui/material'
import { PostAuthRoutes } from '../../../routes'
import { Card, CardContent } from '../../../_components/card'
import { AVATAR_BROKEN_IMAGE } from '../../../_helpers'

function InfluencerCard({ influencer, height = `100%` }) {

    /** Initialize plugins and variables */
    const history = useHistory();

    /** Initialize form values from influencer */
    const { _id, status, insta_profile, email, insta_handle, email_verify_status, mobile_number, mobile_verify_status } = influencer;

    /**
     * function to handle click to direct to influencer details page
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    function handleDetailsClick() {
        history.push({
            pathname: PostAuthRoutes('influencer_details').path,
            search: `?influencerId=${_id}`
        });
    }

    return (
        <Card >
            <CardActionArea onClick={handleDetailsClick} sx={{ height: height, alignItems: `center`, justifyContent: `center` }}>
                <CardHeader action={
                    <React.Fragment>
                        <Chip size='small' label={status === 'active' ? 'Active' : 'Inactive'} color={status === 'active' ? 'success' : 'error'} />
                    </React.Fragment>
                }>
                </CardHeader>
                <CardContent>
                    <Grid container spacing={3} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ paddingTop: 0 }}>
                            <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: `200px` }}>
                                <CardMedia height={`100%`} component={"img"} src={insta_profile.profile_picture_url ? insta_profile.profile_picture_url : 'IMAGE_NOT_AVAILABLE'} onError={e => { e.target.src = AVATAR_BROKEN_IMAGE }} />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} alignItems={`center`} justifyContent={`center`} sx={{ minHeight: `137px` }}>
                            <Typography variant="h5" align='center' mb={1}>{`${insta_profile.name ? insta_profile.name : '-'}`}</Typography>
                            <Typography variant="h6" align='center' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}><Instagram />&nbsp;{`@${insta_handle}`}</Typography>
                            {email && <Typography variant="h6" align='center' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}><Email />&nbsp;{`${email}`}&nbsp;{email_verify_status ? <CheckCircle color='success' size='small' /> : <CheckCircle color='light' size='small' />}</Typography>}
                            {mobile_number && <Typography variant="h6" align='center' sx={{ display: `flex`, alignItems: `center`, justifyContent: `center` }}><Phone />&nbsp;{`${mobile_number}`}&nbsp;{mobile_verify_status ? <CheckCircle color='success' size='small' /> : <CheckCircle color='light' size='small' />}</Typography>}
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} display={`flex`} alignItems={`center`} justifyContent={`center`} mt={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box sx={{ backgroundColor: 'info.main', width: `100%`, p: 1 }}  >
                                <Typography variant="h6" color={`white`} align={`center`}>{insta_profile.followers_count}</Typography>
                                <Typography variant="body2" color={`white`} align={`center`}>{`Followers`}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardActionArea>
        </Card>
    )

}

export { InfluencerCard }