import React, { useState } from 'react';
import { Box, Card, Grid, Stack } from '@mui/material';
import { PostAuthRoutes } from '../../../routes'
import { CardContent, CardHeader } from '../../../_components/card'
import { GeneralButton, FormSearchInput } from '../../../_components';
import { FilterAlt } from '@mui/icons-material';
import { WalletStatementsDataTable } from './WalletStatementsDataTable';
import { WalletStatementsFilter } from './wallet-statements-filter';
import { useLocation } from 'react-router';

function WalletStatements() {

    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    /** Initialize and declare state */
    const [payload, setPayload] = useState({ limit: 10, page: 1, filter: {}, search: searchParams.get('q') ?? '' });
    const [showStatementFilterModal, setShowStatementFilterModal] = useState(false);

    
    /**
     * function to open/close wallet filter modal
     * @param {string} null 
     * @return view
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const statementFilterModal = () => {
        setShowStatementFilterModal(!showStatementFilterModal);
    }

    /**
     * function to apply the selected filter options on the wallet list
     * @param {Object} filterData 
     * @param {String} filterData.status Selected status from the wallet filter modal
     * @return view
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const applyFilter = (filterData) => {
        setPayload({ ...payload, page: 1, filter: filterData });
    }

    /**
     * function to apply the search key words on the ticket list
     * @param {String} text Search keyword used
     * @return view
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    }

    return (
        <React.Fragment>
            <Box>
                <CardHeader
                    title={PostAuthRoutes('wallet_statements').name}
                    action={
                        <Stack spacing={1} direction="row">
                            <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                            <GeneralButton label={<FilterAlt />} onClick={() => statementFilterModal()} />
                        </Stack>
                    }
                />
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <WalletStatementsFilter show={showStatementFilterModal} closeModal={statementFilterModal} applyFilter={applyFilter} statementFilter={payload.filter} />
                    <Card>
                        <CardContent>
                            <WalletStatementsDataTable limit={payload.limit} page={payload.page} filter={payload.filter} search={payload.search}/>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export { WalletStatements }