import { apiServices, cookieServices } from '../../_helpers';


export const UserServices = {

    /**
     * Service for get user details
     * @param {Object} params - The params which are used for the api
     * @author Akshay N 
     * @created_at 30 May 2022 
     */
    getUserDetails: () => {
        return apiServices.get('/brand')
            .then((response) => {
                let date = new Date();
                date.setTime(date.getTime() + (30 * 60 * 24 * 60 * 1000));
                const options = { path: '/', expires: date };
                cookieServices.set('isDarkMode', response.data.is_dark_mode, options);
                return response;
            })
    },
    /**
     * Service for delete account
     * @param {Object} null
     * @author Akshay N 
     * @created_at 30 May 2022 
     */
    deleteAccount: () => {
        return apiServices.put('/brand/delete')
            .then((response) => { cookieServices.remove('accessToken'); return response; })
    },
    /**
     * Service for change password
     * @param {Object} params - The params which are used for change password api.
     * @param {string} params.current_password - Current password of the brand user
     * @param {string} params.new_password - New password the brand user
     * @param {string} params.confirm_password - Password re-entered by the brand user
     * @author Akshay N
     * @created_at 30 May 2022 
     */
    changePassword: (params) => {

        return apiServices.put('/brand/change-password', params)
            .then((response) => { cookieServices.remove('accessToken'); return response; })
    },

    /**
     * Service for add password
     * @param {Object} params - The params which are used for change password api.
     * @param {string} params.new_password - New password the brand user
     * @param {string} params.confirm_password - Password re-entered by the brand user
     * @author Akshay N
     * @created_at 30 May 2022 
     */
    addPassword: (params) => {

        return apiServices.put('/brand/add-password', params)
            .then((response) => { cookieServices.remove('accessToken'); return response; })
    },

    /**
     * Service to add billing address
     * @param {Object} params - The params which are used add billing address api.
     * @param {String} params.address
     * @param {String} params.city
     * @param {String} params.state
     * @param {String} params.country
     * @param {String} params.pincode
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    updateBillingAddress: (params) => {
        return apiServices.put('/brand/update-billing-address', params)
            .then((response) => { return response; })
    },

    /**
     * Service to get billing address
     * @param {Object} params - The params which are used for the api
     * @author Akshay N 
     * @created_at 30 May 2022 
     */
    getBillingAddress: () => {
        return apiServices.get('/brand/billing-address',)
            .then((response) => { return response; })
    },

    /**
     * Service to get login activity list
     * @param {Object} params - The params which are used for the api
     * @author Akshay N 
     * @created_at 30 May 2022 
     */
    getActivityList: (params) => {
        return apiServices.post('/brand/activity-log/list', params)
            .then((response) => { return response; })
    },
    /**
     * Service to update theme
     *
     * @author Akshay 
     */
    updateTheme: () => {
        return apiServices.put('/brand/update/theme')
            .then((response) => {
                let date = new Date();
                date.setTime(date.getTime() + (30 * 60 * 24 * 60 * 1000));
                const options = { path: '/', expires: date };
                cookieServices.set('isDarkMode', response.data.is_dark_mode, options);
                return response;
            })
    },

    /**
     * Service to check brand access token
     *
     * @author Jasin 
     */
    checkBrandAccessToken: () => {
        return apiServices.get('/brand/check-access-token')
            .then((response) => { return response; })
    },

};
