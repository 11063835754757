import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid } from '@mui/material'
import { PostAuthRoutes } from '../../routes'
import { Card, CardContent, CardHeader } from '../../_components/card'
import { Calendar } from '../../_components';
import { BrandCalendarAction } from '../../redux/actions';
import { useHistory } from 'react-router-dom';

function BrandCalendar() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    const { brand_calendar } = useSelector((state) => state.BrandCalendarReducer);

    const getBrandCalendarData = (params) => dispatch(BrandCalendarAction.getBrandCalendar(params));

    const handleViewRender = async ({ start, end }) => {
        await getBrandCalendarData({
            start: new Date(start).toISOString(),
            end: new Date(end).toISOString()
        });
    };

    function handleClick(e) {

        let type = e.event.extendedProps.type;
        let id = e.event.extendedProps._id;

        if (type === 'promo') {
            history.push({
                pathname: PostAuthRoutes('promo_details').path,
                search: `?pId=${id}`
            });
        } else if (type === 'giveaway') {
            history.push({
                pathname: PostAuthRoutes('giveaway_details').path,
                search: `?gId=${id}`
            });
        }
    }

    return (
        <React.Fragment>
            <Box>
                <CardHeader title={PostAuthRoutes('calendar').name} />
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Card>
                        <CardContent>
                            <Calendar events={brand_calendar} handleViewRender={handleViewRender} handleClick={handleClick} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export { BrandCalendar }