import { Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux'
import { PostAuthRoutes } from "../../../../routes";
import { UserAction } from "../../../../redux/actions";
import React, { useEffect, useState } from "react";
import { handleInputChange, validateForm } from "../../../../_helpers";
import { validate } from '.'
import { FormInput, FormSelect } from "../../../../_components/form";
import { SubmitButton } from "../../../../_components/controls";
import { useHistory } from "react-router-dom";
import { Container } from "@mui/system";
import { Card, CardHeader, CardContent } from "../../../../_components";
const { getNames } = require('country-list');


// Initialize form input values to null
const inputs = { address: '', city: '', state: '', country: '', pincode: '' };

function UpdateBillingAddress() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    /** Redux reducer state */
    const { update_billing_address_loading: isLoading, update_billing_address_errors: updateBillingAddressErrors, billing_address, billing_address_loading: isBillingAddressLoading } = useSelector((state) => state.UserReducer);

    /** Redux reducer actions */
    const updateBillingAddress = (params) => dispatch(UserAction.updateBillingAddress(params));
    const getBillingAddress = () => dispatch(UserAction.getBillingAddress());

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });

    // Get country select list
    const getCountrySelectList = () => {
        const countries = getNames();
        return countries.map(country => ({
            value: country,
            label: country
        }));
    }

    useEffect(() => {
        setErrors({ ...updateBillingAddressErrors });
    }, [updateBillingAddressErrors])

    useEffect(() => {
        getBillingAddress();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * function to handle input changes and alter the value
     * @param object e input object with name and value
     * @author Akshay N
     * @created_at 30 May 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit
     * @param object e form object
     * @author Akshay N
     * @created_at 30 May 2022
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        dispatchAddBillingAddressAction();
    }

    /**
     * function to dispatch sign in
     * @author Akshay N
     * @created_at 30 May 2022
     */
    async function dispatchAddBillingAddressAction() {
        const isUpdated = await updateBillingAddress(data);
        if (isUpdated && isUpdated.status === 1) {
            history.push({
                pathname: PostAuthRoutes('billing_address').path
            });
        }
    }

    useEffect(() => {
        if (Object.keys(billing_address).length > 0) {
            setData({
                address: billing_address.address,
                city: billing_address.city,
                state: billing_address.state,
                country: billing_address.country,
                pincode: billing_address.pincode,
            });
            // setPromoLoading(false);
        } else {
            // setPromoLoading(false);
        }
    }, [billing_address])

    return (
        <Container>
            <CardHeader title={PostAuthRoutes('update_billing_address').name} />
            <Card>
                <CardContent sx={{ minHeight: `60vh`, display: `flex`, alignItems: `center`, justifyContent: `center` }}>
                    <form onSubmit={handleSubmit} noValidate>
                        <Grid container spacing={2} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="h6">Please add or update your billing address.</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <FormInput
                                            tabIndex={1}
                                            label='Address'
                                            name='address'
                                            value={data.address}
                                            error={action.isSubmitted && errors.address ? errors.address : ''}
                                            onChange={handleChange}
                                            autoFocus={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <FormInput
                                            tabIndex={2}
                                            label='City'
                                            name='city'
                                            value={data.city}
                                            error={action.isSubmitted && errors.city ? errors.city : ''}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <FormInput
                                            tabIndex={3}
                                            label='State'
                                            name='state'
                                            value={data.state}
                                            error={action.isSubmitted && errors.state ? errors.state : ''}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <FormSelect
                                            tabIndex={4}
                                            label='Country'
                                            name='country'
                                            value={data.country}
                                            error={action.isSubmitted && errors.country ? errors.country : ''}
                                            onChange={handleChange}
                                            data={getCountrySelectList()}
                                            placeholder={`Select country`}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <FormInput
                                            tabIndex={5}
                                            label='Pincode'
                                            name='pincode'
                                            value={data.pincode}
                                            error={action.isSubmitted && errors.pincode ? errors.pincode : ''}
                                            onChange={handleChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                                        <SubmitButton label={'Update Billing Address'} fullWidth color={`secondary`} loading={isLoading || isBillingAddressLoading} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </Container>
    )
}

export { UpdateBillingAddress };
