import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Grid, Stack } from "@mui/material";
import { Add, FilterAlt } from "@mui/icons-material";
import { PostAuthRoutes } from "../../routes";
import { Card, CardContent, CardHeader } from "../../_components/card";
import { FormSearchInput } from "../../_components/form";
import { GeneralButton } from "../../_components/controls";
import { TicketsDataTable, TicketsFilter, UpdateStatusModal } from '.'
import { TicketAction } from '../../redux/actions';

function Tickets() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    /** Redux actions */
    const changeStatus = (params) => dispatch(TicketAction.changeStatus(params));

    /** Initialize and declare state */
    const { per_page, page } = useSelector((state) => state.TicketReducer);
    const [payload, setPayload] = useState({ limit: per_page, page: page, filter: { status: searchParams.get('status') ?? '' }, search: searchParams.get('q') ?? '' });
    const [showTicketsFilterModal, setShowTicketsFilterModal] = useState(false);

    const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
    const [statusData, setStatusData] = useState({ id: '', status: '', item: '' });

    /**
     * function to handle click to direct to create ticket page
     * @author Akshay N
     * @created_at 30 May 2022
     */
    function createTicket() {
        history.push({
            pathname: PostAuthRoutes('create_ticket').path,
        });
    }

    /**
     * function to open/close ticket filter modal
     * @param {string} null 
     * @return view
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const ticketsFilterModal = () => {
        setShowTicketsFilterModal(!showTicketsFilterModal);
    }

    /**
     * function to apply the selected filter options on the ticket list
     * @param {Object} filterData 
     * @param {String} filterData.status Selected status from the ticket filter modal
     * @return view
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const applyFilter = (filterData) => {
        setPayload({ ...payload, page: 1, filter: filterData });
    }

    /**
     * function to apply the search key words on the ticket list
     * @param {String} text Search keyword used
     * @return view
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    }

    /**
     * function to open/close status update modal
     * @param {string} id - Id of the data which we need to update.
     * @param {string} status -  Current status of the data which we need to update.
     * @param {string} item - Current item of the data which we need to update.
     * @return view
     * @author 
     * @created_at 31 May 2022
     */
    const statusUpdateModal = ({ id, status, remark }) => {
        setStatusData({ id: id, status: status, remark: remark });
        setShowStatusUpdateModal(!showStatusUpdateModal);
    }

    /**
     * function to update ticket status
     * @param {string} id - Id of the data which we need to update.
     * @return view
     * @author 
     * @created_at 31 May 2022
     */
    const updateStatusAction = async ({ id, current_status, ticket_change_status, remark }) => {
        setShowStatusUpdateModal(!showStatusUpdateModal);
        await changeStatus({ 'ticket_id': id, current_status, ticket_change_status, remark });
    }

    return (
        <React.Fragment>
            <CardHeader title={PostAuthRoutes('tickets').name} action={
                <Stack spacing={1} direction="row">
                    <GeneralButton startIcon={<Add />} label={`Create Ticket`} onClick={() => createTicket()} />
                    <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                    <GeneralButton label={<FilterAlt />} onClick={() => ticketsFilterModal()} />
                </Stack>
            } />
            <TicketsFilter show={showTicketsFilterModal} closeModal={ticketsFilterModal} applyFilter={applyFilter} ticketsFilter={payload.filter} />
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                    <Card>
                        <CardContent>
                            <TicketsDataTable statusUpdateModal={statusUpdateModal} filter={payload.filter} search={payload.search} limit={payload.limit} page={payload.page} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <UpdateStatusModal show={showStatusUpdateModal} closeModal={statusUpdateModal} data={statusData} updateStatus={updateStatusAction} />
        </React.Fragment>

    )

}

export { Tickets };