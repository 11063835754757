import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { PostAuthRoutes } from "../../../routes";
import { APP_NAME, handleInputChange, validateForm } from "../../../_helpers";
import { validate } from ".";
import { CancelButton, SubmitButton } from "../../../_components/controls";
import { FormBrowseFile, FormDateTimePicker, FormInput, FormRadio, FormSelect } from "../../../_components/form";
import { Card, CardContent, CardHeader } from "../../../_components/card";
import { Box, Grid, Stack, Alert } from "@mui/material";
import { PromoAction } from "../../../redux/actions";
import { Info } from '@mui/icons-material';
import sampleCouponFile from "./sample-coupon-file.csv"

// Initialize form input values to null
const inputs = { promo_id: '', name: '', insta_handle: '', description: '', promo_type: 'story', file: '', coupon_file: '', promo_start_date: '', promo_end_date: '', number_of_promos: '', payment_method: 'coupon', promo_cost_per_influencer: '', issue_coupons_at: 'after_24_hours', coupon_message: '', thank_you_message: '', start_date: '', time_between_post: '' };

// Declare promo types select options
const promoType = [{ value: 'story', label: 'Story' }]

// Declare promo payment methods select options
const paymentMethod = [{ value: 'coupon', label: 'Coupon' }]

// Declare issue coupons at select options
const issueCouponsAt = [{ value: 'instantly', label: 'Instantly' }, { value: 'after_24_hours', label: 'After 24 hours' }]

// Declare time between post select options
const timeBetweenPostOptions = [
    { value: 'one_time', label: 'One time' },
    { value: 'once_in_a_day', label: 'Once in a day' },
    { value: 'once_in_a_week', label: 'Once in a week' },
    { value: 'once_in_a_month', label: 'Once in a monh' },

]

function CreateOrUpdatePromo() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { promo } = location.state ?? {};

    /** Redux actions */
    const createPromo = (params) => dispatch(PromoAction.createPromo(params));
    const updatePromo = (params) => dispatch(PromoAction.updatePromo(params));

    /** Redux reducer states */
    const { create_promos_loading: isLoading } = useSelector((state) => state.PromoReducer);
    const { user_loading: isUserLoading, user } = useSelector((state) => state.UserReducer);

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, promo_type: '', payment_method: '', issue_coupons_at: '' });
    const [action, setAction] = useState({ isSubmitted: false });
    const [isPromoLoading, setIsPromoLoading] = useState(true);

    useEffect(() => {
        if (promo && promo._id) {
            setData({
                promo_id: promo._id,
                name: promo.name,
                insta_handle: promo.insta_handle,
                description: promo.description,
                promo_type: promo.promo_type,
                file: promo.file,
                coupon_file: promo.coupon_code_file,
                promo_start_date: (promo.promo_start_date ? (new Date(promo.promo_start_date.split(" ").map(part => part.split("/").reverse().join("/")).join(" ")).toISOString()) : ''),
                promo_end_date: (promo.promo_end_date ? (new Date(promo.promo_end_date.split(" ").map(part => part.split("/").reverse().join("/")).join(" ")).toISOString()) : ''),
                number_of_promos: promo.number_of_promos,
                payment_method: promo.payment_method,
                promo_cost_per_influencer: promo.promo_cost_per_influencer,
                issue_coupons_at: promo.issue_coupons_at ?? '',
                coupon_message: promo.coupon_message ?? '',
                thank_you_message: promo.thank_you_message ?? '',
                start_date: (promo.promo_start_date ? (new Date(promo.promo_start_date.split(" ").map(part => part.split("/").reverse().join("/")).join(" ")).toISOString()) : ''),
                time_between_post: promo.time_between_post ?? ''
            });
            setIsPromoLoading(false);
        } else {
            setIsPromoLoading(false);
        }
    }, [promo])

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const handleChange = async (e) => {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle create/update promo form submit action
     * @param {object} e form object
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        formData.append("promo_id", data.promo_id);
        formData.append("name", data.name);
        formData.append("insta_handle", data.insta_handle);
        formData.append("description", data.description);
        formData.append("promo_type", data.promo_type);
        formData.append("file", data.file);
        formData.append("promo_start_date", data.promo_start_date);
        formData.append("promo_end_date", data.promo_end_date);
        formData.append("number_of_promos", data.number_of_promos);
        formData.append("payment_method", data.payment_method);
        formData.append("promo_cost_per_influencer", data.promo_cost_per_influencer);
        formData.append("coupon_file", data.coupon_file);
        formData.append("issue_coupons_at", data.issue_coupons_at);
        formData.append("coupon_message", data.coupon_message);
        formData.append("thank_you_message", data.thank_you_message);
        formData.append("time_between_post", data.time_between_post);
        dispatchCreateOrUpdatePromoAction(formData);
    }

    /**
     * function to dispatch create/update promotion api
     * @param {Object} formData - The params which are used for the api
     * @param {string} formData.promo_id - Promo id which used for update promo
     * @param {string} formData.name - Promo name
     * @param {string} formData.insta_handle - Promo instagram handle
     * @param {string} formData.description - Promo description
     * @param {string} formData.promo_type - Promo type chosen form the select box
     * @param {string} formData.file - Promo sample file
     * @param {string} formData.promo_start_date - Promo start date
     * @param {string} formData.promo_end_date - Promo end date
     * @param {string} formData.number_of_promos - Maximum Promos can post the promo
     * @param {string} formData.payment_method - Promo payment method
     * @param {string} formData.promo_cost_per_influencer - Promo cost per influencer
     * @param {string} formData.coupon_file - Promo coupon code csv file uploaded
     * @return view
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const dispatchCreateOrUpdatePromoAction = async (formData) => {
        const isCreated = promo && promo._id ? await updatePromo(formData) : await createPromo(formData);
        if (isCreated && isCreated.status === 1) {
            history.push({
                pathname: PostAuthRoutes('promos').path
            });
        } else {
            setErrors(isCreated.errors);
        }
    }

    /**
     * function to cancel promo create or update
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const cancelPromo = () => {
        history.push({
            pathname: PostAuthRoutes('promos').path
        });
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${promo && promo._id ? PostAuthRoutes('update_promos').name : PostAuthRoutes('create_promos').name}`}</title>
            </Helmet>
            <Box>
                <CardHeader title={promo && promo._id ? PostAuthRoutes('update_promos').name : PostAuthRoutes('create_promos').name} />
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={12} md={6} lg={6}>
                            <Card >
                                {!isPromoLoading && <CardContent >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormInput
                                                tabIndex={1}
                                                label='Promo name'
                                                name='name'
                                                value={data.name}
                                                error={action.isSubmitted && (errors.name ? errors.name : '')}
                                                onChange={handleChange}
                                                autoFocus={true}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                            <FormInput
                                                tabIndex={2}
                                                label='Promo instagram handle'
                                                name='insta_handle'
                                                value={data.insta_handle ? data.insta_handle : (!isUserLoading && user) ? user.insta_handle : ''}
                                                error={action.isSubmitted && (errors.insta_handle ? errors.insta_handle : '')}
                                                onChange={handleChange}
                                                readOnly={true}
                                            />
                                        </Grid>
                                        <Grid item xs={4} sm={4} md={3} lg={3} xl={3} sx={{ display: { xs: 'none', md: 'block' } }}>
                                            <FormRadio
                                                label={`Promo type`}
                                                name={`promo_type`}
                                                onChange={handleChange}
                                                data={promoType}
                                                value={data.promo_type}
                                                error={(action.isSubmitted && ((errors.promo_type)) ? (errors.promo_type) : '')}
                                                isReadOnly={(promo && promo._id) ? true : false}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                            <FormRadio
                                                label={`Issue Coupons`}
                                                name={`issue_coupons_at`}
                                                onChange={handleChange}
                                                data={issueCouponsAt}
                                                value={data.issue_coupons_at}
                                                error={(action.isSubmitted && ((errors.issue_coupons_at)) ? (errors.issue_coupons_at) : '')}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormSelect
                                                tabIndex={1}
                                                label={`How often you can post`}
                                                name={`time_between_post`}
                                                placeholder={`Select how often you can post`}
                                                onChange={handleChange}
                                                data={timeBetweenPostOptions}
                                                value={data.time_between_post}
                                                error={(action.isSubmitted && (errors.time_between_post)) ? (errors.time_between_post) : ''}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormInput
                                                tabIndex={4}
                                                label='Description'
                                                name='description'
                                                value={data.description}
                                                error={action.isSubmitted && (errors.description ? errors.description : '')}
                                                onChange={handleChange}
                                                multiline
                                                maxLength={1000}
                                                minRows={2}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                            <FormDateTimePicker
                                                tabIndex={5}
                                                label='Start date'
                                                name='promo_start_date'
                                                value={data.promo_start_date}
                                                error={action.isSubmitted && errors.promo_start_date ? errors.promo_start_date : ''}
                                                onChange={handleChange}
                                                minDate={new Date(data.start_date) < new Date() ? data.start_date : new Date()}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                            <FormDateTimePicker
                                                tabIndex={6}
                                                label='End date'
                                                name='promo_end_date'
                                                value={data.promo_end_date}
                                                error={action.isSubmitted && errors.promo_end_date ? errors.promo_end_date : ''}
                                                onChange={handleChange}
                                                minDate={data.promo_start_date}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                            <FormInput
                                                tabIndex={7}
                                                label='Maximum promos'
                                                name='number_of_promos'
                                                value={data.number_of_promos}
                                                error={action.isSubmitted && errors.number_of_promos ? errors.number_of_promos : ''}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                            <FormRadio
                                                label={`Promo payment method`}
                                                name={`payment_method`}
                                                onChange={handleChange}
                                                data={paymentMethod}
                                                value={data.payment_method}
                                                error={(action.isSubmitted && (errors.payment_method)) ? (errors.payment_method) : ''}
                                                isReadOnly={(promo && promo._id) ? true : false}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            {data.payment_method === 'price' ? <FormInput
                                                tabIndex={9}
                                                label='Promo price (In rupees)'
                                                name='promo_cost_per_influencer'
                                                value={data.promo_cost_per_influencer}
                                                error={action.isSubmitted && errors.promo_cost_per_influencer ? errors.promo_cost_per_influencer : ''}
                                                onChange={handleChange}
                                            /> :
                                                <Box>
                                                    <FormBrowseFile
                                                        tabIndex={10}
                                                        name="coupon_file"
                                                        error={(action.isSubmitted && (errors.coupon_file)) ? (errors.coupon_file) : ''}
                                                        placeholder="your coupon code csv"
                                                        value={data.coupon_file}
                                                        onChange={handleChange}
                                                        acceptType=".csv"
                                                        info={`Allowed Format: csv | Allowed Maximum Size: 2 MB`}
                                                    />
                                                    {!data.coupon_file && <Alert icon={<Info sx={{ fontSize: `14px` }} color="info" />} style={{ background: `#00000000`, padding: `0 4px`, marginTop: `4px`, fontSize: `12px` }}><a style={{ fontSize: `12px` }} href={sampleCouponFile} download>Click here to download the sample csv file</a></Alert>}
                                                </Box>
                                            }
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormBrowseFile
                                                tabIndex={10}
                                                name="file"
                                                error={(action.isSubmitted && (errors.file)) ? (errors.file) : ''}
                                                placeholder="your sample post"
                                                value={data.file}
                                                onChange={handleChange}
                                                acceptType=".png,.jpg,.jpeg"
                                                info="Allowed Format: png, jpg, jpeg | Allowed Maximum Size: 2 MB | Suggested Aspect Ratio: 1080 x 1920"
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <FormInput
                                                tabIndex={4}
                                                label='Coupon message'
                                                name='coupon_message'
                                                value={data.coupon_message}
                                                error={action.isSubmitted && (errors.coupon_message ? errors.coupon_message : '')}
                                                onChange={handleChange}
                                                multiline
                                                maxLength={250}
                                                minRows={2}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <FormInput
                                                tabIndex={4}
                                                label='Thank you message'
                                                name='thank_you_message'
                                                value={data.thank_you_message}
                                                error={action.isSubmitted && (errors.thank_you_message ? errors.thank_you_message : '')}
                                                onChange={handleChange}
                                                multiline
                                                maxLength={250}
                                                minRows={2}
                                                readOnly={data.issue_coupons_at === 'after_24_hours' ? false : true}
                                            />
                                        </Grid>
                                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}  >
                                                <SubmitButton label={promo && promo._id ? 'Update' : 'Create'} loading={isLoading} tabIndex={11} />
                                                <CancelButton onClick={cancelPromo} tabIndex={12} />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </CardContent>}
                            </Card>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </React.Fragment>
    )
}

export { CreateOrUpdatePromo };
