import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { Grid, Box } from '@mui/material'
import { FormPassword } from '../../../_components/form'
import { SubmitButton } from '../../../_components/controls'

import { validate } from '.'
import { handleInputChange, validateForm } from '../../../_helpers'
import { UserAction } from '../../../redux/actions'
import { PostAuthRoutes, PreAuthRoutes } from '../../../routes'
import { Card, CardContent, CardHeader, SweetAlert } from '../../../_components'


// Initialize form input values to null
const inputs = { current_password: '', new_password: '', confirm_password: '' };

function AddPassword() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    /** Redux actions and state */
    const { add_password_loading: isLoading, add_password_errors: addPasswordErrors } = useSelector((state) => state.UserReducer);
    const addPassword = (params) => dispatch(UserAction.addPassword(params));

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });

    useEffect(() => {
        setErrors({ ...addPasswordErrors });
    }, [addPasswordErrors])

    /**
     * function to handle input changes and alter the value
     * @param object e input object with name and value
     * @author Akshay N
     * @created_at 30 May 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit
     * @param object e form object
     * @author Akshay N
     * @created_at 30 May 2022
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        dispatchChangePasswordAction();
    }

    /**
     * function to dispatch sign in
     * @author Akshay N
     * @created_at 30 May 2022
     */
    async function dispatchChangePasswordAction() {
        const isUpdated = await addPassword(data);
        if (isUpdated.status === 1) {
            let timerInterval;
            SweetAlert.fire({
                title: 'Password Added!',
                html: "Your account password has been added.You will be redirected to the login screen soon!<br/><br/>I will close in <b></b> milliseconds.Please login again.",
                icon: 'success',
                timer: 2000,
                timerProgressBar: true,
                didOpen: () => {
                    SweetAlert.showLoading()
                    const b = SweetAlert.getHtmlContainer().querySelector('b')
                    timerInterval = setInterval(() => { b.textContent = SweetAlert.getTimerLeft() }, 200)
                },
                willClose: () => {
                    setTimeout(() => {

                        history.push({
                            pathname: PreAuthRoutes('login').path
                        });

                    }, 2000);
                    clearInterval(timerInterval)
                }
            })
        }
    }

    return (
        <Card sx={{ p: `70px !important` }}>
            <CardHeader title={PostAuthRoutes('set_password').name} />
            <CardContent>
                <Grid container spacing={2} display={`flex`} alignItems={`center`}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <form onSubmit={handleSubmit} noValidate>
                            <Grid container spacing={2} display={`flex`} alignItems={`center`}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <FormPassword
                                        tabIndex={1}
                                        label='New password'
                                        name='new_password'
                                        value={data.new_password}
                                        error={action.isSubmitted && errors.new_password ? errors.new_password : ''}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <FormPassword
                                        tabIndex={1}
                                        label='Re-enter password'
                                        name='confirm_password'
                                        value={data.confirm_password}
                                        error={action.isSubmitted && errors.confirm_password ? errors.confirm_password : ''}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <SubmitButton label={'Set Password'} fullWidth loading={isLoading} />
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} display={`flex`} alignItems={`center`} justifyContent={`end`}>
                        <Box>
                            <svg width="300" height="200" viewBox="0 0 300 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_120_2)">
                                    <path d="M107.61 114.74C117.891 96.6542 137.502 82.7675 158.263 84.1052C142.949 100.758 134.02 122.29 133.054 144.893C132.713 153.611 133.242 163.343 127.453 169.871C123.851 173.933 118.346 175.901 112.933 176.313C107.52 176.726 102.108 175.748 96.7669 174.774L95.4713 175.172C94.9781 154.374 97.329 132.825 107.61 114.74Z" fill="#F0F0F0" />
                                    <path d="M158.293 84.5408C142.942 91.9312 129.851 104.274 121.815 119.327C120.078 122.581 118.599 126.034 117.954 129.684C117.308 133.337 117.759 136.813 119.007 140.282C120.147 143.453 121.681 146.564 122.03 149.957C122.399 153.534 121.083 156.878 118.714 159.532C115.816 162.779 111.924 164.792 108.049 166.627C103.747 168.665 99.2463 170.714 96.1993 174.517C95.8301 174.978 95.1366 174.379 95.5052 173.918C100.807 167.301 109.881 166.057 116.174 160.726C119.11 158.238 121.315 154.851 121.176 150.882C121.054 147.411 119.475 144.201 118.293 141C117.051 137.639 116.432 134.26 116.877 130.676C117.333 127.011 118.68 123.493 120.326 120.209C124.04 112.801 129.121 106.001 134.943 100.122C141.633 93.3644 149.43 87.8013 157.997 83.6731C158.527 83.4178 158.82 84.2871 158.293 84.5408V84.5408Z" fill="white" />
                                    <path d="M124.17 114.296C121.816 112.575 120.151 110.074 119.471 107.239C118.792 104.403 119.143 101.419 120.46 98.8182C120.729 98.2929 121.558 98.6854 121.289 99.2114C120.057 101.631 119.731 104.411 120.369 107.05C121.007 109.689 122.568 112.013 124.769 113.602C125.247 113.948 124.645 114.64 124.17 114.296V114.296Z" fill="white" />
                                    <path d="M118.217 138.75C124.114 137.888 129.47 134.835 133.216 130.2C133.587 129.741 134.281 130.34 133.91 130.799C130.003 135.616 124.423 138.781 118.284 139.664C117.699 139.748 117.635 138.833 118.217 138.75V138.75Z" fill="white" />
                                    <path d="M142.086 94.4925C142.858 95.3481 143.83 95.9986 144.916 96.3856C146.001 96.7726 147.166 96.8839 148.305 96.7095C148.889 96.6184 148.952 97.5334 148.372 97.624C147.113 97.8107 145.827 97.6862 144.626 97.2613C143.426 96.8365 142.348 96.1241 141.487 95.1866C141.399 95.1051 141.344 94.9928 141.336 94.8727C141.327 94.7526 141.364 94.6337 141.44 94.54C141.519 94.4485 141.632 94.3922 141.753 94.3833C141.874 94.3744 141.994 94.4137 142.086 94.4925Z" fill="white" />
                                    <path d="M199.569 141.158C199.206 141.226 198.843 141.293 198.477 141.367C193.602 142.297 188.811 143.621 184.151 145.327C183.789 145.453 183.423 145.587 183.065 145.723C171.882 149.952 161.58 156.221 152.685 164.211C149.148 167.394 145.859 170.841 142.845 174.522C138.683 179.607 134.575 185.452 129.137 188.767C128.574 189.119 127.989 189.436 127.386 189.716L96.057 176.721C96.0006 176.656 95.9409 176.598 95.8842 176.532L94.6096 176.071C94.7518 175.87 94.9036 175.665 95.0457 175.464C95.1277 175.347 95.2167 175.233 95.2987 175.117C95.3555 175.04 95.4128 174.963 95.4597 174.89C95.4785 174.864 95.4977 174.839 95.5136 174.82C95.5605 174.748 95.6118 174.685 95.6557 174.62C96.5009 173.471 97.3556 172.326 98.2199 171.184C98.2228 171.177 98.2228 171.177 98.2328 171.173C104.84 162.495 112.23 154.292 120.63 147.472C120.883 147.267 121.138 147.054 121.405 146.855C125.209 143.802 129.255 141.065 133.505 138.671C135.836 137.367 138.231 136.183 140.682 135.122C147.02 132.392 153.747 130.676 160.618 130.037C174.316 128.765 188.268 131.888 198.773 140.493C199.042 140.713 199.304 140.931 199.569 141.158Z" fill="#F0F0F0" />
                                    <path d="M199.334 141.527C182.627 138.185 164.744 140.158 149.265 147.339C145.918 148.892 142.658 150.758 139.945 153.284C137.231 155.812 135.498 158.859 134.406 162.379C133.407 165.598 132.758 169.006 130.994 171.925C129.135 175.003 126.071 176.881 122.582 177.574C118.313 178.422 113.994 177.685 109.795 176.818C105.133 175.855 100.306 174.781 95.583 175.983C95.0108 176.129 94.8181 175.232 95.3895 175.087C103.607 172.995 111.601 177.465 119.835 176.997C123.677 176.779 127.477 175.402 129.756 172.149C131.748 169.304 132.42 165.791 133.403 162.523C134.435 159.092 135.976 156.021 138.489 153.428C141.059 150.776 144.253 148.778 147.545 147.147C154.969 143.468 163.121 141.098 171.309 139.909C180.719 138.541 190.294 138.794 199.619 140.655C200.196 140.771 199.907 141.642 199.334 141.527V141.527Z" fill="white" />
                                    <path d="M154.173 144.74C153.33 141.949 153.506 138.949 154.671 136.276C155.835 133.603 157.912 131.432 160.53 130.149C161.061 129.891 161.486 130.703 160.955 130.961C158.515 132.152 156.58 134.175 155.501 136.666C154.422 139.157 154.269 141.952 155.069 144.546C155.242 145.11 154.345 145.301 154.173 144.74Z" fill="white" />
                                    <path d="M134.697 160.681C139.925 163.543 146.039 164.33 151.821 162.885C152.394 162.741 152.587 163.638 152.014 163.781C145.995 165.274 139.633 164.442 134.2 161.451C133.683 161.166 134.182 160.397 134.697 160.681V160.681Z" fill="white" />
                                    <path d="M180.402 139.715C180.503 140.863 180.887 141.968 181.521 142.93C182.155 143.893 183.017 144.683 184.032 145.229C184.553 145.508 184.053 146.277 183.535 146C182.417 145.391 181.465 144.517 180.763 143.455C180.06 142.393 179.629 141.176 179.505 139.909C179.484 139.79 179.508 139.668 179.573 139.567C179.638 139.465 179.74 139.393 179.857 139.364C179.975 139.339 180.099 139.362 180.201 139.427C180.303 139.493 180.375 139.596 180.402 139.715V139.715Z" fill="white" />
                                    <path d="M139.889 162.597C135.502 141.383 130.017 134.31 130.017 134.31L127.922 132.685L125.403 130.728L125.415 130.476L124.817 130.273L124.677 130.164L124.447 129.987L124.41 130.027L124.332 130.108L112.93 126.232L98.4648 121.319L91.7666 112.613C90.6757 111.195 89.2713 110.049 87.6633 109.264C86.0554 108.48 84.2876 108.079 82.4987 108.092L61.1067 108.25C58.2143 108.272 55.4345 109.374 53.3138 111.342L39.2532 124.384L17.3442 134.847L17.3072 134.81L17.0771 134.975L13.692 136.593L13.8316 137.32L11.7364 138.832C11.7364 138.832 6.252 145.414 1.86442 165.154C0.748274 170.176 0.198865 180.951 0.000557285 194.228C-0.00940368 194.9 0.114257 195.566 0.364346 196.19C0.614435 196.813 0.985965 197.381 1.45733 197.859C1.9287 198.338 2.4905 198.718 3.11007 198.977C3.72963 199.237 4.39461 199.371 5.06632 199.371H122.087V199.371H136.693C137.364 199.371 138.028 199.237 138.648 198.978C139.267 198.718 139.828 198.339 140.3 197.861C140.771 197.383 141.143 196.816 141.393 196.193C141.643 195.571 141.768 194.904 141.759 194.233C141.565 179.786 141.016 168.044 139.889 162.597Z" fill="#3F3D56" />
                                    <path d="M154.904 18.501C150.351 18.501 145.985 20.3096 142.765 23.5289C139.546 26.7482 137.737 31.1145 137.737 35.6673C137.737 40.2201 139.546 44.5864 142.765 47.8057C145.985 51.025 150.351 52.8336 154.904 52.8336H282.834C287.386 52.8336 291.753 51.025 294.972 47.8057C298.191 44.5864 300 40.2201 300 35.6673C300 31.1145 298.191 26.7482 294.972 23.5289C291.753 20.3096 287.386 18.501 282.834 18.501H154.904Z" fill="#E5E5E5" />
                                    <path d="M154.904 21.9751C151.272 21.9751 147.79 23.4177 145.222 25.9854C142.654 28.5532 141.211 32.0359 141.211 35.6673C141.211 39.2987 142.654 42.7813 145.222 45.3491C147.79 47.9169 151.272 49.3595 154.904 49.3595H282.834C286.465 49.3595 289.948 47.9169 292.515 45.3491C295.083 42.7813 296.526 39.2987 296.526 35.6673C296.526 32.0359 295.083 28.5532 292.515 25.9854C289.948 23.4177 286.465 21.9751 282.834 21.9751H154.904Z" fill="white" />
                                    <path d="M75.1622 100.056C90.6403 100.056 103.188 87.5082 103.188 72.0301C103.188 56.5521 90.6403 44.0046 75.1622 44.0046C59.6842 44.0046 47.1367 56.5521 47.1367 72.0301C47.1367 87.5082 59.6842 100.056 75.1622 100.056Z" fill="#9E616A" />
                                    <path d="M67.9927 98.6834C67.873 98.4198 67.7536 98.1553 67.6345 97.8899C67.6804 97.8914 67.7258 97.898 67.7717 97.8992L67.9927 98.6834Z" fill="#2F2E41" />
                                    <path d="M46.3573 45.2792C47.7748 44.1485 49.4314 43.1081 51.2437 43.1665C53.0559 43.2249 54.8937 44.8617 54.5182 46.6356C57.847 40.7418 63.1453 36.2088 69.4832 33.8322C75.8212 31.4557 82.7937 31.3875 89.1769 33.6395C93.6811 35.2287 98.0982 38.4078 99.1328 43.0707C99.3985 44.2677 99.4555 45.5801 100.203 46.5521C101.145 47.7776 102.948 48.0548 104.433 47.6237C104.447 47.6194 104.462 47.615 104.477 47.6106C104.728 47.525 104.999 47.5166 105.254 47.5865C105.51 47.6563 105.739 47.8013 105.911 48.0027C106.083 48.204 106.191 48.4524 106.221 48.7157C106.251 48.9791 106.2 49.2453 106.077 49.4799L104.803 51.8567C106.409 52.2148 108.078 52.1793 109.668 51.7532C109.932 51.684 110.211 51.6981 110.467 51.7937C110.723 51.8893 110.943 52.0617 111.097 52.2872C111.251 52.5128 111.331 52.7804 111.327 53.0534C111.323 53.3264 111.234 53.5914 111.073 53.8121C106.871 59.5817 99.8501 63.3074 92.6856 63.2636C87.5935 63.2326 82.449 61.4776 77.4932 62.6486C75.576 63.1016 73.7846 63.9781 72.2503 65.2137C70.716 66.4494 69.4779 68.0129 68.6267 69.7895C67.7755 71.5661 67.3328 73.5107 67.3312 75.4807C67.3295 77.4507 67.769 79.396 68.6172 81.174C67.0948 79.5088 64.1518 79.903 62.5944 81.5358C61.0372 83.1685 60.634 85.6054 60.7909 87.8562C61.0309 91.2988 62.3797 94.5427 63.8016 97.6999C51.8799 97.3246 40.6039 88.9617 36.8084 77.6507C32.9969 66.2922 36.9915 52.7506 46.3573 45.2792Z" fill="#2F2E41" />
                                    <path opacity="0.2" d="M27.5842 156.193L35.4447 198.96L40.1612 199.371L27.5842 156.193Z" fill="black" />
                                    <path opacity="0.2" d="M114.17 152.967L106.309 198.929L101.593 199.371L114.17 152.967Z" fill="black" />
                                    <path d="M53.0616 30.8858C51.5545 31.5151 49.9819 30.8254 49.3223 29.2459C48.6527 27.6423 49.2781 26.0144 50.8094 25.375C52.3409 24.7355 53.8855 25.4269 54.5659 27.0564C55.2361 28.6614 54.6175 30.2361 53.0616 30.8858ZM50.1746 22.2185L48.9762 22.7189C48.6769 22.8424 48.342 22.8491 48.038 22.7376C47.734 22.626 47.4828 22.4045 47.3343 22.1167L47.2751 22.0008C45.9857 19.6753 45.838 16.8491 46.8386 13.6051C47.7655 10.695 48.1505 8.6483 47.3762 6.79413C46.4811 4.65037 44.5696 3.97883 42.132 4.95163C41.1866 5.3464 40.8857 5.3401 40.1417 6.07816C39.9799 6.24065 39.787 6.3689 39.5745 6.45524C39.3621 6.54157 39.1344 6.58424 38.9051 6.5807C38.6834 6.57809 38.4645 6.53086 38.2615 6.44184C38.0584 6.35281 37.8754 6.22381 37.7233 6.06253C37.4303 5.75171 37.2661 5.34127 37.2639 4.9141C37.2618 4.48693 37.4218 4.07486 37.7117 3.7611C38.9622 2.45204 40.4705 1.4164 42.1414 0.719544C47.3788 -1.46739 50.0153 1.7613 51.2218 4.65068C52.4019 7.47682 51.738 10.099 50.6714 13.495C49.7776 16.3169 49.8297 18.4078 50.845 20.4869C50.9183 20.6441 50.9591 20.8144 50.9649 20.9878C50.9707 21.1611 50.9414 21.3338 50.8788 21.4956C50.8162 21.6573 50.7216 21.8047 50.6005 21.9289C50.4795 22.0532 50.3346 22.1517 50.1746 22.2185L50.1746 22.2185Z" fill="#E97E98" />
                                    <path d="M183.162 43.8544H157.709C157.628 43.8549 157.548 43.8395 157.473 43.8089C157.398 43.7783 157.33 43.7333 157.272 43.6763C157.215 43.6193 157.169 43.5515 157.138 43.4769C157.107 43.4022 157.091 43.3221 157.091 43.2413C157.091 43.1604 157.107 43.0803 157.138 43.0057C157.169 42.931 157.215 42.8633 157.272 42.8063C157.33 42.7493 157.398 42.7042 157.473 42.6737C157.548 42.6431 157.628 42.6276 157.709 42.6282H183.162C183.324 42.6293 183.479 42.6944 183.593 42.8092C183.707 42.9241 183.771 43.0794 183.771 43.2413C183.771 43.4031 183.707 43.5585 183.593 43.6733C183.479 43.7882 183.324 43.8533 183.162 43.8544Z" fill="#E97E98" />
                                    <path d="M215.451 44.0587H189.997C189.917 44.0593 189.836 44.0438 189.761 44.0132C189.687 43.9827 189.619 43.9376 189.561 43.8806C189.504 43.8236 189.458 43.7559 189.427 43.6812C189.396 43.6065 189.38 43.5265 189.38 43.4456C189.38 43.3648 189.396 43.2847 189.427 43.21C189.458 43.1354 189.504 43.0676 189.561 43.0106C189.619 42.9536 189.687 42.9086 189.761 42.878C189.836 42.8474 189.917 42.832 189.997 42.8325H215.451C215.532 42.832 215.612 42.8474 215.687 42.878C215.761 42.9086 215.83 42.9536 215.887 43.0106C215.944 43.0676 215.99 43.1354 216.021 43.21C216.052 43.2847 216.068 43.3648 216.068 43.4456C216.068 43.5265 216.052 43.6065 216.021 43.6812C215.99 43.7559 215.944 43.8236 215.887 43.8806C215.83 43.9376 215.761 43.9827 215.687 44.0132C215.612 44.0438 215.532 44.0593 215.451 44.0587V44.0587Z" fill="#E97E98" />
                                    <path d="M247.74 44.263H222.286C222.125 44.2619 221.97 44.1969 221.856 44.082C221.742 43.9672 221.677 43.8118 221.677 43.65C221.677 43.4881 221.742 43.3328 221.856 43.2179C221.97 43.1031 222.125 43.038 222.286 43.0369H247.74C247.821 43.0363 247.901 43.0518 247.976 43.0823C248.05 43.1129 248.119 43.158 248.176 43.215C248.233 43.2719 248.279 43.3397 248.31 43.4144C248.341 43.489 248.357 43.5691 248.357 43.65C248.357 43.7308 248.341 43.8109 248.31 43.8855C248.279 43.9602 248.233 44.028 248.176 44.085C248.119 44.1419 248.05 44.187 247.976 44.2176C247.901 44.2481 247.821 44.2636 247.74 44.263H247.74Z" fill="#E97E98" />
                                    <path d="M280.029 44.4674H254.575C254.414 44.4663 254.259 44.4012 254.145 44.2863C254.031 44.1715 253.967 44.0162 253.967 43.8543C253.967 43.6924 254.031 43.5371 254.145 43.4222C254.259 43.3074 254.414 43.2423 254.575 43.2412H280.029C280.191 43.2423 280.346 43.3074 280.46 43.4222C280.574 43.5371 280.638 43.6924 280.638 43.8543C280.638 44.0162 280.574 44.1715 280.46 44.2863C280.346 44.4012 280.191 44.4663 280.029 44.4674Z" fill="#E97E98" />
                                    <path d="M170.379 38.2121C172.992 38.2121 175.11 36.0941 175.11 33.4814C175.11 30.8687 172.992 28.7507 170.379 28.7507C167.766 28.7507 165.648 30.8687 165.648 33.4814C165.648 36.0941 167.766 38.2121 170.379 38.2121Z" fill="#E97E98" />
                                    <path d="M202.863 38.2121C205.476 38.2121 207.594 36.0941 207.594 33.4814C207.594 30.8687 205.476 28.7507 202.863 28.7507C200.251 28.7507 198.133 30.8687 198.133 33.4814C198.133 36.0941 200.251 38.2121 202.863 38.2121Z" fill="#E97E98" />
                                    <path d="M235.347 38.2121C237.96 38.2121 240.078 36.0941 240.078 33.4814C240.078 30.8687 237.96 28.7507 235.347 28.7507C232.734 28.7507 230.616 30.8687 230.616 33.4814C230.616 36.0941 232.734 38.2121 235.347 38.2121Z" fill="#E97E98" />
                                    <path d="M267.831 38.2121C270.444 38.2121 272.562 36.0941 272.562 33.4814C272.562 30.8687 270.444 28.7507 267.831 28.7507C265.219 28.7507 263.101 30.8687 263.101 33.4814C263.101 36.0941 265.219 38.2121 267.831 38.2121Z" fill="#E97E98" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_120_2">
                                        <rect width="300" height="199.371" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                        </Box>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}
export { AddPassword };
