import React, { useEffect } from 'react'

import { CurrencyRupee } from '@mui/icons-material';
import { IconButton, Chip } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { PostAuthRoutes } from '../../../../routes';
import { useDispatch, useSelector } from 'react-redux';
import { WalletAction } from '../../../../redux/actions';


function WalletMenu() {

    const history = useHistory();
    const dispatch = useDispatch();

    const { wallet_chart_loading: isLoading, wallet_balance } = useSelector((state) => state.WalletReducer);

    /** Redux reducer actions */
    const getWalletBalance = () => dispatch(WalletAction.getWalletBalance());

    useEffect(() => {
        getWalletBalance()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClick = (event) => {
        history.push({
            pathname: PostAuthRoutes('wallet').path
        });
    };

    return (
        <IconButton >
            <Chip icon={<CurrencyRupee />} label={!isLoading && `${wallet_balance}`} onClick={handleClick} />
        </IconButton>
    )
}

export { WalletMenu }
