import { Box, Skeleton, Stack } from '@mui/material'
import React from 'react'

function ConversationPanelLoader() {
    var rows = [];
    for (var i = 0; i < 9; i++) {
        rows.push(
            <React.Fragment>
                <Box key={i} sx={{ minHeight: `80px`, display: `flex`, alignItems: `center`, justifyContent: `center`, backgroundColor: `#1b1b1b`, p: 2, borderRadius: 2, mb: 1 }} >
                    <Box sx={{ width: `100%` }}>
                        <Stack direction="row" alignItems="center" sx={{ width: `100%`, pl: 4, pr: 4, pt: 0, pb: 1 }} spacing={2} >
                            <Skeleton animation="wave" variant='circular' height="40px" width="45px" />
                            <Box sx={{ width: `100%` }}>
                                <Skeleton animation="wave" height={20} width="70%" />
                                <Skeleton animation="wave" height={10} width="60%" />
                            </Box>
                        </Stack>
                    </Box>
                </Box>
            </React.Fragment>
        )
    }

    return <React.Fragment> {rows} </React.Fragment>
}

export { ConversationPanelLoader }