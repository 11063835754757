import { Facebook } from "@mui/icons-material";
import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { SignInAction } from "../../../redux/actions";
import { preRegisterRoutes } from "../../../_helpers";
import { useFacebookSDK } from "../../../_helpers/initFacebookSdk";
import { SignInModal } from "./sign-in-modal";


function InstaSignIn() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    /** Initialize and declare state */
    const [showSignInCouponCouponModal, setShowSignInCouponModal] = useState(false);

    /** Redux reducer state */
    const { loading: isLoading, insta_signin_loading: isInstaSigninLoading, loggedIn, user } = useSelector((state) => state.SignInReducer);

    const instaSignIn = (params) => dispatch(SignInAction.instaSignIn(params));

    const { fbsdk: { newUser, authResponse }, handleLogin } = useFacebookSDK();
    useEffect(() => {
        if (newUser) {
            instaSignIn(authResponse).then(isSignin => {
                if (isSignin.status === 1) {
                    const brand = isSignin.data;
                    const redirectRoute = preRegisterRoutes(brand);
                    history.push(redirectRoute);
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newUser, authResponse]);

    useEffect(() => {
        if (loggedIn && Object.keys(user).length > 0 && user.status) {
            const redirectRoute = preRegisterRoutes(user);
            history.push(redirectRoute);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedIn]);

    /**
     * function to open/close redeem coupon Update modal
     * @param {string} null 
     * @return view
     * @author
     * @created_at 21 June 2022
     */
    const signInModal = () => {
        setShowSignInCouponModal(!showSignInCouponCouponModal);
    }
    
    /**
     * function to handle click to direct to story details page
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    function handleSignInModalClick() {
        signInModal();
    }

    return (
        <React.Fragment>
            {/* <Button
                // style={{ marginBottom: `20px` }}
                startIcon={<Facebook />}
                type="button"
                variant="contained"
                onClick={handleLogin}
                disabled={isLoading || isInstaSigninLoading}
                color={`primary`}
                fullWidth
            >
                Continue with Facebook
            </Button> */}

            <Button
                // style={{ marginBottom: `20px` }}
                startIcon={<Facebook />}
                type="button"
                variant="contained"
                onClick={handleSignInModalClick}
                disabled={isLoading || isInstaSigninLoading}
                color={`primary`}
                fullWidth
            >
                Continue with Facebook
            </Button>

            <SignInModal show={showSignInCouponCouponModal} closeModal={signInModal} handleLogin={handleLogin}  />

        </React.Fragment>
    );
}

export { InstaSignIn };
