import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Grid, Stack, Container } from "@mui/material";
import { Card, CardContent, CardHeader } from "../../../_components/card";
import { PostAuthRoutes } from "../../../routes";
import { TicketAction } from '../../../redux/actions';
import { TicketDetailsCard, TicketReplies, AddReply } from "./";
import { GeneralButton } from '../../../_components/controls/general-button/GeneralButton';
import { UpdateStatusModal } from '../update-status-modal'

function TicketDetails({ ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();

    const params = new URLSearchParams(search);
    const ticketId = params.get('T_Id'); // plan promo from url

    /** Redux actions and state */
    const { ticket_details_loading: isLoading, ticket_details } = useSelector((state) => state.TicketReducer);
    const ticketDetails = (params) => dispatch(TicketAction.ticketDetails(params));
    const changeStatus = (params) => dispatch(TicketAction.changeStatus(params));

    /** Initialize and declare state */
    const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false);
    const [statusData, setStatusData] = useState({ id: '', status: '' });

    useEffect(() => {
        ticketDetails({ ticket_id: ticketId });
        //eslint-disable-next-line
    }, [ticketId])

    /**
     * function to open/close SupportCenter status Update modal
     * @param {string} null 
     * @return view
     * @author
     * @created_at 21 June 2022
     */
    const statusUpdateModal = ({ id, status, remark }) => {
        setStatusData({ id: id, status: status, remark: remark });
        setShowStatusUpdateModal(!showStatusUpdateModal);
    }

    /**
     * function to update support center status
     * @param {string} id - Id of the data which we need to update.
     * @return view
     * @author 
     * @created_at 21 June 2022
     */
    const updateStatusAction = async ({ id, current_status, ticket_change_status, remark }) => {
        setShowStatusUpdateModal(!showStatusUpdateModal);
        await changeStatus({ 'ticket_id': id, current_status, ticket_change_status, remark });
    }

    return (
        <React.Fragment>

            {!isLoading && ticket_details &&
                <CardHeader
                    title={PostAuthRoutes('ticket_details').name}
                    action={
                        <Stack spacing={1} direction="row" alignItems={`center`}>
                            {ticket_details.status === 'open' ? <GeneralButton label={`Update Status`} onClick={() => statusUpdateModal({ id: ticketId, status: ticket_details.status, remark: '' })} /> : ''}
                        </Stack>

                    }
                />}
            <Container>
                <Grid container spacing={2} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                        <TicketDetailsCard />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                        <Card sx={{ mb: 1, minHeight: `710px` }}>
                            <CardContent sx={{ p: `2rem !important` }}>
                                <TicketReplies />
                                <AddReply />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            <UpdateStatusModal show={showStatusUpdateModal} closeModal={statusUpdateModal} data={statusData} updateStatus={updateStatusAction} />
        </React.Fragment>
    );

}

export { TicketDetails }