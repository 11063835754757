import {
	SIGNIN_REQUEST, SIGNIN_SUCCESS, SIGNIN_FAILURE,
	INSTA_SIGNIN_REQUEST, INSTA_SIGNIN_SUCCESS, INSTA_SIGNIN_FAILURE,
} from "../actions";

let user = localStorage.getItem('accessToken') ? (localStorage.getItem('accessToken')) : '';

const initialState = user ? { loggedIn: true, user, errors: {}, password_errors: {}, insta_signin_loading: false, insta_signin_request: {} } : { loggedIn: false, request: {}, user: {}, errors: {}, insta_signin_loading: false, insta_signin_request: {} };

export function SignInReducer(state = initialState, action) {
	switch (action.type) {

		/** begin::Sign in redux */
		case SIGNIN_REQUEST: {
			return {
				loading: true,
				loggedIn: false,
				request: action.request,
				user: {}
			};
		}
		case SIGNIN_SUCCESS: {
			return {
				loading: false,
				loggedIn: true,
				user: action.data,
			};
		}
		case SIGNIN_FAILURE: {
			return {
				loading: false,
				loggedIn: false,
				user: {},
				errors: action.errors
			};
		}
		/** end::Sign in redux */

		/** begin::intagram Sign in redux */
		case INSTA_SIGNIN_REQUEST: {
			return {
				insta_signin_loading: true,
				loggedIn: false,
				insta_signin_request: action.request,
				user: {}
			};
		}
		case INSTA_SIGNIN_SUCCESS: {
			return {
				insta_signin_loading: false,
				loggedIn: true,
				user: action.data,
			};
		}
		case INSTA_SIGNIN_FAILURE: {
			return {
				insta_signin_loading: false,
				loggedIn: false,
				user: {},
			};
		}
		/** end::intagram Sign in redux */

		case "RESET_USER": {
			return {
				loggedIn: false,
				user: {},
				check_brand_access: ''
			};
		}

		default:
			return state;
	}
}
