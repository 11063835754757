import React, { useEffect } from 'react';
import { StatisticsWidget } from '../../../_components';
import { useDispatch, useSelector } from 'react-redux';
import { WalletAction } from '../../../redux/actions';
import { useState } from 'react';
import { Box } from '@mui/material';
import { CurrencyRupee } from '@mui/icons-material';

function WalletBalanceWidget() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const [data, setData] = useState({ series: [], balance: 0 });

    const { wallet_chart_loading: isLoading, wallet_chart } = useSelector((state) => state.WalletReducer);

    /** Redux reducer actions */
    const getWalletChart = () => dispatch(WalletAction.getWalletChart());

    useEffect(() => {
        getWalletChart();
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (!isLoading && wallet_chart && Object.keys(wallet_chart).length > 0) {
            setData({
                ...data,
                series: wallet_chart.series,
                balance: wallet_chart.balanceAmount,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [wallet_chart])

    return (
        <Box>
            <StatisticsWidget
                color="dark"
                icon={<CurrencyRupee />}
                title="Current Balance"
                count={wallet_chart.balanceAmount}
                percentage={{
                    // color: `${wallet_chart.balanceAmount > 0 ? 'success' : 'error'}`,
                    // amount: `${wallet_chart.balanceAmount}%`,
                    label: `Your active balance is ${wallet_chart.balanceAmount} rupees`
                }}
            />
        </Box>
    )
}

export { WalletBalanceWidget }