import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { GlobalReducer, SignInReducer, RegisterReducer, UserReducer, ForgotPasswordReducer, PromoReducer, StoryReducer, PlanReducer, InvoiceReducer, InfluencerReducer, NotificationReducer, TicketReducer, SearchReducer, ConnectAccountReducer, IgInsightsReducer, MessagesReducer, PaymentReducer, GiveawayReducer, BrandCalendarReducer, SchedulePostReducer, WalletReducer } from './reducers';

const loggerMiddleware = createLogger({ predicate: () => process.env.NODE_ENV !== 'production' });
const rootReducer = combineReducers({ GlobalReducer, SignInReducer, RegisterReducer, UserReducer, ForgotPasswordReducer, PromoReducer, StoryReducer, PlanReducer, InvoiceReducer, InfluencerReducer, NotificationReducer, TicketReducer, SearchReducer, ConnectAccountReducer, IgInsightsReducer, MessagesReducer, PaymentReducer, GiveawayReducer, BrandCalendarReducer, SchedulePostReducer, WalletReducer })

export const store = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware
    )
);