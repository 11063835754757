import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleInputChange, validateForm } from "../../../../_helpers";
import { validate } from ".";
import { SubmitButton } from "../../../../_components/controls";
import { FormInput } from "../../../../_components/form";
import { Stack } from "@mui/material";
import { TicketAction } from "../../../../redux/actions";
import { useLocation } from "react-router-dom";

// Initialize form input values to null
const inputs = { ticket_id: '', reply: '' };

function AddReply() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();

    const params = new URLSearchParams(search);
    const ticketId = params.get('T_Id'); // plan promo from url

    /** Redux actions and state */
    const { add_reply_loading: isLoading, add_reply_errors: addReplyErrors } = useSelector((state) => state.TicketReducer);
    const addReply = (params) => dispatch(TicketAction.addReply(params));

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });

    useEffect(() => {
        setErrors({ ...addReplyErrors });
    }, [addReplyErrors])

    useEffect(() => {
        setData({ ...data, ticket_id: ticketId, });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticketId])


    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Naveen
     * @created_at 30 May 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle update brand form submit action
     * @param {object} e form object
     * @author Naveen
     * @created_at 30 May 2022
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        dispatchAddReplyAction(data);
    }

    /**
     * function to dispatch update brand api
     * @author Naveen
     * @created_at 30 May 2022
     */
    async function dispatchAddReplyAction(formData) {
        const isCreated = await addReply(formData);
        if (isCreated.status === 1) {
            setData({ ...data, reply: '' });
        }
    }

    return (
        <form onSubmit={handleSubmit} noValidate>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}  >
                <FormInput
                    tabIndex={1}
                    label='Reply'
                    name='reply'
                    value={data.reply}
                    error={action.isSubmitted && errors.reply ? errors.reply : ''}
                    onChange={handleChange}
                    autoFocus={true}
                />
                <SubmitButton label='SEND' loading={isLoading} />
            </Stack>
        </form>
    )
}

export { AddReply };