import { apiServices, cookieServices } from '../../_helpers';


export const SignInServices = {

	/**
	 * Service for signin 
	 * @param {Object} params - The params which are used for signin api.
	 * @param {string} params.email - Email of the brand user
	 * @param {string} params.password - Password the brand user
	 * @author Akshay N 
	 * @created_at 30 May 2022
	 */
	signIn: (params) => {

		return apiServices.post('/brand/login', params)
			.then((response) => {
				let date = new Date();
				date.setTime(date.getTime() + (30 * 60 * 24 * 60 * 1000));
				const options = { path: '/', expires: date };
				cookieServices.set('accessToken', response.data.token, options);
				return response;
			})
	},

	/**
	 * Service for sign out 
	 * @param {Object} params - The params which are used for the api
	 * @author Akshay N 
	 * @created_at 30 May 2022
	 */
	signOut: (params) => {

		return apiServices.get('/brand/logout')
			.then((response) => {
				cookieServices.remove('accessToken');
				return response;
			})
	},

	/**
	 * Service for instaSignin 
	 * @param {Object} params - The params which are used for signin api.
	 * @author Jasin
	 * @created_at 28 October 2022
	 */
	instaSignIn: (params) => {

		return apiServices.post('/brand/insta/login', params)
			.then((response) => {
				if (response.status === 1) {
					let date = new Date();
					date.setTime(date.getTime() + (30 * 60 * 24 * 60 * 1000));
					const options = { path: '/', expires: date };

					if (response.data.status === 'active') {
						cookieServices.set('accessToken', response.data.token, options);
					}
				}
				return response;

			})
	},

	/**
	 * Service for sign out 
	 * @param {Object} params - The params which are used for the api
	 * @author Akshay N 
	 * @created_at 30 May 2022
	 */
	getUserStatus: (params) => {
		return apiServices.post('/brand/user-status', params)
			.then((response) => {
				return response;
			})
	},

}
