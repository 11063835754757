import React from 'react'
import { Box, Grid } from '@mui/material'
import { PromoCountWidget } from '../promos';
import { StoriesCountWidget } from '../stories';
import { InfluencerCountWidget, InfluencerWithMostStoriesGraph, InfluencerWithMostFollowersGraph } from '../influencer';
import { WalletBalanceWidget } from '../brand-wallet';

function Dashboard() {

    return (
        <Box>
            <Grid container spacing={3}>
                <Grid item xs={12} md={3} lg={3}>
                    {/*  Influencer count widget based on the brand */}
                    <InfluencerCountWidget />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    {/*  Promo count widget based on the brand created */}
                    <PromoCountWidget />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    {/*  Stories count widget based on the influencers posted on the brand promo */}
                    <StoriesCountWidget />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                    <WalletBalanceWidget />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    {/*  Graph based on the influencers with most followers */}
                    <InfluencerWithMostFollowersGraph />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    {/*  Graph based on the influencers with most stories posted */}
                    <InfluencerWithMostStoriesGraph />
                </Grid>
            </Grid>
        </Box>
    )
}

export { Dashboard }
