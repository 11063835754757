import { Card, CardContent, CardMedia, Stack, Typography, Grid } from '@mui/material';
import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalFooterCloseBtn, ModalFooterSaveBtn } from '../../../../_components/modal'
import { FormCheckbox } from '../../../../_components/form'
import questinMarkSvg from './question-mark.svg'
import { handleInputChange, validateForm } from "../../../../_helpers";
import { validate } from ".";
import { Facebook } from '@mui/icons-material';

// Initialize form input values to null
const inputs = { instagram_business_account: false, facebook_page: false, messages: false };

function SignInModal({ show, showLoader, closeModal, handleLogin }) {

    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });
    /**
     * function to close update status modal
     * @param {string} status Entered status on ticket update status modal
     * @return view
     * @author
     * @created_at 30 May 2022
     */
    const onClose = () => {
        closeModal({});
        setData({ ...inputs });
        setErrors({ ...inputs });

    }

    /**
     * function to handle submit entered values
     * @param {object} e form object
     * @author 
     * @created_at 30 May 2022
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        console.log(valid, 'valid');
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        handleLogin();
        closeModal({});
        setData({ ...inputs });
        setErrors({ ...inputs });

    }

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author
     * @created_at 30 May 2022
     */
    function handleChange(e) {

        const { name, value } = handleInputChange(e);
        console.log(name, value);

        setErrors({ ...errors, ...(validate(name, value, data)) })
        data[name] = value;
        setData({ ...data });
    }

    return (
        <Modal isDialogOpen={show} onClose={onClose} loader={showLoader} maxWidth="sm" PaperProps={{ style: { padding: 0 } }}>
            <Card style={{ boxShadow: `none`, padding: 30 }}>
                <CardMedia component="img" image={questinMarkSvg} style={{ position: "absolute", padding: 0, bottom: 0, right: 0, top: 0, width: "60%", opacity: '0.1' }} />
                <form onSubmit={handleSubmit} noValidate className='ns-form' >
                    <ModalBody style={{ padding: 0 }}>
                        <CardContent style={{ display: `flex`, alignItems: `center`, padding: `2rem 1rem 0 1rem` }}>

                            <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" style={{ minHeight: `10px`, minWidth: `100%` }} spacing={3}>
                                <Typography variant='body2'>Before you proceed with Facebook login for your Instagram business account, please confirm that you have completed the following prerequisites:</Typography>

                                <Stack direction="row" justifyContent="flex-end" alignItems="flex-start" style={{ minHeight: `100px`, minWidth: `60%` }} >
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormCheckbox
                                                name="instagram_business_account"
                                                label="I have an Instagram Business Account"
                                                value={data.instagram_business_account}
                                                onChange={handleChange}
                                                error={action.isSubmitted && (errors.instagram_business_account ? errors.instagram_business_account : '')}

                                            />
                                            <FormCheckbox
                                                name="facebook_page"
                                                label=" I have connected my Instagram business to a Facebook Page."
                                                value={data.facebook_page}
                                                onChange={handleChange}
                                                error={action.isSubmitted && (errors.facebook_page ? errors.facebook_page : '')}

                                            />
                                            <FormCheckbox
                                                name="messages"
                                                label="I have enabled access to messages under connected tools."
                                                value={data.messages}
                                                onChange={handleChange}
                                                error={action.isSubmitted && (errors.messages ? errors.messages : '')}

                                            />
                                        </Grid>
                                    </Grid>
                                </Stack>

                                <Typography variant='body2'>By checking the box and proceeding, you acknowledge that you have completed the necessary prerequisites and agree to the terms and conditions.</Typography>

                            </Stack>

                        </CardContent>
                    </ModalBody>
                    <ModalFooter style={{ padding: 0 }}>
                        <ModalFooterSaveBtn tabIndex={3} startIcon={<Facebook />} text={'Continue with Facebook'} isProcessing={action.isProcessing} disabled={action.isProcessing} />
                        <ModalFooterCloseBtn tabIndex={4} onClose={onClose} />
                    </ModalFooter>
                </form>
            </Card>
        </Modal>
    )
}

export { SignInModal }
