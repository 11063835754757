
import { useEffect } from 'react';
import { cookieServices } from '../../_helpers';
import { useHistory } from 'react-router';
import { Grid } from '@mui/material';
import { CopyRight, useStyles } from '.'
import { Box } from '../../_components';
import { Card } from '../../_components/card/card/Card';
import image from "../../_theme/images/bg-sign-in-basic.jpeg";
import { PostAuthRoutes } from '../../routes';



const PreSignIn = ({ children, ...props }) => {

    let history = useHistory();
    const classes = useStyles();

    useEffect(() => {
        if (cookieServices.get('accessToken')) {
            history.push({
                pathname: PostAuthRoutes('home').path
            });
        }
    }, [history])

    return (
        <Box justifyContent="center" alignItems="center" position="absolute" width="100%" minHeight="100vh" sx={{ backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) => image && `${linearGradient(rgba(gradients.dark.main, 0.6), rgba(gradients.dark.state, 0.6))}, url(${image})` }} >
            <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" className={classes.preSignInWrapper}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                    <Card>
                        <Box display="flex" alignItems="center">
                            <Box className={classes.preSignInWrapperItemInner} {...props}>
                                {children}
                            </Box>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
            <CopyRight />
        </Box>
    )
}


export { PreSignIn }
