import { Delete } from "@mui/icons-material";
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PaymentAction } from "../../../../redux/actions";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "../../../../_components";
import { AddUpdateCreditCard } from "./AddUpdateCreditCard";

const useStyles = makeStyles((theme) => ({
    titleContainer: {
        marginBottom: theme.spacing(2),
        borderBottom: `1px solid #b0bec5`
    },
    headIcon: {
        paddingRight: theme.spacing(1)
    },
    headTitle: {
        fontWeight: 900,
        paddingBottom: theme.spacing(1),
    },
    headButton: {
        margin: '0 5px',
    },
    paymentPreferenceBox: {
        padding: theme.spacing(2)
    },
    paymentPreferenceContainer: {
        // marginBottom: theme.spacing(2)
    },
    paymentPrefercenceTitle: {
        paddingBottom: theme.spacing(1)
    },
    paymentMethodTitle: {
        marginTop: theme.spacing(2)
    },
    table: {
        marginBottom: theme.spacing(2),
    },
    tableHeader: {
        backgroundColor: `#b3e5fc`,
        display: 'table-header-group !important'
    },
    tableTextColor: {
        color: `initial !important`,
    }
}));

function CreditCardDetails() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    const classes = useStyles();

    const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false)

    const { payment_details_loading: loading, payment_details } = useSelector((state) => state.PaymentReducer);

    const deleteCreditCard = () => dispatch(PaymentAction.deleteCreditCard());
    const getPaymentDetails = () => dispatch(PaymentAction.getPaymentDetails());

    const deleteCard = (e) => {
        e.preventDefault();
        setShowDeleteWarningModal(true);
    }

    const onClose = () => {
        setShowDeleteWarningModal(false);
    }

    const deleteCreditCardAction = () => {
        deleteCreditCard().then(() => {
            getPaymentDetails();
            setShowDeleteWarningModal(false);
        })
    }



    return (
        <Box className={classes.paymentPreferenceBox}>

            <Modal isDialogOpen={showDeleteWarningModal} onClose={onClose} loader={false} size="md">
                <ModalHeader id="AddUpdateDeals" title="Remove Credit Card" onClose={onClose} />
                <ModalBody className="text-center">
                    <h1>Are You Sure?</h1>
                    <p>{`Do you want to remove this credit card?`}</p>
                </ModalBody>
                <ModalFooter>
                    <Button variant="contained" color="primary" size="small" className={classes.button} onClick={deleteCreditCardAction}>Confirm</Button>
                    <Button variant="contained" color="secondary" size="small" className={classes.button} onClick={onClose}>Close</Button>
                </ModalFooter>
            </Modal>

            <TableContainer >
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead className={classes.tableHeader}>
                        <TableRow>
                            <TableCell className={classes.tableTextColor}>Credit Card</TableCell>
                            <TableCell className={classes.tableTextColor}>Name on Card</TableCell>
                            <TableCell className={classes.tableTextColor}>Expires On</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {!loading && !payment_details.stripe_card_response && (
                            <TableRow>
                                <TableCell className={classes.tableTextColor} colSpan={4}>Card not added</TableCell>
                            </TableRow>
                        )}

                        {!loading && payment_details.stripe_card_response && (
                            <TableRow >
                                <TableCell style={{ textTransform: `capitalize`, color: `initial` }}>{payment_details.stripe_card_response.card.brand} ending in {payment_details.stripe_card_response.card.last4}</TableCell>
                                <TableCell className={classes.tableTextColor}>{payment_details.stripe_card_response.billing_details.name}</TableCell>
                                <TableCell className={classes.tableTextColor}>{payment_details.stripe_card_response.card.exp_month}/{payment_details.stripe_card_response.card.exp_year}</TableCell>
                                <TableCell>
                                    <Button variant="contained" color="secondary" size="small" className={classes.button} startIcon={<Delete />} onClick={deleteCard}>Delete</Button>
                                </TableCell>
                            </TableRow>
                        )}

                    </TableBody>
                </Table>
            </TableContainer>
            {!loading && !payment_details.stripe_card_response && <AddUpdateCreditCard />}
        </Box>
    )
}

export { CreditCardDetails }