import { Card, CardContent, CardHeader } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useDispatch } from 'react-redux';
import { InfluencerAction } from '../../../../redux/actions';

function InfluencerWithMostStoriesGraph() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    /** Redux reducer actions */
    const getTopInfluencersByStories = (params) => dispatch(InfluencerAction.getInfluencersWithMostStories(params));

    /** Initialize and declare state */
    const [option, setOption] = useState({
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: 0,
            plotShadow: false,
            backgroundColor: 'rgba(0,0,0,0)',
        },
        title: {
            text: ''
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}% ({point.y:.0f})</b>'

        },
        accessibility: {
            point: {
                valueSuffix: '%'
            },
            enabled: false
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    enabled: true,
                    distance: -50,
                    style: {
                        fontWeight: 'bold',
                        color: 'white'
                    }
                },
                startAngle: -90,
                endAngle: 90,
                center: ['50%', '75%'],
                size: '110%'
            }
        },
        credits: {
            enabled: false
        }
    });

    useEffect(() => {
        getTopInfluencersByStories().then(result => {
            if (result.status)
                setOption({
                    series: [{
                        type: 'pie',
                        name: 'Stories',
                        innerSize: '50%',
                        data: result.data
                    }],
                })
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <Card>
            <CardHeader title="Influencer's with most stories" />
            <CardContent>
                <HighchartsReact highcharts={Highcharts} options={option} />
            </CardContent>
        </Card>
    )
}

export { InfluencerWithMostStoriesGraph }