import { Avatar, Card, CardContent, CardHeader, Chip, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { TicketAction } from '../../../../redux/actions';
import { NoData } from '../../../../_components/layout/no-data';

/** Initialize color values */
function getColor(status) {
    let color = 'error';
    if (status === 'open')
        color = "success"
    else if (status === 'closed')
        color = "error"
    else if (status === 'hold')
        color = "primary"
    else if (status === 're-open')
        color = "secondary"
    else if (status === 'resolved')
        color = "success"
    return color;
}

function TicketDetailsCard() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();

    const params = new URLSearchParams(search);
    const ticketId = params.get('T_Id'); // plan promo from url

    /** Redux actions and state */
    const { ticket_details_loading: isLoading, ticket_details } = useSelector((state) => state.TicketReducer);
    const ticketDetails = (params) => dispatch(TicketAction.ticketDetails(params));

    useEffect(() => {
        ticketDetails({ ticket_id: ticketId });
        //eslint-disable-next-line
    }, [ticketId])

    return (
        <Card>
            <Grid container spacing={2} display="flex" alignItems="center">
                {!isLoading && Object.keys(ticket_details).length > 0 &&
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <CardHeader
                            avatar={<Avatar src={ticket_details.brand_id.insta_profile.profile_picture_url} />}
                            title={ticket_details.brand_id.name}
                            subheader={ticket_details.created_at}
                            action={<Chip label={ticket_details.status.toUpperCase()} color={getColor(ticket_details.status)} />}
                        />
                        <CardContent sx={{ minHeight: `740px`, pt: 4 }}>
                            <Typography variant="h6">{`${ticket_details.subject}`}</Typography>
                            <Typography variant="body2">{`${ticket_details.description}`}</Typography>
                        </CardContent>
                    </Grid>
                }
                {isLoading && <NoData />}
            </Grid>
        </Card>
    )

}

export { TicketDetailsCard }