import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Stack } from '@mui/material';
import { PostAuthRoutes } from '../../routes'
import { Card, CardContent, CardHeader } from '../../_components/card'
import { Calendar, GeneralButton, RouteLink } from '../../_components';
import { SchedulePostAction } from '../../redux/actions';
import { Add } from '@mui/icons-material';

function SchedulePost() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    const { schedule_post_calendar } = useSelector((state) => state.SchedulePostReducer);

    const getSchedulePostCalendar = (params) => dispatch(SchedulePostAction.getSchedulePostCalendar(params));

    const handleViewRender = async ({ start, end }) => {
        await getSchedulePostCalendar({
            start: new Date(start).toISOString(),
            end: new Date(end).toISOString()
        });
    };

    function handleClick(e) {

        let id = e.event.extendedProps._id;

        history.push({
            pathname: PostAuthRoutes('schedule_post_details').path,
            search: `?spId=${id}`
        });
    }

    return (
        <React.Fragment>
            <Box>
                <CardHeader title={PostAuthRoutes('schedule_post').name} 
                action={
                    <Stack spacing={1} direction="row" alignItems={`center`} >
                        <RouteLink to={PostAuthRoutes('create_schedule_post').path} label={<GeneralButton startIcon={<Add />} label={PostAuthRoutes('create_schedule_post').name} />} />
                    </Stack>
                }/>
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Card>
                        <CardContent>
                            <Calendar events={schedule_post_calendar} handleViewRender={handleViewRender} handleClick={handleClick}/>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export { SchedulePost }