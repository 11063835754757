import { CardMedia } from "@mui/material";
import React from "react";
import { MediaCarousel } from "../../../../_components"
import { POST_BROKEN_IMAGE } from "../../../../_helpers";

function SchedulePostMedia({ post }) {

    const MediaContainer = ({data}) => {
        let extension = (data.media_url).split('.').pop();
        extension = extension.toLowerCase();
        
        return (
            <React.Fragment>
                { (data.media_type === 'image' || extension === 'gif') &&
                    <CardMedia component={'img'} src={data.media_url} onError={e => { e.target.src = POST_BROKEN_IMAGE }} style={{ position: "relative", padding: 0, margin: 0, width: "100%", borderTopRightRadius: 0, borderBottomRightRadius: 0 }} />
                }
                { (data.media_type === 'video' && extension !== 'gif') &&
                    <CardMedia component={"video"} src={data.media_url} onError={e => { e.target.component = 'img'; e.target.src = POST_BROKEN_IMAGE }} controls style={{ position: "relative", padding: 0, margin: 0, width: "100%", borderTopRightRadius: 0, borderBottomRightRadius: 0 }} />
                }
            </React.Fragment>
        )
    }
    
    return (
        <React.Fragment>
            { (post.post_type === 'post' || post.post_type === 'reels') &&
                <MediaContainer data={post} />
            }
            { post.post_type === 'carousel' &&
                <MediaCarousel>
                    {
                    post.carousel_medias.length > 0 && post.carousel_medias.map((carousel_media, i) => {
                        return(<MediaContainer key={i} data={carousel_media}/>)
                    })
                }
                </MediaCarousel>
            }
        </React.Fragment>

    )
}

export { SchedulePostMedia }