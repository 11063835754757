import { apiServices } from '../../_helpers';


export const InfluencerServices = {

    /**
     * Service to get influencer count
     * @param {Object} params - The params which are used for change password api.
     * @author 
     * @created_at 30 May 2022
     */
    getInfluencerCount: (params) => {
        return apiServices.post('/brand/influencer/count', params)
            .then((response) => { return response; })
    },

    /**
     * Service for list influencer
     * @param {Object} params - The params which are used for change password api.
     * @author 
     * @created_at 30 May 2022
     */
    listInfluencer: (params) => {
        return apiServices.post('/brand/influencer/list', params)
            .then((response) => { return response; })

    },

    /**
     * Service to get influencer details
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.infuencer_id
     * @author Akshay N 
     * @created_at 30 May 2022
    */
    influencerDetails: (params) => {
        return apiServices.post('brand/influencer/details', params)
            .then((response) => { return response; })
    },

    /**
     * Service to get top influencers by followers count
     * @param {Object} params - The params which are used for change password api.
     * @author 
     * @created_at 30 May 2022
     */
    getInfluencersWithMostFollowers: (params) => {
        return apiServices.post('/brand/influencer/influencers-with-most-followers', params)
            .then((response) => { return response; })
    },

    /**
    * Service to get top influencers by followers count
    * @param {Object} params - The params which are used for change password api.
    * @author 
    * @created_at 30 May 2022
    */
    getInfluencersWithMostStories: (params) => {
        return apiServices.post('/brand/influencer/influencers-with-most-stories', params)
            .then((response) => { return response; })
    },

    /**
     * Service to export influencer CSV
     * @param {Object} params - The params which are used for change password api
     * @author Jasin
     * @created_at 30 May 2022
    */
    exportInfluencerCsv: (params) => {
        return apiServices.post('/brand/influencer/export', params)
            .then((response) => { return response; })

    },

}