import React from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import { Facebook, Instagram } from '@mui/icons-material';

const Calendar = ({ events, handleViewRender, handleClick, ...props }) => {

	const useStyles = makeStyles((theme) => ({

		menuPaper: {
			'& .fc-daygrid-event.fc-event-end, .fc-daygrid-event.fc-event-start': {
				padding: '5px'
			},
			'& .fc-event-title': {
				fontWeight: 'bold'
			},
			'& .fc-event': {
				cursor: 'pointer',
				border: 'none',
				marginTop: '3px'
			}
		},
		icon: {
			verticalAlign: 'middle',
			marginRight: `10px`
		}
	}))

	const classes = useStyles();

	const getIcon = (icon) => {
		switch (icon) {
			case "facebook":
				return <Facebook className={classes.icon}/>;
		  	case "instagram":
				return <Instagram className={classes.icon}/>;
			default:
				return  '';
		}
	};

	return (
		<Box className={classes.menuPaper}>
			<FullCalendar
				plugins={[dayGridPlugin]}
				initialView="dayGridMonth"
				displayEventTime={false}
				events={events}
				eventDisplay="block"
				datesSet={handleViewRender}
				eventContent={function (info) {
					let event = info.event;
					let desc = event.extendedProps.description ? event.extendedProps.description : '';
					let icon = event.extendedProps.icon ? event.extendedProps.icon : '';
					return (
						<React.Fragment>
							<Typography align="left" variant="subtitle2" paragraph={false}>{icon ? getIcon(icon) : ''}{desc}<b>{event.title}</b></Typography>
						</React.Fragment>)
				}}
				eventClick={handleClick}
			/>
		</Box>
	);
};

export { Calendar };
