import { useEffect, useState } from "react";

const { FACEBOOK_API_VERSION, FACEBOOK_APP_ID } = require("./config");

export function useFacebookSDK() {
    const [fbsdk, setFbsdk] = useState({ newUser: false, authResponse: {} });

    useEffect(() => {
        setFbsdk({ ...fbsdk, fbsdkLoading: true });
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: FACEBOOK_APP_ID,
                xfbml: true,
                version: FACEBOOK_API_VERSION,
                cookie: true
            });
        };

        (function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk.js';
            fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLogin = () => {
        let scopes = [
            'instagram_basic', 'pages_show_list', 'instagram_manage_messages', 'pages_manage_metadata',  'instagram_manage_insights', 'pages_read_engagement', 'pages_manage_posts', 'instagram_content_publish'
        ];

        window.FB.login((response) => {
            if (!response || !response.authResponse) {
                return;
            } else {
                if (response.status === 'connected') {
                    let selectedPermissions = response.authResponse.grantedScopes.split(",");
                    let checkPermissions = scopes.every(v => selectedPermissions.includes(v));
                    if (checkPermissions === true) {
                        setFbsdk({ ...fbsdk, newUser: true, authResponse: response.authResponse });
                    } else {
                        setFbsdk({ ...fbsdk, newUser: false, authResponse: {} });
                        handleLogin();
                    }
                } else {
                    setFbsdk({ ...fbsdk, newUser: false, authResponse: {} });
                }
            }
        }, {
            scope: scopes.toString(),
            return_scopes: true,
            auth_type: 'rerequest'
        });
    };
    const handleLogout = () => {
        // console.log('logout');

        // window.FB.logout(function (response) {
        // });
    };

    return { fbsdk, handleLogin, handleLogout };
}