import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { Add, Check, Delete, PublishedWithChanges } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import { Card, CardContent } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { StoryAction } from '../../../../redux/actions';

/** Initialize TimelineIcon component */
const TimelineIcon = ({ timeline }) => {
    if (timeline.point === 0) {
        return <TimelineDot color='primary'><Add /></TimelineDot>;
    } else if (timeline.point >= 8) {
        return <TimelineDot color={`success`}><Check /></TimelineDot>
    }
    if (timeline.status === 'DELETED') {
        return <TimelineDot color='secondary'><Delete /></TimelineDot>
    }
    return <TimelineDot color={timeline.point % 2 === 0 ? 'success' : 'warning'}><PublishedWithChanges /></TimelineDot>
}

function StoryTimeline() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();

    const params = new URLSearchParams(search);
    const storyId = params.get('sId'); // promo id from url

    /** Redux reducer state */
    const { story_timeline_loading: isLoading, story_timeline } = useSelector((state) => state.StoryReducer);

    /** Redux reducer actions */
    const getStoryTimeline = (params) => dispatch(StoryAction.getStoryTimeline(params));

    React.useEffect(() => {
        getStoryTimeline({ story_id: storyId });
        //eslint-disable-next-line
    }, [storyId])

    if (isLoading)
        return ''

    return (
        <Card sx={{ padding: 6, width: `100%` }}>
            <CardContent >
                <Timeline position="alternate">
                    {story_timeline.map((timeline, i) => {
                        return (
                            <TimelineItem key={i}>
                                <TimelineOppositeContent sx={{ m: 'auto 0' }} align="right" variant="h6">{timeline.status}  </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineConnector />
                                    <TimelineIcon timeline={timeline} />
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent sx={{ py: '12px', px: 2 }}>
                                    <Typography variant="body2" component="span">{timeline.description}</Typography>
                                    <Typography>{timeline.checked_at}</Typography>
                                </TimelineContent>
                            </TimelineItem>
                        )
                    })}
                </Timeline>
            </CardContent>
        </Card>
    )
}

export { StoryTimeline }