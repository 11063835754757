import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from "@mui/styles";
import { Box, FormControlLabel, Radio, RadioGroup, Button, Divider, Typography } from '@mui/material';
import { Modal, ModalBody, ModalFooter, ModalHeader } from '../../../../_components';
import { PaymentAction } from '../../../../redux/actions';



const useStyles = makeStyles((theme) => ({
    paymentPreferenceDetailsBox: {
        padding: theme.spacing(2),
        background: theme.palette.primary.main,
        color: `#fff`,
    },
    paymentPreferenceBox: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(1)
    },
    paymentMethodTitle: {
        marginTop: theme.spacing(2)
    },
}));

function DefaultPayment() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    const classes = useStyles();
    const [showLoader, setShowLoader] = useState(false)
    const [defaultPaymentModal, setDefaultPaymentModal] = useState(false)


    const { payment_details_loading: loading, payment_details } = useSelector((state) => state.PaymentReducer);

    const setDefaultPayment = (params) => dispatch(PaymentAction.setDefaultPaymentMethod(params));
    const getPaymentDetails = () => dispatch(PaymentAction.getPaymentDetails());

    const [method, setMethod] = useState(payment_details.default_payment);


    const [data, setData] = useState(null);

    useEffect(() => {
        setMethod(payment_details.default_payment);
    }, [payment_details.default_payment])

    const setDefaultMethod = (e) => {
        setData(e.target.value);
        setDefaultPaymentModal(true);
    }


    const onClose = () => {
        setDefaultPaymentModal(false);
    }

    const setDefaultPaymentMethod = (e) => {
        setShowLoader(true);
        setDefaultPayment({ method: data }).then(() => {
            getPaymentDetails();
            setDefaultPaymentModal(false);
            setShowLoader(false);
        })
    }

    return (
        <React.Fragment>

            {/* BEGIN: Confirmation modal for setting default payment method */}
            <Modal isDialogOpen={defaultPaymentModal} onClose={onClose} loader={showLoader} size="md">
                <ModalHeader id="AddUpdateDeals" title="Default Payment Method" onClose={onClose} />
                <ModalBody className="text-center">
                    <h1>Are You Sure?</h1>
                    <p>{`Do you want to change, ${data} as your default payment method?`}</p>
                </ModalBody>
                <ModalFooter>
                    <Button variant="contained" color="primary" size="small" className={classes.button} onClick={setDefaultPaymentMethod}>Confirm</Button>
                    <Button variant="contained" color="secondary" size="small" className={classes.button} onClick={onClose}>Close</Button>
                </ModalFooter>
            </Modal>
            {/* END: Confirmation modal for setting default payment method */}

            {/* BEGIN: Payment preference section based on default payment */}
            {(!loading && (payment_details.default_payment === 'card' || payment_details.default_payment === 'bank')) && <Box className={classes.paymentPreferenceDetailsBox} color='secondary'>
                {!loading && payment_details.default_payment === 'card' && (
                    <React.Fragment>
                        <Typography variant="h6" component="p" color="initial" className={classes.paymentMethodTitle}>Choosen Payment Method</Typography>
                        <Typography variant="caption" component="p" style={{ textTransform: `capitalize` }}>{payment_details.stripe_card_response.card.brand} ending in {payment_details.stripe_card_response.card.last4}</Typography>
                    </React.Fragment>
                )}
                {!loading && payment_details.default_payment === 'bank' && (

                    <React.Fragment>
                        <Typography variant="h6" component="p" color="initial" className={classes.paymentMethodTitle}>Choosen Payment Method</Typography>
                        <Typography variant="caption" component="p" >{payment_details.stripe_bank_response.bank_name} account ending in {payment_details.stripe_bank_response.last4}</Typography>
                    </React.Fragment>
                )}
            </Box>}
            {/* END: Payment preference section based on default payment */}

            {/* BEGIN: Section to select default payment method */}
            <Box className={classes.paymentPreferenceBox}>
                {!loading && (
                    <RadioGroup row aria-label="method" name={`method`} value={method} onChange={setDefaultMethod}>
                        <FormControlLabel value="card" control={<Radio />} label="Pay with Credit Card" disabled={!payment_details.stripe_card_response} />
                        <FormControlLabel value="bank" control={<Radio />} label="Pay with Bank Account" disabled={!payment_details.stripe_bank_response || payment_details.stripe_bank_response.status === 'new'} />
                    </RadioGroup>
                )}
            </Box>
            <Divider />
            {/* BEGIN: Section to select default payment method */}
        </React.Fragment>
    )
}


export { DefaultPayment }
