import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, CardContent, Chip, Divider, Stack, Typography } from '@mui/material'
import { CurrencyRupee } from '@mui/icons-material'
import { styled } from "@mui/material/styles";
import { GeneralButton } from '../../../_components/controls';
import { PaymentAction } from '../../../redux/actions';
import { useEffect } from 'react';

/** Initialize colors */
const colors = { 'trial': '#2c5285', 'basic': '#0aaef2', 'premium': '#ea3661', 'ultimate': '#e97e98' }

/** Initialize plane card style */
const PlanCardComponent = styled(({ ...props }) => (<Card {...props} />))`
        background-color:  ${props => (((props.plan === props.current_plan) || (props.plan === props.current_giveaway_plan)) ? '#81d9b330' : colors[props.plan])}; 
        ${'' /* zoom: ${props => (((props.plan === props.current_plan) || (props.plan === props.current_giveaway_plan)) ? 1.03 : 1)}; */}
        &:hover {
            background-color:  #0000002e;
        }
` ;

function PlanCard({ planDetails, buy = false, planUpdateModal, type = 'main', ...props }) {

    /** Initialize form values from planDetails */
    const { plan, name, cost, number_of_coupons, current_plan, discount, discounted_cost, current_giveaway_plan } = planDetails;
    const { update_brand_plan_loading: planUpdateLoading, create_checkout_session_loading: isCheckoutLoading, create_giveaway_checkout_session_loading: isGiveawayCheckoutLoading } = useSelector((state) => state.PlanReducer);

    const getPaymentDetails = () => dispatch(PaymentAction.getPaymentDetails());

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    useEffect(() => {
        getPaymentDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    /**
     * function to enter update plan values
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    const buyPlan = () => {
        planUpdateModal({ ...planDetails, type })
    }

    return (
        <PlanCardComponent plan={plan} current_plan={(current_plan && current_plan.plan) ? current_plan.plan : null} current_giveaway_plan={(current_giveaway_plan && current_giveaway_plan.plan) ? current_giveaway_plan.plan : null} >
            <CardContent>
                <Stack spacing={2} direction="column" display={`flex`} alignItems={`center`} justifyContent={`center`} py={5}>
                    <Typography variant='h3'>{name}</Typography>
                    <Box style={{ minHeight: '80px' }}>
                        {discounted_cost &&
                            <Typography variant="h2" style={{ display: `flex`, alignItems: `center`, justifyContent: `center`, fontWeight: 500, textDecorationLine: 'line-through' }}>
                                <CurrencyRupee style={{ marginRight: '-5px' }} />{`${cost}`}
                            </Typography>
                        }
                        {discount && <Chip label={`${discount}% of discount`} size="small" />}
                    </Box>
                    <Typography variant="d3" style={{ display: `flex`, alignItems: `center`, justifyContent: `center`, fontWeight: 600 }}><CurrencyRupee />{`${discounted_cost ?? cost}`}</Typography>
                    {number_of_coupons && <Chip label={`${number_of_coupons} coupons`} size="small" />}
                    {buy && (plan !== 'co4cef339ced6b9aac04e1') && <GeneralButton label={((current_plan && current_plan.plan === plan) || (current_giveaway_plan && current_giveaway_plan.plan === plan)) ? 'Current Plan' : 'Buy Now'} disabled={((current_plan && current_plan.plan === plan) || (current_giveaway_plan && current_giveaway_plan.plan === plan)) || (planUpdateLoading || isCheckoutLoading || isGiveawayCheckoutLoading)} onClick={buyPlan} />}
                    {(plan === 'co4cef339ced6b9aac04e1') && <GeneralButton sx={{ visibility: 'hidden' }} />}
                </Stack>
                <Divider />
                {/* <Box style={{ minHeight: `125px` }}>
                    <Typography variant='subtitle2'>{description}</Typography>
                </Box> */}
                {/* {!buy && <RouteLink label={`Do you want to upgrade your plan?`} to={PostAuthRoutes('plans').path} mt={4} />} */}
            </CardContent>
        </PlanCardComponent>
    )
}

export { PlanCard }