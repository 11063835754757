import { Card, CardContent, CardMedia, Stack, Typography, Grid } from '@mui/material';
import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalFooterCloseBtn, ModalFooterSaveBtn } from '../../../_components/modal'
import { FormSelect, FormInput } from '../../../_components/form'
import questinMarkSvg from './question-mark.svg'
import { handleInputChange, validateForm } from "../../../_helpers";
import { validate } from ".";

// Initialize form input values to null
const inputs = { status: '', remark: '' };

// Declare status open select options
const statusOpenSelect = [
    { value: 'hold', label: 'Hold' },
    { value: 'closed', label: 'Closed' }
]

// Declare status closed select options
const statusClosedSelect = [
    { value: 're-open', label: 'Re-open' },
]

function UpdateStatusModal({ show, showLoader, data, closeModal, updateStatus }) {

    /** Initialize and declare state */
    const [ticketStatusData, setTicketStatusData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });

    /**
     * function to close update status modal
     * @param {string} status Entered status on ticket update status modal
     * @return view
     * @author
     * @created_at 30 May 2022
     */
    const onClose = () => {
        closeModal(false);
    }

    /**
     * function to handle submit entered values
     * @param {object} e form object
     * @author 
     * @created_at 30 May 2022
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        setAction({ isSubmitted: true });

        const formErrors = {};
        for (const [name, value] of Object.entries(ticketStatusData)) {
            const inputError = validate(name, value, ticketStatusData);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        if (data.status === 'open' || data.status === 're-open') {
            updateStatus({ id: data.id, current_status: data.status, ticket_change_status: ticketStatusData.new_status, remark: ticketStatusData.remark });
        }
        else {
            updateStatus({ id: data.id, current_status: data.status, ticket_change_status: '', remark: ticketStatusData.remark })
        }
    }

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author
     * @created_at 30 May 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, ticketStatusData)) })
        data[name] = value;
        setTicketStatusData({ ...data });
    }

    return (
        <Modal isDialogOpen={show} onClose={onClose} loader={showLoader} maxWidth="sm" PaperProps={{ style: { padding: 0 } }}>
            <Card style={{ boxShadow: `none`, padding: 30 }}>
                <CardMedia component="img" image={questinMarkSvg} style={{ position: "absolute", padding: 0, bottom: 0, right: 0, top: 0, width: "60%", opacity: '0.1' }} />
                <form onSubmit={handleSubmit} noValidate className='ns-form' >
                    <ModalBody style={{ padding: 0 }}>
                        <CardContent style={{ display: `flex`, alignItems: `center`, padding: `2rem 1rem 0 1rem` }}>

                            <Stack direction="column" justifyContent="flex-end" alignItems="flex-start" style={{ minHeight: `10px`, minWidth: `100%` }} spacing={3}>
                                <Typography variant='h3'>Are You Sure?</Typography>

                                <Stack direction="row" justifyContent="flex-end" alignItems="flex-start" style={{ minHeight: `100px`, minWidth: `60%` }} >
                                    <Grid container spacing={1}>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormSelect
                                                tabIndex={1}
                                                label={`Status`}
                                                name={`new_status`}
                                                placeholder={`Select status`}
                                                error={action.isSubmitted && errors.new_status ? errors.new_status : ''}
                                                onChange={handleChange}
                                                data={(data.status === 'closed') ? statusClosedSelect : statusOpenSelect}

                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormInput
                                                tabIndex={4}
                                                label='Remark'
                                                name='remark'
                                                value={ticketStatusData.remark}
                                                error={(action.isSubmitted && (errors.remark)) ? (errors.remark) : ''}
                                                onChange={handleChange}
                                                multiline
                                                maxLength={25}
                                                minRows={1}
                                            />
                                        </Grid>
                                    </Grid>
                                </Stack>

                            </Stack>

                        </CardContent>
                    </ModalBody>
                    <ModalFooter style={{ padding: 0 }}>
                        <ModalFooterSaveBtn tabIndex={3} text={'Update'} isProcessing={action.isProcessing} />
                        <ModalFooterCloseBtn tabIndex={4} onClose={onClose} />
                    </ModalFooter>
                </form>
            </Card>
        </Modal>
    )
}

export { UpdateStatusModal }
