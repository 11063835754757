import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import {SchedulePostAction } from "../../../redux/actions";
import { CardHeader as PageHeader } from "../../../_components/card";
import { PostAuthRoutes } from "../../../routes";
import { NoData } from "../../../_components";
import { SchedulePostCard, SchedulePostCardLoader } from "../schedule-post-card";


function SchedulePostDetails() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();

    const params = new URLSearchParams(search);

    const postId = params.get('spId'); // schedule post id from url

    /** Redux actions and state */
    const { schedule_post_details_loading: isLoading, schedule_post_details: post_details } = useSelector((state) => state.SchedulePostReducer);
    const schedulePostDetails = (params) => dispatch(SchedulePostAction.schedulePostDetails(params));

    /** Initialize and declare state */


    useEffect(() => {
        schedulePostDetails({ post_id: postId });
        //eslint-disable-next-line
    }, [postId])


    return (
        <React.Fragment>
            {!isLoading && Object.keys(post_details).length === 0 && <NoData content1={`No`} content2={`sheduled post available`} minHeight={`55vh`} ></NoData>}
            {(!isLoading && Object.keys(post_details).length > 0) &&
                <React.Fragment>
                    <PageHeader title={PostAuthRoutes('schedule_post_details').name} />
                    <Grid container spacing={2} display="flex" justifyContent="center" alignItems="center">
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                            <SchedulePostCard  post={post_details} />
                        </Grid>
                    </Grid>
                  
                </React.Fragment>}
            {isLoading && <SchedulePostCardLoader count={1}/>}
            
        </React.Fragment>
    );
}

export { SchedulePostDetails }