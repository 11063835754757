import { RegisterServices } from "../services";
import { GlobalAction } from "./GlobalAction";

export const CREATE_ACCOUNT_REQUEST = 'CREATE_ACCOUNT_REQUEST';
export const CREATE_ACCOUNT_SUCCESS = 'CREATE_ACCOUNT_SUCCESS';
export const CREATE_ACCOUNT_FAILURE = 'CREATE_ACCOUNT_FAILURE';


export const ADD_BILLING_ADDRESS_REQUEST = 'ADD_BILLING_ADDRESS_REQUEST';
export const ADD_BILLING_ADDRESS_SUCCESS = 'ADD_BILLING_ADDRESS_SUCCESS';
export const ADD_BILLING_ADDRESS_FAILURE = 'ADD_BILLING_ADDRESS_FAILURE';

export const RegisterAction = {


    /**
     * Action for insta sign up 
     * @param {Object} params - The params which are used for registration api.
     * @author Akshay N 
     * @created_at 28 October 2022
     */
    createAccount: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RegisterServices.createAccount(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: CREATE_ACCOUNT_REQUEST, request: request } }
        function success(request, response) { return { type: CREATE_ACCOUNT_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: CREATE_ACCOUNT_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action for add billing address
     * @param {Object} params - The params which are used for the api
     * @param {String} params.address
     * @param {String} params.city
     * @param {String} params.state
     * @param {String} params.country
     * @param {String} params.pincode
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    addBillingAddress: (params) => {
        return dispatch => {
            dispatch(request(params));
            return RegisterServices.addBillingAddress(params)
                .then(
                    response => { dispatch(GlobalAction.showToastMessage(response.message)); return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: ADD_BILLING_ADDRESS_REQUEST, request: request } }
        function success(request, response) { return { type: ADD_BILLING_ADDRESS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: ADD_BILLING_ADDRESS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
    * Action for sign out
    * @author Akshay N 
    * @created_at 30 May 2022
    */
    confirmEmail: (verificationString) => {
        return dispatch => {
            return RegisterServices.confirmEmail(verificationString)
                .then(
                    response => { return response; },
                    error => { return error; }
                );
        };

    },
};



