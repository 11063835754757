import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Helmet } from "react-helmet";

import { APP_NAME, handleInputChange, validateForm } from '../../../_helpers';
import { PreSignIn } from '../../../_layouts/pre-signin';
import { PostAuthRoutes, PreAuthRoutes } from '../../../routes';

import { FormInput, FormPassword } from '../../../_components/form';
import { SubmitButton } from '../../../_components/controls';
import { RouteLink } from '../../../_components/controls/route-link';

import { Box, Grid, Typography } from '@mui/material';
import { SignInAction } from '../../../redux/actions';
import { validate } from '.'
import { Header } from '../../../_layouts/pre-signin/header';
import { InstaSignIn } from '../insta-sign-in';

// Initialize form input values to null
const inputs = { email: '', password: '' };

const OrDivider = ({ children }) => {
    return (
        <Box style={{ display: 'flex', alignItems: 'center' }}>
            <Box style={{ borderBottom: '1px solid #ffffff1f', width: '100%' }} />
            <Box style={{ padding: '0 10px 0 10px' }}>
                {children}
            </Box>
            <Box style={{ borderBottom: '1px solid #ffffff1f', width: '100%' }} />
        </Box>
    );
};

function SignIn() {

    /** Initialize plugins and variables */
    const history = useHistory();
    const dispatch = useDispatch();

    /** Redux actions and state */
    const { loading: isLoading, insta_signin_loading: isInstaSigninLoading, errors: signinErrors } = useSelector((state) => state.SignInReducer);
    const signIn = (params) => dispatch(SignInAction.signIn(params));

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, remember_me: '' });
    const [action, setAction] = useState({ isSubmitted: false });

    useEffect(() => {
        setErrors({ ...signinErrors });
    }, [signinErrors])

    /**
     * function to handle input changes and alter the value
     * @param object e input object with name and value
     * @author Akshay N
     * @created_at 30 May 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit
     * @param object e form object
     * @author Akshay N
     * @created_at 30 May 2022
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        dispatchSignInAction();
    }

    /**
     * function to dispatch sign in
     * @author Akshay N
     * @created_at 30 May 2022
     */
    async function dispatchSignInAction() {
        const isSignin = await signIn(data);
        if (isSignin.status === 1) {
            history.push({
                pathname: PostAuthRoutes('home').path,
            });
        }
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${PreAuthRoutes('login').name}`}</title>
            </Helmet>
            <PreSignIn >
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3} >
                        <Grid item xs={12} sm={12} md={5} lg={5} xl={5} m={0} p={0}>
                            <Header title={`${PreAuthRoutes('login').name}`} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={7} lg={7} xl={7} >
                            <Grid container spacing={2} >
                                <Grid item xs={12} sm={12} p="0">
                                    <Typography variant="h4" fontWeight="medium" color="white" align="center" mt={2} >{`Login`}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} p="0">
                                    <FormInput
                                        tabIndex={1}
                                        label='Email'
                                        name='email'
                                        value={data.email}
                                        error={action.isSubmitted && errors.email ? errors.email : ''}
                                        onChange={handleChange}
                                        autoFocus={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} p="0">
                                    <FormPassword
                                        tabIndex={1}
                                        label='Password'
                                        name='password'
                                        value={data.password}
                                        error={action.isSubmitted && errors.password ? errors.password : ''}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                            <Box display="flex" alignItems="center" justifyContent="left" mt={1}>
                                <RouteLink to={PreAuthRoutes('forgot_password').path} label={PreAuthRoutes('forgot_password').name} />
                            </Box>
                            <Box display="flex" alignItems="center" justifyContent="space-between" mt={2} mb={2}>
                                <SubmitButton label={'Log In'} loading={isLoading || isInstaSigninLoading} fullWidth />
                            </Box>
                            <OrDivider> OR </OrDivider>
                            <Box display="flex" alignItems="center" justifyContent="space-between" mt={2}>
                                <InstaSignIn />
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            </PreSignIn>
        </React.Fragment>
    );
}

export { SignIn };
