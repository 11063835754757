import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { handleInputChange, validateForm } from '../../../../_helpers';
import { Box, Stack } from '@mui/material';
import { FormInput, SubmitButton } from '../../../../_components';
import { validate } from '.';
import { useDispatch, useSelector } from 'react-redux';
import { MessagesAction } from '../../../../redux/actions';

const inputs = { message_content: '', influencer_igsid: '' }

function ConversationReply() {

    let { search } = useLocation();
    const dispatch = useDispatch();

    const params = new URLSearchParams(search);
    const pId = params.get('pId');

    /** Redux actions and state */
    const { send_direct_message_loading: isLoading, send_direct_message_error: sendDirectMessageError, conversation_details_loading: isDetailsLoading, conversation_details_string } = useSelector((state) => state.MessagesReducer);
    const sendDirectMessage = (params) => dispatch(MessagesAction.sendDirectMessage(params));

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });

    useEffect(() => {
        setErrors({ ...sendDirectMessageError });
    }, [sendDirectMessageError])

    useEffect(() => {
        setData({ ...data, message_content: '', influencer_igsid: pId, });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pId])


    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Naveen
     * @created_at 30 May 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    const handleSubmit = (e) => {

        e.preventDefault();
        setAction({ ...action, isSubmitted: true, isProcessing: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ ...action, isSubmitted: true, isProcessing: false });
            return;
        }
        dispatchMessageReplyAction(data);
    }

    async function dispatchMessageReplyAction(formData) {
        const isCreated = await sendDirectMessage(formData);
        if (isCreated.status === 1) {
            setData({ ...data, message_content: '' });
        }
    }

    if (!pId) {
        return ''
    }

    return (
        <Box sx={{ position: `absolute`, bottom: 16, left: 16, right: 16 }}>
            <form onSubmit={handleSubmit} noValidate>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}  >
                    <FormInput
                        tabIndex={1}
                        label='Reply'
                        name='message_content'
                        value={data.message_content}
                        error={action.isSubmitted && errors.message_content ? errors.message_content : ''}
                        onChange={handleChange}
                        autoFocus={true}
                        isReadOnly={isDetailsLoading || (conversation_details_string === "")}
                    />
                    <SubmitButton label='SEND' loading={isLoading} sx={{ height: `43px` }} />
                </Stack>
            </form>
        </Box>
    )
}

export { ConversationReply }