import React from "react";
import { Helmet } from "react-helmet";
import { APP_NAME } from "../../../../_helpers";
import { PostAuthRoutes } from "../../../../routes";
import { CardHeader } from "../../../../_components";
import { Box, List, ListItem, Typography } from "@mui/material";

const listContainer = {
    listStyleType: 'disc', 
    listStylePosition: 'inside'
};
const listItem = {
    display: 'list-item'
};

function SchedulePostSpecifications() {


    return (
        <React.Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${PostAuthRoutes('schedule_post_specifications').name}`}</title>
            </Helmet>
            <Box>
                <CardHeader title={PostAuthRoutes('schedule_post_specifications').name} />
                <Typography variant="h6" paragraph>
                    Instagram Image Specifications
                </Typography>
                <List style={listContainer}>
                    <ListItem style={listItem}>
                        Format: JPEG
                    </ListItem>
                    <ListItem style={listItem}>
                        File size: 8 MB maximum.
                    </ListItem>
                    <ListItem style={listItem}>
                        Aspect ratio: Must be within a 4:5 to 1.91:1 range
                    </ListItem>
                    <ListItem style={listItem}>
                        Minimum width: 320
                    </ListItem>
                    <ListItem style={listItem}>
                        Maximum width: 1440
                    </ListItem>
                    <ListItem style={listItem}>
                        Color Space: sRGB. Images using other color spaces will have their color spaces converted to sRGB.
                    </ListItem>
                </List>
                <br/>
                <Typography variant="h6" paragraph>
                    Instagram Video Specifications
                </Typography>
                <List style={listContainer}>
                    <ListItem style={listItem}>
                        Container: MOV or MP4 (MPEG-4 Part 14), no edit lists, moov atom at the front of the file.
                    </ListItem>
                    <ListItem style={listItem}>
                        Audio codec: AAC, 48khz sample rate maximum, 1 or 2 channels (mono or stereo).
                    </ListItem>
                    <ListItem style={listItem}>
                        Video codec: HEVC or H264, progressive scan, closed GOP, 4:2:0 chroma subsampling.
                    </ListItem>
                    <ListItem style={listItem}>
                        Frame rate: 23-60 FPS.
                    </ListItem>
                    <ListItem style={listItem}>
                        Picture size: Maximum columns (horizontal pixels): 1920
                    </ListItem>
                    <ListItem style={listItem}>
                        Picture size: Minimum aspect ratio [cols / rows]: 4 / 5
                    </ListItem>
                    <ListItem style={listItem}>
                        Picture size: Maximum aspect ratio [cols / rows]: 16 / 9
                    </ListItem>
                    <ListItem style={listItem}>
                        Video bitrate: VBR, 25Mbps maximum
                    </ListItem>
                    <ListItem style={listItem}>
                        Audio bitrate: 128kbps
                    </ListItem>
                    <ListItem style={listItem}>
                        Duration: 60 seconds maximum, 3 seconds minimum
                    </ListItem>
                    <ListItem style={listItem}>
                        File size: 100MB maximum
                    </ListItem>
                </List>

                <br/>
                <Typography variant="h6" paragraph>
                    Instagram Reels Specifications
                </Typography>
                <List style={listContainer}>
                    <ListItem style={listItem}>
                        Container: MOV or MP4 (MPEG-4 Part 14), no edit lists, moov atom at the front of the file.
                    </ListItem>
                    <ListItem style={listItem}>
                        Audio codec: AAC, 48khz sample rate maximum, 1 or 2 channels (mono or stereo).
                    </ListItem>
                    <ListItem style={listItem}>
                        Video codec: HEVC or H264, progressive scan, closed GOP, 4:2:0 chroma subsampling.
                    </ListItem>
                    <ListItem style={listItem}>
                        Frame rate: 23-60 FPS.
                    </ListItem>
                    <ListItem style={listItem}>
                        Picture size: Maximum columns (horizontal pixels): 1920
                    </ListItem>
                    <ListItem style={listItem}>
                        Required aspect ratio is between 0.01:1 and 10:1 but we recommend 9:16 to avoid cropping or blank space.
                    </ListItem>
                    <ListItem style={listItem}>
                        Picture size: Maximum aspect ratio [cols / rows]: 16 / 9
                    </ListItem>
                    <ListItem style={listItem}>
                        Video bitrate: VBR, 25Mbps maximum
                    </ListItem>
                    <ListItem style={listItem}>
                        Audio bitrate: 128kbps
                    </ListItem>
                    <ListItem style={listItem}>
                        Duration: 15 mins maximum, 3 seconds minimum
                    </ListItem>
                    <ListItem style={listItem}>
                        File size: 1GB maximum
                    </ListItem>
                </List>

                <br/>
                <Typography variant="h6" paragraph>
                    Facebook Image Specifications
                </Typography>
                <List style={listContainer}>
                    <ListItem style={listItem}>
                        Format: Animated photos are not supported
                    </ListItem>
                    <ListItem style={listItem}>
                        File size: 10 MB maximum.
                    </ListItem>
                </List>
                <br/>
                <Typography variant="h6" paragraph>
                    Fcaebook Video Specifications
                </Typography>
                <List style={listContainer}>
                    <ListItem style={listItem}>
                        Format: 3g2, 3gp, 3gpp, asf, avi, dat, divx, dv, f4v, flv, gif, m2ts, m4v, mkv, mod, mov, mp4, mpe, mpeg, mpeg4, mpg, mts, nsv, ogm, ogv, qt, tod, ts, vob, and wmv.
                    </ListItem>
                    <ListItem style={listItem}>
                        File size: 1 GB maximum.
                    </ListItem>
                </List>
                <br/>
                <Typography variant="h6" paragraph>
                    Facebook Reels Specifications
                </Typography>
                <List style={listContainer}>
                    <ListItem style={listItem}>
                        Container: .mp4
                    </ListItem>
                    <ListItem style={listItem}>
                        Audio codec: AAC Low Complexity, 48kHz sample rate, stereo
                    </ListItem>
                    <ListItem style={listItem}>
                        Video codec: H.264 or H.265 (VP9, AV1 are also supported), fixed frame rate, progressive scan, closed GOP (2-5 seconds), chroma subsampling 4:2:0
                    </ListItem>
                    <ListItem style={listItem}>
                        Frame rate: 24-60 FPS
                    </ListItem>
                    <ListItem style={listItem}>
                        Picture size (resolution): Minimum: 540 x 960 pixels, Recommended: 1080 x 1920 pixels
                    </ListItem>
                    <ListItem style={listItem}>
                        Aspect ratio: 9:16
                    </ListItem>
                    <ListItem style={listItem}>
                        Audio bitrate: 128kbs or higher
                    </ListItem>
                    <ListItem style={listItem}>
                        Duration: 90 seconds maximum, 3 seconds minimum
                    </ListItem>
                </List>
            </Box>
        </React.Fragment>
    )
}

export { SchedulePostSpecifications };
