import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import { Box, Typography } from "../../../_components";
import { PRODUCT_NAME } from "../../../_helpers";
import Grid from '@mui/material/Grid'

function CopyRight({ light }) {

  return (
    <Box position="unset" width="100%" bottom={0} py={3} sx={({ breakpoints }) => ({ [breakpoints.up("md")]: { position: 'absolute', py: 4 } })} >
      <Container>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" >
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box display="flex" justifyContent="center" alignItems="center" flexWrap="wrap" color={light ? "white" : "text"} fontSize={`14px`} sx={{ justifyContent: { md: 'left' } }}>
              &copy; {new Date().getFullYear()} Made by
              <Link href="https://www.adrundigital.com/" target="_blank"><Typography variant="button" color={light ? "white" : "dark"}>&nbsp;{PRODUCT_NAME}&nbsp;  </Typography>  </Link>
              for a better web.
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
            <Box component="ul" sx={({ breakpoints }) => ({ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "center", listStyle: "none", mt: 0, mb: 0, p: 0, [breakpoints.up("md")]: { justifyContent: 'right' } })} >
              <Box component="li" pr={2} lineHeight={1}>
                <Link href="https://adrundigital.com/featured-promos?p=1" target="_blank">
                  <Typography variant="button" fontWeight="regular" color={light ? "white" : "dark"}> Promos </Typography>
                </Link>
              </Box>
              <Box component="li" pr={2} lineHeight={1}>
                <Link href="https://www.adrundigital.com/about-us" target="_blank">
                  <Typography variant="button" fontWeight="regular" color={light ? "white" : "dark"}> About Us </Typography>
                </Link>
              </Box>
              <Box component="li" pr={2} lineHeight={1}>
                <Link href="https://www.adrundigital.com/contact-us" target="_blank">
                  <Typography variant="button" fontWeight="regular" color={light ? "white" : "dark"}> Support Center </Typography>
                </Link>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box >
  );
}

// Setting default props for the CopyRight
CopyRight.defaultProps = {
  light: true,
};

// Typechecking props for the CopyRight
CopyRight.propTypes = {
  light: PropTypes.bool,
};

export { CopyRight };
