import React, { useState } from 'react';
import { Box, Button, Card, Grid, Stack } from '@mui/material';
import { PostAuthRoutes } from '../../routes'
import { CardContent, CardHeader } from '../../_components/card'
import { WalletBalanceWidget } from './wallet-balance-widget';
import { CreateWallet } from './wallet-create';
import { WalletHistoryDataTable } from './WalletHistoryDataTable';
import { WalletTotalCreditWidget } from './wallet-total-credit-widget';
import { WalletMonthlyCreditWidget } from './wallet-monthly-credit-widget';
import { GeneralButton, RouteLink } from '../../_components';
import { FilterAlt } from '@mui/icons-material';
import { WalletFilter } from './wallet-filter';

function BrandWallet() {

    /** Initialize and declare state */
    const [showCreateWalletModal, setShowCreateWalletModal] = useState(false);
    const [payload, setPayload] = useState({ limit: 10, page: 1, filter: {} });
    const [showWalletFilterModal, setShowWalletFilterModal] = useState(false);

    /**
     * function to close plans update modal
     * @return view
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    const closeModal = () => {
        setShowCreateWalletModal(!showCreateWalletModal);
    }

    /**
     * function to create wallet
     * @return view
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    const createWalletModal = () => {
        setShowCreateWalletModal(!showCreateWalletModal);
    }

    
    /**
     * function to open/close wallet filter modal
     * @param {string} null 
     * @return view
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const walletFilterModal = () => {
        setShowWalletFilterModal(!showWalletFilterModal);
    }

    /**
     * function to apply the selected filter options on the wallet list
     * @param {Object} filterData 
     * @param {String} filterData.status Selected status from the wallet filter modal
     * @return view
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const applyFilter = (filterData) => {
        setPayload({ ...payload, page: 1, filter: filterData });
    }

    return (
        <React.Fragment>
            <Box>
                <CardHeader
                    title={PostAuthRoutes('wallet').name}
                    action={
                        <Stack spacing={1} direction="row">
                            <Button style={{ marginTop: '10px' }} variant="contained" color="primary" onClick={createWalletModal}>Add Money To Your Wallet</Button>
                            <RouteLink style={{ marginTop: '10px' }} to={PostAuthRoutes('wallet_statements').path} label={<GeneralButton label={PostAuthRoutes('wallet_statements').name} />} />
                            <GeneralButton style={{ marginTop: '10px' }} label={<FilterAlt />} onClick={() => walletFilterModal()} />
                        </Stack>
                    }
                />
            </Box>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <WalletBalanceWidget />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <WalletTotalCreditWidget />
                </Grid>
                <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                    <WalletMonthlyCreditWidget />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <WalletFilter show={showWalletFilterModal} closeModal={walletFilterModal} applyFilter={applyFilter} walletFilter={payload.filter} />
                    <Card>
                        <CardContent>
                            <WalletHistoryDataTable limit={payload.limit} page={payload.page} filter={payload.filter} />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <CreateWallet show={showCreateWalletModal} closeModal={closeModal} />

        </React.Fragment>
    )
}

export { BrandWallet }