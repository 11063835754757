import React from 'react';
import { Box, Card, Grid, Skeleton } from '@mui/material';

function StoryCardLoader({ ...props }) {

    var rows = [];
    for (var i = 0; i < 8; i++) {
        rows.push(
            <Grid item xs={12} sm={6} md={3} lg={2} xl={2} key={i}  {...props}>
                <Card>
                    <Grid container spacing={2} display="flex" alignItems="center">
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Box style={{ width: `100%` }}>
                                <Skeleton variant="rectangular" height={544} style={{ borderRadius: `0.75rem` }} />
                            </Box>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        )
    }

    return <React.Fragment> {rows} </React.Fragment>
}

export { StoryCardLoader };
