import { Event, FileDownload, Instagram, MoreVert, Numbers } from '@mui/icons-material'
import { Box, CardMedia, Chip, Divider, Grid, IconButton, Menu, MenuItem, Stack, Card, CardContent, Typography } from '@mui/material'
import React from 'react'
import { useHistory } from 'react-router'
import { PostAuthRoutes } from '../../../routes'
import { POST_BROKEN_IMAGE } from '../../../_helpers'

// const timeBetweenPostOptions = [
//     { value: 'one_time', label: 'One time' },
//     { value: 'once_in_a_day', label: 'Once in a day' },
//     { value: 'once_in_a_week', label: 'Once in a week' },
//     { value: 'once_in_a_month', label: 'Once in a month' },
// ]

function GiveawayCard({ giveaway, statusUpdateModal, hasAction = true }) {

    /** Initialize form statusData values from giveaway */
    const statusData = { id: giveaway._id, status: giveaway.status, item: '' };

    /** Initialize and declare state */
    const [anchorEl, setAnchorEl] = React.useState(null);

    /** Initialize plugins and variables */
    const open = Boolean(anchorEl);
    let history = useHistory();

    /**
     * function to handle click icon menu
     * @param {object} event form object
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    /**
     * function to handle close icon menu
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    /**
     * function to direct to update giveaway page
     * @return view
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    function handleUpdateClick() {
        setAnchorEl(null);
        history.push({
            pathname: PostAuthRoutes('update_giveaways').path,
            state: { giveaway: giveaway },
        });
    }

    /**
     * function to direct to update giveaway page
     * @return view
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    function handleDetailsClick() {
        setAnchorEl(null);
        history.push({
            pathname: PostAuthRoutes('giveaway_details').path,
            search: `?gId=${giveaway._id}`
        });
    }

    /**
     * function to change giveaway status
     * @return view
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    function handleStatusChangeClick() {
        setAnchorEl(null);
        setTimeout(() => {
            statusUpdateModal(statusData);
        }, 100);
    }

    // const howOften = (value) => {
    //     const option = timeBetweenPostOptions.find(option => option.value === value);
    //     return option ? option.label : '';
    // }

    return (
        <Card style={{ "height": "100%" }}>
            <Grid container spacing={2} display="flex" alignItems="center">
                <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                    <Box style={{ width: `100%` }}>
                        <CardMedia component={"img"} src={giveaway.sample_story} onError={e => { e.target.src = POST_BROKEN_IMAGE }} style={{ position: "relative", padding: 0, margin: 0, width: "100%", borderTopRightRadius: 0, borderBottomRightRadius: 0 }} />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                    <CardContent>
                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} mb={1} mt={2}>
                            <Typography variant="h5" onClick={handleDetailsClick} sx={{ cursor: `pointer` }}>{`${giveaway.name}`}</Typography>
                            <Box>
                                {giveaway.active_today &&
                                    <Chip size='small' label='Active Today' color='primary' sx={{ mr: 1 }} />
                                }
                                <Chip size='small' label={giveaway.status === 'active' ? 'Active' : 'Inactive'} color={giveaway.status === 'active' ? 'success' : 'error'} />
                                {hasAction &&
                                    <React.Fragment>
                                        <IconButton aria-label="more" id="long-button" aria-controls={open ? 'long-menu' : undefined} aria-expanded={open ? 'true' : undefined} aria-haspopup="true" onClick={handleClick} ><MoreVert /></IconButton>
                                        <Menu id="long-menu" MenuListProps={{ 'aria-labelledby': 'long-button' }} anchorEl={anchorEl} open={open} onClose={handleClose}  >
                                            <MenuItem onClick={handleDetailsClick}>{`Details`}</MenuItem>
                                            <MenuItem onClick={handleStatusChangeClick}>{giveaway.status === 'active' ? 'Deactivate' : 'Activate'}</MenuItem>
                                            <MenuItem disabled={giveaway.giveaway_running && giveaway.status === 'active'} onClick={handleUpdateClick}>{`Update`}</MenuItem>
                                        </Menu>
                                    </React.Fragment>
                                }
                            </Box>
                        </Box>
                        <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>{`${giveaway.description}`}</Typography>
                        <Divider />
                        <Box display="flex" justifyContent="space-between" alignItems="center" >
                            <Typography variant="body2"><Instagram /> {`Instagram handle : `}</Typography>
                            <Typography variant="h6">{`@${giveaway.insta_handle}`}</Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center" >
                            <Typography variant="body2"><Event /> {`Start date : `}</Typography>
                            <Typography variant="h6">{`${giveaway.start_date}`}</Typography>
                        </Box>
                        <Box display="flex" justifyContent="space-between" alignItems="center" >
                            <Typography variant="body2"><Event /> {`End date : `}</Typography>
                            <Typography variant="h6">{`${giveaway.end_date}`}</Typography>
                        </Box>
                        <Divider />
                        <Stack spacing={1}>
                            {/* {giveaway.time_between_post &&
                                <Box display="flex" justifyContent="space-between" alignItems="center" >
                                    <Typography variant="body2">{`How often : `}</Typography>
                                    <Chip label={`${howOften(giveaway.time_between_post)}`} size="small" icon={<PostAdd />} />
                                </Box>
                            } */}
                            {(giveaway.send_coupon_code === 'yes' || giveaway.coupon_codes > 0) &&
                                <React.Fragment>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                                        <Typography variant="body2">{`Coupon file uploaded : `}</Typography>
                                        <Chip component="a" href={giveaway.coupon_code_file} label={`Download`} clickable size="small" icon={<FileDownload />} />
                                    </Box>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" >
                                        <Typography variant="body2">{`Coupon codes : `}</Typography>
                                        <Chip component="a" href={`${PostAuthRoutes('giveaways_coupon_codes').path}?gId=${giveaway._id}`} clickable label={`${giveaway.used_coupon_count}/${giveaway.coupon_codes}`} size="small" icon={<Numbers />} />
                                    </Box>
                                    <Divider />
                                </React.Fragment>
                            }
                            <Box display="flex" justifyContent="space-between" alignItems="center" >
                                <Typography variant="body2" align="left" style={{ minWidth: "35%" }}>{`Thank you message : `}</Typography>
                                <Typography variant="body2" align="right" style={{ whiteSpace: 'pre-line' }}>{`${giveaway.thank_you_message}`}</Typography>
                            </Box>
                        </Stack>
                    </CardContent>
                </Grid>
            </Grid>
        </Card>
    )
}

export { GiveawayCard }