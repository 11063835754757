import React, { useEffect } from 'react'
import { Box, Card, CardContent, CardMedia, Divider, Grid, Skeleton, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { StoryAction } from '../../../../redux/actions';
import { POST_BROKEN_IMAGE } from '../../../../_helpers';

function StoryPromoDetails() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();

    const params = new URLSearchParams(search);
    const storyId = params.get('sId'); // story id from url

    /** Redux reducer state */
    const { promo_loading: isLoading, promo } = useSelector((state) => state.StoryReducer);

    /** Redux reducer actions */
    const getPromoDetails = (params) => dispatch(StoryAction.getPromoDetails(params));

    useEffect(() => {
        getPromoDetails({ story_id: storyId });
        //eslint-disable-next-line
    }, [storyId])

    return (
        <Card sx={{ padding: 6 }}>
            <Grid container>
                <Grid item xs={12} sm={12} md={8} lg={8} xl={8} display="flex" alignItems="center"  >
                    <CardContent >
                        <Box>
                            <Typography variant={`h5`} sx={{ mb: 1 }}>{promo.name}</Typography>
                            <Box style={{ minHeight: `60px` }}>
                                <Typography variant={`subtitle2`} sx={{ fontWeight: 500, whiteSpace: 'pre-line' }}>{promo.description}</Typography>
                            </Box>
                            <Typography variant={`body2`} sx={{ fontWeight: 500 }} mt={2}>{`Mention @${promo.insta_handle}`}</Typography>
                            <Divider />
                            <Typography variant={`body2`} sx={{ fontWeight: 500 }} mt={2}>{`${promo.promo_start_date} - ${promo.promo_end_date}`}</Typography>
                            <Typography variant={`body2`} sx={{ fontWeight: 500 }}>{`Brand name - ${promo.brand_name}`}</Typography>
                        </Box>
                    </CardContent>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                    {isLoading ? (
                        <Skeleton variant="rectangular" width={180} height={320} />
                    ) : (
                        <CardMedia component="img" image={promo.file} onError={e => { e.target.src = POST_BROKEN_IMAGE }} style={{ position: "relative", padding: 0, margin: 0, width: "100%" }} alt={promo.name} />
                    )}
                </Grid>
            </Grid>
        </Card>
    )
}

export { StoryPromoDetails }