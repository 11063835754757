import {
    GET_SCHEDULE_POST_CALENDAR_REQUEST, GET_SCHEDULE_POST_CALENDAR_SUCCESS, GET_SCHEDULE_POST_CALENDAR_FAILURE,    
    SCHEDULE_POST_DETAILS_REQUEST, SCHEDULE_POST_DETAILS_SUCCESS, SCHEDULE_POST_DETAILS_FAILURE,
    CREATE_SCHEDULE_POST_REQUEST, CREATE_SCHEDULE_POST_SUCCESS, CREATE_SCHEDULE_POST_FAILURE,
    UPDATE_SCHEDULE_POST_REQUEST, UPDATE_SCHEDULE_POST_SUCCESS, UPDATE_SCHEDULE_POST_FAILURE,
} from "../actions";

const initialState = {
    schedule_post_calendar_loading: false, schedule_post_calendar: {}, schedule_post_calendar_error: {},
    schedule_post_details_loading: false, schedule_post_details: {}, schedule_post_details_errors: {},
    create_schedule_post_loading: false, create_schedule_post: {}, create_schedule_post_errors: {},
};

export function SchedulePostReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::Schedule post calendar redux */
        case GET_SCHEDULE_POST_CALENDAR_REQUEST: {
            return {
                ...state,
                schedule_post_calendar_loading: true,
                schedule_post_calendar_error: {}
            };
        } case GET_SCHEDULE_POST_CALENDAR_SUCCESS: {

            return {
                ...state,
                schedule_post_calendar_loading: false,
                schedule_post_calendar: action.data,
                schedule_post_calendar_error: {}
            };
        } case GET_SCHEDULE_POST_CALENDAR_FAILURE: {
            return {
                ...state,
                schedule_post_calendar_loading: false,
                schedule_post_calendar_error: action.error
            };
        }
        /** end::Schedule post calendar redux */

        
        /** begin::schedule post details redux */
        case SCHEDULE_POST_DETAILS_REQUEST: {
            return {
                ...state,
                schedule_post_details_loading: true,
                schedule_post_details_errors: {}
            };
        }
        case SCHEDULE_POST_DETAILS_SUCCESS: {
            return {
                ...state,
                schedule_post_details_loading: false,
                schedule_post_details: action.data,
                schedule_post_details_errors: {}
            };
        }
        case SCHEDULE_POST_DETAILS_FAILURE: {
            return {
                ...state,
                schedule_post_details_loading: false,
                schedule_post_details_errors: action.errors
            };
        }
        /** end::schedule post details redux */

        /** begin::Create promo redux */
        case CREATE_SCHEDULE_POST_REQUEST: {
            return {
                ...state,
                create_schedule_post_loading: true,
                create_schedule_post_errors: {}
            };
        }
        case CREATE_SCHEDULE_POST_SUCCESS: {

            return {
                ...state,
                create_schedule_post_loading: false,
                create_schedule_post: action.data,
                create_schedule_post_errors: {}
            };
        }
        case CREATE_SCHEDULE_POST_FAILURE: {
            return {
                ...state,
                create_schedule_post_loading: false,
                create_schedule_post_errors: action.errors
            };
        }
        /** end::Create promo redux */

        
        /** begin::Update promo redux */
        case UPDATE_SCHEDULE_POST_REQUEST: {
            return {
                ...state,
                create_schedule_post_loading: true,
                create_schedule_post: {}
            };
        }
        case UPDATE_SCHEDULE_POST_SUCCESS: {
            return {
                ...state,
                create_schedule_post_loading: false,
                create_schedule_post: action.data,
                create_schedule_post_errors: {}
            };
        }
        case UPDATE_SCHEDULE_POST_FAILURE: {
            return {
                ...state,
                create_schedule_post_loading: false,
                create_schedule_post_errors: action.errors
            };
        }
        /** end::Update promo redux */
        default:
            return state;
    }
}