import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Autocomplete, Box, FormControl, FormHelperText, TextField } from '@mui/material';
import { FormMultiSelectStyle as useStyles } from ".";

function FormMultiSelect({ label, data, name, value, placeholder, className, onChange, tabIndex, error, info, readOnly, ...props }) {

    const classes = useStyles();
    const [selected, setSelected] = useState([]);

    useEffect(() => {

        if (value && value.length > 0) {
            const selectedObj = data.filter(obj => value.includes(obj.value));
            setSelected(selectedObj);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    const handleSelectChange = (e, selectedObj) => {
        if (selectedObj && selectedObj.length > 0) {
            const selectValues = selectedObj.map(obj => obj.value);
            let target = {
                'name': name,
                'value': selectValues,
                'type': 'multi-select'
            }
            setSelected(selectedObj);
            onChange({ target })
        } else {
            setSelected([]);
            onChange({
                target: {
                    'name': name,
                    'value': [],
                    'type': 'multi-select'
                }
            })
        }
    }

    return (
        <FormControl fullWidth className={classes.formControl} error >
            <Autocomplete
                multiple
                {...(readOnly) ? { readOnly: true} :{}} 
                tabIndex={tabIndex}
                options={data}
                onChange={handleSelectChange}
                getOptionLabel={(option) => option.label}
                value={selected}
                name={name}
                renderOption={(props, option) => (
                    <Box component="li" {...props}>
                        {option.icon ? option.icon : '' }
                        {option.label}
                    </Box>
                  )}
                renderInput={(params) => <TextField {...params} label={label} error={error ? true : false} fullWidth />}
                isOptionEqualToValue={(option, value) =>((value !== undefined) && (option.value === value.value))}
            />
            {(info) && <FormHelperText id={`helper_${name}`} className={classes.info}>{info}</FormHelperText>}
            {(error) && <FormHelperText id={`error_${name}`} error className={classes.info}>{error}</FormHelperText>}
        </FormControl>
    )
}

FormMultiSelect.defaultProps = {
    className: "",
}

FormMultiSelect.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    className: PropTypes.string,
    value: PropTypes.any,
    id: PropTypes.string,
    onChange: PropTypes.func.isRequired,
}

export { FormMultiSelect }
