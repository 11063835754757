import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import { StatisticsWidget } from '../../../../_components/widgets';
import { useDispatch, useSelector } from 'react-redux';
import { InfluencerAction } from '../../../../redux/actions';
import { People } from '@mui/icons-material';

function InfluencerCountWidget() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    /** Redux reducer actions */
    const getInfluencerCount = (params) => dispatch(InfluencerAction.getInfluencerCount(params));

    /** Redux reducer states */
    const { influencer_count } = useSelector((state) => state.InfluencerReducer);

    useEffect(() => {
        getInfluencerCount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Box>
            <StatisticsWidget
                color="dark"
                icon={<People />}
                title="Influencers"
                count={influencer_count.count}
                percentage={{
                    color: `${influencer_count.percent > 0 ? 'success' : 'error'}`,
                    amount: `${influencer_count.percent}%`,
                    label: "than last month",
                }}
            />
        </Box>
    )
}

export { InfluencerCountWidget }