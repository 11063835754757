import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    preSignInWrapper: {
        [theme.breakpoints.up('md')]: {
            height: `100vh`,
        }
    },
    preSignInWrapperItemInner: {
        minWidth: `320px`,
        maxWidth: `680px`,
        minHeight: `240px`,
        padding: theme.spacing(5),
        paddingBottom: theme.spacing(6)
    },
    preSigninFooter: {
        flexBasis: `unset !important`
    }
}))

export { useStyles };
