import { Business, SupportAgent, DynamicFeed, Home, LocalOffer, Notifications, Password, People, Person, RowingOutlined, Search, Message, LocalActivity, CalendarMonth, Schedule, CurrencyRupee } from "@mui/icons-material";
import { Dashboard } from "../app/dashboard";
import { ChangePassword, Profile, ProfileDetails, BillingAddress, ActivityLog, Notifications as NotificationList, UpdateBillingAddress } from "../app/profile";
import { CreateOrUpdatePromo, Promos } from "../app/promos";
import { Stories, StoriesDetail } from "../app/stories";
import { Messages } from "../app/messages";
import { Influencers } from "../app/influencer";
import { InfluencerDetails } from "../app/influencer/influencer-details";
import { Tickets, CreateOrUpdateTicket, TicketDetails } from "../app/tickets";
import { Search as SearchModule } from "../app/search";
import { PromoDetails } from "../app/promos/promo-details/PromoDetails";
import { AddPassword } from "../app/profile/add-password/AddPassword";
import { CreateOrUpdateGiveaway, GiveawayDetails, Giveaways } from "../app/giveaways";
import { GiveawaysCouponCodes } from "../app/giveaways/giveaways-coupon-codes";
import { BrandCalendar } from "../app/brand-calendar/BrandCalendar";
import { CreateOrUpdateSchedulePost, SchedulePost, SchedulePostDetails, SchedulePostSpecifications } from "../app/schedule-post";
import { BrandWallet, WalletStatements } from "../app/brand-wallet";
import { GenerateCouponCodes } from "../app/promos/promos-generate-coupon-codes";

const allRoutes = {

    /** begin::welcome module */
    'welcome': {
        path: "/welcome",
        name: "Welcome Brand",
        component: Dashboard,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Home,
        module: 'welcome',
        isModuleMenuItem: false,
        isMainComponent: false
    },
    /** end::welcome module */

    /** begin::profile module */
    'profile': {
        path: "/profile/:type?",
        name: "Profile",
        component: Profile,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Person,
        module: 'profile',
        isModuleMenu: true,
        isModuleMenuItem: false,
        isMainComponent: true

    },
    'profile_details': {
        path: "/profile/details",
        name: "Profile",
        component: ProfileDetails,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Person,
        module: 'profile',
        isModuleMenuItem: true,
    },
    /** end::profile module */

    /** begin::notifications module */
    'notifications': {
        path: "/profile/notifications",
        name: "Notifications",
        component: NotificationList,
        layout: "/post-auth",
        menuIcon: Notifications,
        isMenuItem: false,
        module: 'profile',
        isModuleMenuItem: true
    },
    /** end::notifications module */

    /** begin::activity log module */
    'activity_log': {
        path: "/profile/activity",
        name: "Activity Log",
        component: ActivityLog,
        layout: "/post-auth",
        menuIcon: RowingOutlined,
        isMenuItem: false,
        module: 'profile',
        isModuleMenuItem: true
    },
    /** end::activity log module */

    /** begin::change-password module */
    'change_password': {
        path: "/profile/change-password",
        name: "Change Password",
        component: ChangePassword,
        layout: "/post-auth",
        menuIcon: Password,
        isMenuItem: false,
        module: 'profile',
        isModuleMenuItem: true
    },
    /** end::change-password module */

    /** begin::set-password module */
    'set_password': {
        path: "/profile/set-password",
        name: "Set Password",
        component: AddPassword,
        layout: "/post-auth",
        menuIcon: Password,
        isMenuItem: false,
        module: 'profile',
        isModuleMenuItem: true
    },
    /** end::set-password module */

    /** begin::billing address module */
    'billing_address': {
        path: "/profile/billing-address",
        name: "Billing Address",
        component: BillingAddress,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Business,
        module: 'profile',
        isModuleMenuItem: true,
    },
    'update_billing_address': {
        path: "/profile/billing-address/update",
        name: "Update Billing Address",
        component: UpdateBillingAddress,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Business,
        module: 'profile',
        isModuleMenuItem: false,
        isMainComponent: true
    },
    /** end::billing address module */

    /** begin::account status module */
    // 'account_status': {
    //     path: "/profile/status",
    //     name: "Account Status",
    //     component: AccountStatus,
    //     layout: "/post-auth",
    //     menuIcon: Warning,
    //     isMenuItem: false,
    //     module: 'profile',
    //     isModuleMenuItem: true
    // },
    /** end::account status module */

    /** begin::home module */
    'home': {
        path: "/",
        name: "Dashboard",
        component: Dashboard,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: Home,
        module: 'dashboard',
        isModuleMenuItem: true,
        isMainComponent: true
    },
    /** end::home module */

    /** begin::promos module */
    'promos': {
        path: "/promos",
        name: "Promos",
        component: Promos,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: LocalOffer,
        module: 'promos',
        isModuleMenuItem: true,
        isMainComponent: true,
        hasSeparation: true
    },
    'create_promos': {
        path: "/promos/create",
        name: "Create Promo",
        component: CreateOrUpdatePromo,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: LocalOffer,
        module: 'promos',
        isModuleMenuItem: true,
        isMainComponent: true
    },
    'update_promos': {
        path: "/promos/update",
        name: "Update Promo",
        component: CreateOrUpdatePromo,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: LocalOffer,
        module: 'promos',
        isModuleMenuItem: true,
        isMainComponent: true
    },
    'promo_details': {
        path: "/promos/details",
        name: "Promo Details",
        component: PromoDetails,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: LocalOffer,
        module: 'promos',
        isModuleMenuItem: true,
        isMainComponent: true
    },
    'generate_coupons': {
        path: "/promos/coupons/generate",
        name: "Generate Coupon Codes",
        component: GenerateCouponCodes,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: LocalOffer,
        module: 'promos',
        isModuleMenuItem: true,
        isMainComponent: true
    },
    /** end::promos module */

    /** begin::giveaways module */
    'giveaways': {
        path: "/giveaways",
        name: "Giveaways",
        component: Giveaways,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: LocalActivity,
        module: 'giveaways',
        isModuleMenuItem: true,
        isMainComponent: true,
    },
    'create_giveaways': {
        path: "/giveaways/create",
        name: "Create Giveaway",
        component: CreateOrUpdateGiveaway,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: LocalActivity,
        module: 'giveaways',
        isModuleMenuItem: true,
        isMainComponent: true
    },
    'update_giveaways': {
        path: "/giveaways/update",
        name: "Update Giveaway",
        component: CreateOrUpdateGiveaway,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: LocalActivity,
        module: 'giveaways',
        isModuleMenuItem: true,
        isMainComponent: true
    },
    'giveaway_details': {
        path: "/giveaways/details",
        name: "Giveaway Details",
        component: GiveawayDetails,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: LocalActivity,
        module: 'giveaways',
        isModuleMenuItem: true,
        isMainComponent: true
    },
    'giveaways_coupon_codes': {
        path: "/giveaways/codes",
        name: "Giveaway Coupon Codes",
        component: GiveawaysCouponCodes,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: LocalActivity,
        module: 'giveaways',
        isModuleMenuItem: false,
        isMainComponent: true
    },
    /** end::giveaways module */

    /** begin::stories module */
    'stories': {
        path: "/stories",
        name: "Stories",
        component: Stories,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: DynamicFeed,
        module: 'stories',
        isModuleMenuItem: true,
        isMainComponent: true
    },
    'stories_detail': {
        path: "/stories/detail",
        name: "Stories Detail",
        component: StoriesDetail,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: DynamicFeed,
        module: 'stories',
        isModuleMenuItem: false,
        isMainComponent: true
    },
    /** end::stories module */

    /** begin::influencers module */
    'influencer': {
        path: "/influencers",
        name: "Influencers",
        component: Influencers,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: People,
        module: 'influencer',
        isModuleMenuItem: true,
        isMainComponent: true
    },
    'influencer_details': {
        path: "/influencers/details",
        name: "Influencer Details",
        component: InfluencerDetails,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: People,
        module: 'influencer',
        isModuleMenuItem: true,
        isMainComponent: true,
        hasSeparation: true
    },
    /** end::influencers module */

    /** begin::schedule post module */
    'schedule_post': {
        path: "/schedule-post",
        name: "Schedule Post",
        component: SchedulePost,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: Schedule,
        module: 'schedule_post',
        isModuleMenuItem: true,
        isMainComponent: true,
        hasSeparation: true
    },
    'schedule_post_details': {
        path: "/schedule-post/details",
        name: "Schedule Post Details",
        component: SchedulePostDetails,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Schedule,
        module: 'schedule_post',
        isModuleMenuItem: true,
        isMainComponent: true
    },
    'create_schedule_post': {
        path: "/schedule-post/create",
        name: "Create Post",
        component: CreateOrUpdateSchedulePost,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Schedule,
        module: 'schedule_post',
        isModuleMenuItem: true,
        isMainComponent: true
    },
    'update_schedule_post': {
        path: "/schedule-post/update",
        name: "Update Post",
        component: CreateOrUpdateSchedulePost,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Schedule,
        module: 'schedule_post',
        isModuleMenuItem: true,
        isMainComponent: true
    },
    'schedule_post_specifications': {
        path: "/schedule-post/specifications",
        name: "Media Specifications",
        component: SchedulePostSpecifications,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: Schedule,
        module: 'schedule_post',
        isModuleMenuItem: true,
        isMainComponent: true
    },
    /** end::schedule post module */

    /** begin::insights module */
    // 'insights': {
    //     path: "/insights",
    //     name: "Insights",
    //     component: IgInsights,
    //     layout: "/post-auth",
    //     isMenuItem: true,
    //     menuIcon: Insights,
    //     module: 'insights',
    //     isModuleMenuItem: true,
    //     isMainComponent: true,
    //     hasSeparation: true
    // },
    // 'insights_charts': {
    //     path: "/insights/chart",
    //     name: "Insights",
    //     component: InsightsCharts,
    //     layout: "/post-auth",
    //     isMenuItem: false,
    //     menuIcon: Insights,
    //     module: 'insights',
    //     isModuleMenuItem: true,
    //     isMainComponent: true,
    //     hasSeparation: true
    // },
    /** begin::insights module */

    /** begin::messages module */
    'messages': {
        path: "/messages",
        name: "Messages",
        component: Messages,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: Message,
        module: 'messages',
        isModuleMenuItem: true,
        isMainComponent: true
    },
    /** begin::insights module */

    /** begin::calendar module */
    'calendar': {
        path: "/calendar",
        name: "Calendar",
        component: BrandCalendar,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: CalendarMonth,
        module: 'calendar',
        isModuleMenuItem: true,
        isMainComponent: true
    },
    /** begin::insights module */

    /** begin::plans module */
    // 'plans': {
    //     path: "/plans",
    //     name: "Plans",
    //     component: Plans,
    //     layout: "/post-auth",
    //     isMenuItem: true,
    //     menuIcon: Payments,
    //     module: 'plans',
    //     isModuleMenuItem: true,
    //     isMainComponent: true,
    //     hasSeparation: true
    // },
    // 'payment_preferences': {
    //     path: "/plans/payment-preferences",
    //     name: "Payment Preferences",
    //     component: PaymentMethod,
    //     layout: "/post-auth",
    //     isMenuItem: false,
    //     menuIcon: Payments,
    //     module: 'plans',
    //     isModuleMenuItem: false,
    //     isMainComponent: true
    // },
    // /** end::plans module */

    // /** begin::invoice module */
    // 'invoice': {
    //     path: "/invoice",
    //     name: "Invoice",
    //     component: Invoice,
    //     layout: "/post-auth",
    //     isMenuItem: true,
    //     menuIcon: CreditScore,
    //     module: 'invoice',
    //     isModuleMenuItem: true,
    //     isMainComponent: true,
    // },

    // 'invoice_details': {
    //     path: "/invoice/details",
    //     name: "Invoice Details",
    //     component: InvoiceDetails,
    //     layout: "/post-auth",
    //     isMenuItem: false,
    //     menuIcon: CreditScore,
    //     module: 'invoice',
    //     isModuleMenuItem: true,
    //     isMainComponent: true,
    //     hasSeparation: true
    // },
    /** end::invoice module */

    /** begin::search coupon module */
    'search': {
        path: "/search",
        name: "Search",
        component: SearchModule,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: Search,
        module: 'search',
        isModuleMenuItem: true,
        isMainComponent: true,
        hasSeparation: true
    },
    /** end::search coupon module */

    /** begin::tickets module */
    'tickets': {
        path: "/tickets",
        name: "Support Center",
        component: Tickets,
        layout: "/post-auth",
        isMenuItem: true,
        menuIcon: SupportAgent,
        module: 'tickets',
        isModuleMenuItem: true,
        isMainComponent: true
    },
    'create_ticket': {
        path: "/tickets/create",
        name: "Create Ticket",
        component: CreateOrUpdateTicket,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: SupportAgent,
        module: 'tickets',
        isModuleMenuItem: true,
        isMainComponent: true
    },
    'update_ticket': {
        path: "/tickets/update",
        name: "Update Ticket",
        component: CreateOrUpdateTicket,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: SupportAgent,
        module: 'tickets',
        isModuleMenuItem: true,
        isMainComponent: true
    },
    'ticket_details': {
        path: "/tickets/detail",
        name: "Ticket Detail",
        component: TicketDetails,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: SupportAgent,
        module: 'tickets',
        isModuleMenuItem: false,
        isMainComponent: true
    },
    /** end::tickets module */

    /** begin::wallet module */
    'wallet': {
        path: "/wallet",
        name: "Wallet",
        component: BrandWallet,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: CurrencyRupee,
        module: 'wallet',
        isModuleMenu: true,
        isModuleMenuItem: false,
        isMainComponent: true
    },
    'wallet_statements': {
        path: "/wallet/statements",
        name: "Wallet Statements",
        component: WalletStatements,
        layout: "/post-auth",
        isMenuItem: false,
        menuIcon: CurrencyRupee,
        module: 'wallet',
        isModuleMenuItem: true,
        isMainComponent: true
    },
    /** end::wallet module */

};

function PostAuthRoutes(keyType = null) {
    if (keyType) {
        return allRoutes[keyType];
    }
}

function PostAuthMenuRoutes() {
    return Object.values(allRoutes).filter(function (item) {
        return item.isMenuItem === true;
    });
}

function PostAuthMainComponents() {
    return Object.values(allRoutes).filter(function (item) {
        return item.isMainComponent === true ? (
            { ...item, path: (item.isModuleMenu) ? (item.path.replace(':type?', '/*')) : (item.path) }
        ) : null;
    });
}

function PostAuthModuleMenuRoutes(module) {
    return Object.values(allRoutes).filter(function (item) {
        return (item.isModuleMenuItem === true && item.module === module) ? ({
            ...item, path: item.path.split("/").pop()
        }) : null;
    });
}

function getPostComponentNameByPath(path) {
    if (path) {
        let componentItem = Object.values(allRoutes).filter(function (item) {
            return item.path === path;
        });
        return componentItem.length > 0 ? componentItem[0]['name'] : '';
    }
}


export { PostAuthRoutes, PostAuthMenuRoutes, PostAuthModuleMenuRoutes, PostAuthMainComponents, getPostComponentNameByPath };
