import React, { useEffect, useState } from 'react'
import queryString from 'query-string';
import { DataTable } from '../../../_components';
import { useDispatch, useSelector } from 'react-redux';
import { TicketAction } from '../../../redux/actions';
import { Button, ButtonGroup, Chip } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { PostAuthRoutes } from '../../../routes';
import { Edit, RemoveRedEyeOutlined } from '@mui/icons-material';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle'

function TicketsDataTable({ statusUpdateModal, filter, search, limit, page, ...props }) {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();

    /** Redux reducer state */
    const { per_page, total, tickets_loading: isLoading, tickets } = useSelector((state) => state.TicketReducer);

    /** Redux reducer actions */
    const listTickets = (params) => dispatch(TicketAction.listTickets(params));

    /** Initialize and declare state */
    const [sort, setSort] = useState({ '_id': 'asc' });
    const [payload, setPayload] = useState({ filter, search, page, limit });
    // const [setStatusData] = useState({ id: '', status: '' })

    useEffect(() => {
        setPayload({ filter, search, page, limit, sort });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, search, page, limit])


    useEffect(() => {

        const queryParam = { ...filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });

        queryParam.p = page;
        if (search) queryParam.q = search; else delete queryParam.q;
        if (filter.status) queryParam.status = filter.status; else delete queryParam.status;
        history.push({
            pathname: PostAuthRoutes('tickets').path,
            search: `?${queryString.stringify(queryParam)}`
        });

        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.filter, payload.search])

    /**
     * function to fetch ticket list
     * @return json
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const getData = async () => {
        await listTickets(payload);
    }

    /** Initialize column headers and values */
    const columns = [
        { name: '#', selector: (row, i) => (row._id), sortField: '_id', center: true, width: '5%', cell: (row, i) => { return ((per_page * (payload.page - 1)) + (i + 1)) } },
        { name: 'Subject', selector: (row, i) => (row.subject), sortField: 'subject', sortable: true, width: '28%' },
        { name: 'Description', selector: (row, i) => (row.description), sortField: 'description', center: false, sortable: true, width: '47%', wrap: true, cell: row => (<DescriptionRow row={row} />) },
        { name: 'Created Date', selector: (row, i) => (row.created_at), sortField: 'created_at', center: true, sortable: true, width: '12%' },
        { name: 'Status', selector: (row, i) => (row.status), sortField: 'status', center: true, sortable: true, width: '8%', cell: row => (<StatusRow row={row} />) },
    ];

    /**
     * function to display status value and update options on rows
     * @param {Object} row
     * @param {string} row.status - status values on the row
     * @return json
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const StatusRow = ({ row }) => {
        let status = <Chip label={row.status} className="badge-status capitalize" />;
        if (row.status === 'open')
            status = <Chip size='small' label={row.status} color="warning" className="badge-status capitalize" />;
        else if (row.status === 'closed')
            status = <Chip size='small' label={row.status} color="error" className="badge-status capitalize" />;
        else if (row.status === 'hold')
            status = <Chip size='small' label={row.status} color="primary" className="badge-status capitalize" />;
        else if (row.status === 'resolved')
            status = <Chip size='small' label={row.status} color="success" className="badge-status capitalize" />;
        else if (row.status === 're-open')
            status = <Chip size='small' label={row.status} color="success" className="badge-status capitalize" />;
        let statusHover = (
            <ButtonGroup variant="text" size="small" className='row-action'>
                {row.status === 'open' ? <Button onClick={() => handleUpdateClick(row)}> <Edit /></Button> : ''}
                <Button onClick={() => ticketDetails(row._id)}> <RemoveRedEyeOutlined /></Button>
                {row.status === 'open' || row.status === 're-open' || row.status === 'closed' ?
                    <Button onClick={() => handleStatusChange(row)}> <ChangeCircleIcon /></Button> : ''
                }
            </ButtonGroup>
        )
        return <React.Fragment>{status}{statusHover}</React.Fragment>;

    }

    /**
     * function to direct to ticket display page 
     * @return json
     * @author
     * @created_at 30 May 2022
     */
    const ticketDetails = (id) => {
        history.push({
            pathname: PostAuthRoutes('ticket_details').path,
            search: `?T_Id=${id}`
        });
    }

    /**
     * function to change ticket status
     * @return view
     * @author
     * @created_at 30 May 2022
     */
    const handleStatusChange = (row) => {
        // setStatusData({ id: row._id, status: row.status, remark: ''  })
        statusUpdateModal({ id: row._id, status: row.status, remark: '' });
    }

    /**
     * function to display ticket description on rows
     * @param {Object} row
     * @param {string} row.description - description on the row
     * @return json
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const DescriptionRow = ({ row }) => {
        return <p>{row.description.length > 150 ? row.description.substring(0, 150) + "..." : row.description}</p>;
    }

    /**
     * function to update ticket on the rows
     * @param {Object} row
     * @return json
     * @author Akshay N
     * @created_at 30 May 2022
     */
    function handleUpdateClick(row) {
        history.push({
            pathname: PostAuthRoutes('update_ticket').path,
            state: { ticket: row }
        });
    }

    /**
     * function to handle page
     * @param {String} page - Page number to load 
     * @return view
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const handlePage = (page) => {
        setPayload({ ...payload, page: page });
    }

    /**
     * function to sort columns
     * @return view
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const handleSort = (column, sortDirection) => {
        const sortField = column.sortField;
        setSort({ [sortField]: sortDirection })
    }

    return (
        <DataTable
            loading={isLoading}
            columns={columns}
            data={tickets}
            total={total}
            per_page={per_page}
            setPage={page => handlePage(page)}
            handleSort={handleSort}
            paginationDefaultPage={0}
            noDataComponentData={{ content1: 'Tickets', content2: 'Not Found' }}
        />
    )

}
export { TicketsDataTable };