import { Check, Delete } from "@mui/icons-material";
import { Box, Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddUpdateBankDetails } from ".";
import { PaymentAction } from "../../../../redux/actions";
import { FormInput, Modal, ModalBody, ModalFooter, ModalHeader } from "../../../../_components";
import { handleInputChange, validateForm } from "../../../../_helpers";


const useStyles = makeStyles((theme) => ({
    paymentPreferenceBox: {
        padding: theme.spacing(2)
    },
    table: {
        marginBottom: theme.spacing(2),
    },
    tableHeader: {
        backgroundColor: `#b3e5fc`,
        display: 'table-header-group !important'
    },
    verifyBankButton: {
        marginRight: `${theme.spacing(1)} !important`
    },
    bankVerificationGrid: {
        paddingBottom: theme.spacing(2)
    },
    tableTextColor: {
        color: `initial !important`,
    }
}));

const inputs = { first_deposit: '', second_deposit: '' };

function BankDetails() {


    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const classes = useStyles();

    const { payment_details_loading: loading, payment_details } = useSelector((state) => state.PaymentReducer);

    const deleteBankDetails = () => dispatch(PaymentAction.deleteBankDetails());
    const verifyPaymentDetails = (params) => dispatch(PaymentAction.verifyPaymentDetails(params));
    const getPaymentDetails = () => dispatch(PaymentAction.getPaymentDetails());
    const setDefaultPayment = (params) => dispatch(PaymentAction.setDefaultPaymentMethod(params));

    const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false)
    const [showVerifyBankModal, setShowVerifyBankModal] = useState(false)
    const [showLoader, setShowLoader] = useState(false)

    const [defaultPaymentModal, setDefaultPaymentModal] = useState(false)

    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, terms_and_condition: '' });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });


    const deleteBank = (e) => {
        e.preventDefault();
        setShowDeleteWarningModal(true);
    }

    const onClose = () => {
        setShowDeleteWarningModal(false);
        setShowVerifyBankModal(false);
        setDefaultPaymentModal(false);
        getPaymentDetails();
    }

    const deleteBankDetailsAction = () => {
        deleteBankDetails().then(() => {
            getPaymentDetails();
            setShowDeleteWarningModal(false);
        })
    }

    const verifyBank = (e) => {
        e.preventDefault();
        setShowVerifyBankModal(true);
    }

    const handleChange = (e) => {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validateVerification(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    const handleVerifySubmit = (e) => {
        e.preventDefault();
        setAction({ ...action, isSubmitted: true, isProcessing: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validateVerification(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ ...action, isSubmitted: true, isProcessing: false });
            return;
        }
        dispatchBankVerificationAction();
    }

    const dispatchBankVerificationAction = (e) => {

        const { first_deposit, second_deposit } = data;
        const paymentData = {
            first_deposit: first_deposit * 100,
            second_deposit: second_deposit * 100,
        }

        verifyPaymentDetails(paymentData)
            .then((response) => {
                if (response.status === 1) {
                    setAction({ ...action, isSubmitted: false, isProcessing: false });
                    setErrors({ ...response.errors })
                    setData({ ...inputs });
                    setShowVerifyBankModal(false);
                    getPaymentDetails();
                    if (!response.data.default_payment) {
                        setDefaultPaymentModal(true);
                    }
                } else {
                    setErrors({ ...response.errors })
                    setAction({ ...action, isSubmitted: true, isProcessing: false });
                }
            });
    }

    const validateVerification = (name, value, data) => {
        //eslint-disable-next-line
        const errors = {};
        switch (name) {
            case 'first_deposit': {
                errors.first_deposit = '';
                if (value === '')
                    errors.first_deposit = 'First deposit is required. ';
                break;
            } case 'second_deposit': {
                errors.second_deposit = '';
                if (value === '')
                    errors.second_deposit = 'Second deposit is required. ';
                break;
            } default:
                errors[name] = '';
                break;
        }
        return errors;
    }

    const setDefaultPaymentMethod = (e) => {
        setShowLoader(true);
        setDefaultPayment({ method: 'bank' }).then(() => {
            getPaymentDetails();
            setDefaultPaymentModal(false);
            setShowLoader(false);
        })
    }

    return (
        <Box className={classes.paymentPreferenceBox}>

            {/* BEGIN: Confirmation modal for setting default payment method */}
            <Modal isDialogOpen={defaultPaymentModal} onClose={onClose} loader={showLoader} size="md">
                <ModalHeader id="AddUpdateDeals" title="Default Payment Method" onClose={onClose} />
                <ModalBody className="text-center">
                    <h1>New Bank Added</h1>
                    <p>{`Do you want to change, bank as your default payment method?`}</p>
                </ModalBody>
                <ModalFooter>
                    <Button variant="contained" color="primary" size="small" className={classes.button} onClick={setDefaultPaymentMethod}>Confirm</Button>
                    <Button variant="contained" color="secondary" size="small" className={classes.button} onClick={onClose}>Close</Button>
                </ModalFooter>
            </Modal>
            {/* END: Confirmation modal for setting default payment method */}

            <Modal isDialogOpen={showDeleteWarningModal} onClose={onClose} loader={showLoader} size="md">
                <ModalHeader id="AddUpdateDeals" title="Remove Bank Details" onClose={onClose} />
                <ModalBody className="text-center">
                    <h1>Are You Sure?</h1>
                    <p>{`Do you want to remove this bank account?`}</p>
                </ModalBody>
                <ModalFooter>
                    <Button variant="contained" color="primary" size="small" className={classes.button} onClick={deleteBankDetailsAction}>Confirm</Button>
                    <Button variant="contained" color="secondary" size="small" className={classes.button} onClick={onClose}>Close</Button>
                </ModalFooter>
            </Modal>

            <Modal isDialogOpen={showVerifyBankModal} onClose={onClose} loader={showLoader} size="md">
                <ModalHeader id="AddUpdateDeals" title="Verify Bank Details" onClose={onClose} />
                <form onSubmit={handleVerifySubmit} noValidate >
                    <ModalBody>
                        <Grid container spacing={2} className={classes.bankVerificationGrid}>
                            <Grid item xs={12}>
                                <Box className="col-md-12">
                                    <Typography variant="caption"  >To verify your account, enter the two micro deposits below.If you don't see them, they should arrive in 1-3 business days.</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <FormInput
                                    label="First Deposit (amount as seen on your online bank statement, e.g. 0.12 or 0.25 or .34)"
                                    placeholder="Enter first deposit"
                                    name="first_deposit"
                                    tabIndex="1"
                                    value={data.first_deposit}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.first_deposit)) ? (errors.first_deposit) : ''}
                                    className="col-md-12"
                                    maxLength={4}
                                    maskType={'digit-with-one-dot'}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormInput
                                    label="Second Deposit (amount as seen on your online bank statement, e.g. 0.12 or 0.25 or .34)"
                                    placeholder="Enter second deposit"
                                    name="second_deposit"
                                    tabIndex="2"
                                    value={data.second_deposit}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.second_deposit)) ? (errors.second_deposit) : ''}
                                    className="col-md-12"
                                    maxLength={4}
                                    maskType={'digit-with-one-dot'}
                                />
                            </Grid>
                        </Grid>
                    </ModalBody>
                    <ModalFooter>
                        <Button type="submit" variant="contained" color="primary" size="small" className={classes.button} >Verify</Button>
                        <Button variant="contained" color="secondary" size="small" className={classes.button} onClick={onClose}>Close</Button>
                    </ModalFooter>
                </form>
            </Modal>

            <TableContainer >
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead className={classes.tableHeader}>
                        <TableRow>
                            <TableCell className={classes.tableTextColor}>Acccount</TableCell>
                            <TableCell className={classes.tableTextColor}>Name on Account</TableCell>
                            <TableCell className={classes.tableTextColor}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {!loading && !payment_details.stripe_bank_response && (
                            <TableRow>
                                <TableCell className={classes.tableTextColor} colSpan={3}>Bank details not added</TableCell>
                            </TableRow>
                        )}

                        {!loading && payment_details.stripe_bank_response && (
                            <TableRow >
                                <TableCell className={classes.tableTextColor}>
                                    {payment_details.stripe_bank_response.status === 'new' && <img src={`${process.env.PUBLIC_URL}/img/vectors/bank.svg`} style={{ height: `25px`, marginRight: `12px` }} alt="bank" />}
                                    {payment_details.stripe_bank_response.status !== 'new' && <img src={`${process.env.PUBLIC_URL}/img/vectors/bank-with-check.svg`} style={{ height: `25px`, marginRight: `12px` }} alt="bank without check" />}
                                    {payment_details.stripe_bank_response.bank_name} account ending in {payment_details.stripe_bank_response.last4}</TableCell>
                                <TableCell className={classes.tableTextColor}>{payment_details.stripe_bank_response.account_holder_name}</TableCell>
                                <TableCell className={classes.tableTextColor}>
                                    {payment_details.stripe_bank_response.status === 'new' && (<Button variant="contained" color="primary" size="small" className={classes.verifyBankButton} startIcon={<Check />} onClick={verifyBank}>Verify</Button>)}
                                    <Button variant="contained" color="secondary" size="small" className={classes.button} startIcon={<Delete />} onClick={deleteBank}>Delete</Button>
                                </TableCell>
                            </TableRow>
                        )}

                    </TableBody>
                </Table>
            </TableContainer>
            {!loading && !payment_details.stripe_bank_response && <AddUpdateBankDetails />}
        </Box>
    )
}

export { BankDetails }
