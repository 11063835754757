import {
    GET_BRAND_CALENDAR_REQUEST, GET_BRAND_CALENDAR_SUCCESS, GET_BRAND_CALENDAR_FAILURE,    
} from "../actions";

const initialState = {
    brand_calendar_loading: false, brand_calendar: {}, brand_calendar_error: {}
};

export function BrandCalendarReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::Brand calendar redux */
        case GET_BRAND_CALENDAR_REQUEST: {
            return {
                ...state,
                brand_calendar_loading: true,
                brand_calendar_error: {}
            };
        } case GET_BRAND_CALENDAR_SUCCESS: {

            return {
                ...state,
                brand_calendar_loading: false,
                brand_calendar: action.data,
                brand_calendar_error: {}
            };
        } case GET_BRAND_CALENDAR_FAILURE: {
            return {
                ...state,
                brand_calendar_loading: false,
                brand_calendar_error: action.error
            };
        }
        /** end::Brand calendar redux */

        default:
            return state;
    }
}