
module.exports = {
    /**
     * function to validate the promo create or update form inputs
     * @param {string} name Input name
     * @param {string} value Input value
     * @param {object} [data={}] All input values stored in the state
     * @author Akshay N
     * @created_at 28 May 2022
     */
    validate: (name, value, data = {}) => {
        const errors = {};
        switch (name) {

            case 'number': {
                errors.number = value.length < 15 ? 'Credit card number must be 15 or 16 characters long.' : '';
                if (value === '')
                    errors.number = 'Enter credit card number. ';
                break;
            } case 'card_holder': {
                errors.card_holder = '';
                if (value === '')
                    errors.card_holder = 'Enter credit holder name. ';
                break;
            } case 'exp_month': {
                errors.exp_month = '';
                if (value === '')
                    errors.exp_month = 'Enter expiration month. ';
                break;
            } case 'exp_year': {
                errors.exp_year = '';
                if (value === '')
                    errors.exp_year = 'Enter expiration year. ';
                break;
            } case 'cvc': {
                errors.cvc = value.length < 3 ? 'CVC must be 3 characters long.' : '';
                if (value === '')
                    errors.cvc = 'Enter CVC. ';
                break;

            } case 'name': {
                errors.name = '';
                if (value === '')
                    errors.name = 'Enter full name. ';
                break;
            } case 'address': {
                errors.address = '';
                if (value === '')
                    errors.address = 'Enter address. ';
                break;
            } case 'city': {
                errors.city = '';
                if (value === '')
                    errors.city = 'Enter city. ';
                break;
            } case 'state': {
                errors.state = '';
                if (value === '')
                    errors.state = 'Enter state. ';
                break;
            } case 'pincode': {
                errors.pincode = '';
                if (value === '')
                    errors.pincode = 'Enter pincode. ';
                break;
            } case 'country': {
                errors.country = '';
                if (value === '')
                    errors.country = 'Enter country. ';
                break;
            } case 'mobile_number': {
                errors.mobile_number = '';
                if (value === 'undefined') {
                    errors.mobile_number = 'Mobile number invalid. '
                }
                if (value === '')
                    errors.mobile_number = 'Mobile number is required. ';
                break;
            } default:
                errors[name] = '';
                break;
        }
        return errors;
    }
}