import {
    USER_REQUEST, USER_SUCCESS, USER_FAILURE,
    CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILURE,
    ADD_PASSWORD_REQUEST, ADD_PASSWORD_SUCCESS, ADD_PASSWORD_FAILURE,
    UPDATE_BILLING_ADDRESS_REQUEST, UPDATE_BILLING_ADDRESS_SUCCESS, UPDATE_BILLING_ADDRESS_FAILURE,
    GET_BILLING_ADDRESS_REQUEST, GET_BILLING_ADDRESS_SUCCESS, GET_BILLING_ADDRESS_FAILURE,
    GET_ACTIVITY_LOG_REQUEST, GET_ACTIVITY_LOG_SUCCESS, GET_ACTIVITY_LOG_FAILURE,
    USER_UPDATE_REQUEST, USER_UPDATE_SUCCESS, USER_UPDATE_FAILURE,
    CHECK_BRAND_ACCESS_TOKEN_REQUEST, CHECK_BRAND_ACCESS_TOKEN_SUCCESS, CHECK_BRAND_ACCESS_TOKEN_FAILURE,

} from "../actions";

const initialState = {
    user_loading: true, user: {}, user_errors: [],
    change_password_loading: false, change_password: {}, change_password_errors: [],
    add_password_loading: false, add_password: {}, add_password_errors: [],
    billing_address_loading: true, billing_address: {}, billing_address_errors: {},
    update_billing_address_loading: false, update_billing_address_errors: {},
    activity_logs_loading: false, activity_logs: [], activity_logs_errors: {},
    check_brand_access_token_loading: false, check_brand_access: '', check_brand_access_token_errors: {}
};

export function UserReducer(state = initialState, action) {
    switch (action.type) {

        /** begin::User details redux */
        case USER_REQUEST: {
            return {
                ...state,
                user_loading: true,
                user_errors: {}
            };
        }
        case USER_SUCCESS: {
            return {
                ...state,
                user_loading: false,
                user: action.data,
                user_errors: {}
            };
        }
        case USER_FAILURE: {
            return {
                ...state,
                user_loading: false,
                user_errors: action.errors
            };
        }
        /** end::User details redux */

        /** begin::Change password redux */
        case CHANGE_PASSWORD_REQUEST: {
            return {
                ...state,
                change_password_loading: true,
                change_password_errors: {}
            };
        }
        case CHANGE_PASSWORD_SUCCESS: {
            return {
                ...state,
                change_password_loading: false,
                change_password: action.data,
                change_password_errors: {}
            };
        }
        case CHANGE_PASSWORD_FAILURE: {
            return {
                ...state,
                change_password_loading: false,
                change_password_errors: action.errors
            };
        }
        /** end::Change passwor redux */

        /** begin::Add password redux */
        case ADD_PASSWORD_REQUEST: {
            return {
                ...state,
                add_password_loading: true,
                add_password_errors: {}
            };
        }
        case ADD_PASSWORD_SUCCESS: {
            return {
                ...state,
                add_password_loading: false,
                add_password: action.data,
                add_password_errors: {}
            };
        }
        case ADD_PASSWORD_FAILURE: {
            return {
                ...state,
                add_password_loading: false,
                add_password_errors: action.errors
            };
        }
        /** end::Add passwor redux */


        /** begin::Add billing address redux */
        case UPDATE_BILLING_ADDRESS_REQUEST: {
            return {
                ...state,
                update_billing_address_loading: true,
                update_billing_address_errors: {}
            };
        }
        case UPDATE_BILLING_ADDRESS_SUCCESS: {
            let newArray = state.user; //making a new array
            newArray.billing_address = action.data;
            return {
                ...state,
                update_billing_address_loading: false,
                billing_address: action.data,
                update_billing_address_errors: {},
                user: newArray
            };
        }
        case UPDATE_BILLING_ADDRESS_FAILURE: {
            return {
                ...state,
                update_billing_address_loading: false,
                update_billing_address_errors: action.errors
            };
        }
        /** end::Add billing address redux */

        /** begin::Get billing address redux */
        case GET_BILLING_ADDRESS_REQUEST: {
            return {
                ...state,
                billing_address_loading: true,
                billing_address_errors: {}
            };
        }
        case GET_BILLING_ADDRESS_SUCCESS: {
            return {
                ...state,
                billing_address_loading: false,
                billing_address: action.data,
                billing_address_errors: {}
            };
        }
        case GET_BILLING_ADDRESS_FAILURE: {
            return {
                ...state,
                billing_address_loading: false,
                billing_address_errors: action.errors
            };
        }
        /** end::Get billing address redux */

        /** begin::List login activity redux */
        case GET_ACTIVITY_LOG_REQUEST: {
            return {
                ...state,
                activity_logs_loading: true,
                activity_logs_errors: {}
            };
        }
        case GET_ACTIVITY_LOG_SUCCESS: {

            let newArray = [...state.activity_logs]; //making a new array
            if (action.data.page === 1) {
                newArray = []
            }
            action.data.activities.forEach((payload) => {
                const loginActivityIndex = newArray.findIndex(activityLog => activityLog._id === payload._id);
                if (loginActivityIndex !== -1) {
                    newArray[loginActivityIndex] = payload;
                } else {
                    newArray = newArray.concat(payload);
                }
            });

            return {
                ...state,
                activity_logs_loading: false,
                activity_logs_errors: {},
                activity_logs: newArray
            };

        }
        case GET_ACTIVITY_LOG_FAILURE: {
            return {
                ...state,
                activity_logs_loading: false,
                activity_logs_errors: action.errors,
                activity_logs: []
            };
        }

        /** end::List login activity redux */

        
        /** begin::User details redux */
        case USER_UPDATE_REQUEST: {
            return {
                ...state,
                user_loading: true,
                user_errors: {}
            };
        }
        case USER_UPDATE_SUCCESS: {
            return {
                ...state,
                user_loading: false,
                user: action.data,
                user_errors: {}
            };
        }
        case USER_UPDATE_FAILURE: {
            return {
                ...state,
                user_loading: false,
                user_errors: action.errors
            };
        }
        /** end::User details redux */

        /** begin::User details redux */
        case CHECK_BRAND_ACCESS_TOKEN_REQUEST: {
            return {
                ...state,
                check_brand_access_token_loading: true,
                check_brand_access_token_errors: {},
            };
        }
        case CHECK_BRAND_ACCESS_TOKEN_SUCCESS: {
            return {
                ...state,
                check_brand_access_token_loading: false,
                check_brand_access: action.data.valid,
                check_brand_access_token_errors: {}
            };
        }
        case CHECK_BRAND_ACCESS_TOKEN_FAILURE: {
            return {
                ...state,
                check_brand_access_token_loading: false,
                check_brand_access_token_errors: action.errors,
                check_brand_access: 'invalid'
            };
        }
        /** end::User details redux */
        default:
            return state;
    }
}
