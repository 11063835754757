import React from 'react'
import { useLocation } from 'react-router';
import { Link, Route, Switch } from 'react-router-dom'

import { Grid, IconButton, Menu } from '@mui/material'
import { MenuList, MenuItem, ListItemIcon, ListItemText } from '@mui/material'
import { Menu as MenuIcon } from '@mui/icons-material';
import { Box } from '../../_components';

import { PostAuthModuleMenuRoutes } from '../../routes'
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useState } from 'react';

function Profile() {

    let menuRoutes = Object.values(PostAuthModuleMenuRoutes('profile'));
    const { user_loading: isLoading, user } = useSelector((state) => state.UserReducer);

    const [menuRoutedModified, setMenuRoutedModified] = useState([]);

    useEffect(() => {
        if (!isLoading && Object.keys(user).length > 0) {
            let newArray = [];
            menuRoutes.forEach(route => {
                if (route.path === '/profile/set-password') {
                    if (!user.passSet) {
                        newArray.push(route);
                    }
                } else if (route.path === '/profile/change-password') {
                    if (user.passSet) {
                        newArray.push(route);
                    }
                } else {
                    newArray.push(route);
                }
            })
            setMenuRoutedModified(newArray);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])
    /** Initialize plugins and variables */
    const location = useLocation();


    /** Redux actions and state */
    const [anchorElX, setAnchorElX] = useState(null);

    const openMenuX = Boolean(anchorElX);

    const handleClickX = (event) => {
        setAnchorElX(event.currentTarget);
    };

    const handleCloseX = () => {
        setAnchorElX(null);
    };

    return (
        // <Container>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} container justifyContent="flex-end">
                <Box sx={{ position: `fixed` }}>
                    <MenuList dense sx={({ breakpoints }) => ({ paddingTop: 0, paddingBottom: 0, display: 'none', [breakpoints.up("md")]: { display: 'block' } })}>
                        {menuRoutedModified.map((route, index) => (
                            <MenuItem key={index} component={Link} to={route.path} className={(route.path === location.pathname) ? `active` : ``} sx={{ paddingTop: 1, paddingBottom: 1 }}>
                                <ListItemIcon>
                                    <route.menuIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>{route.name}</ListItemText>
                            </MenuItem>
                        ))}
                    </MenuList>
                </Box>

                <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                    <IconButton onClick={handleClickX} >
                        <MenuIcon />
                    </IconButton>
                    <Menu anchorEl={anchorElX} open={openMenuX} onClose={handleCloseX} onClick={handleCloseX} PaperProps={{ elevation: 0 }} transformOrigin={{ horizontal: 'right', vertical: 'top' }} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }} sx={{ display: { xs: 'block', md: 'none' } }} >
                        {menuRoutedModified.map((route, index) => (
                            <MenuItem key={index} component={Link} to={route.path} className={(route.path === location.pathname) ? `active` : ``} sx={{ paddingTop: 1, paddingBottom: 1 }}>
                                <ListItemIcon>
                                    <route.menuIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>{route.name}</ListItemText>
                            </MenuItem>
                        ))}

                    </Menu>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                <Switch>
                    {menuRoutes.map((route, index) => (
                        <Route key={index} exact path={route.path} component={route.component} />
                    ))}
                </Switch>
            </Grid>
        </Grid>
        // </Container>
    )
}




export { Profile };
