import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { NoData } from "../../../../_components";
import { GiveawayAction } from "../../../../redux/actions";

import { InfluencerCard, InfluencerCardLoader } from "../../../influencer";


function GiveawayEntries() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();

    const params = new URLSearchParams(search);
    const giveawayId = params.get('gId'); // plan promo from url

    /** Redux actions and state */
    const { giveaway_entries, giveaway_entries_loading: isEntriesLoading } = useSelector((state) => state.GiveawayReducer);
    const listGiveawayEntries = (params) => dispatch(GiveawayAction.listGiveawayEntries(params));

    /** Initialize and declare state */
    const [giveawayEntriesPayload, setGiveawayEntriesPayload] = useState({ limit: 100, page: 1 });

    useEffect(() => {

        getNextGiveawayEntries() // Initially load participants list
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * function to list giveaways
     * @param {string} null 
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const getNextGiveawayEntries = async () => {
        let giveawayEntriesResult = await listGiveawayEntries({ giveaway_id: giveawayId, ...giveawayEntriesPayload });
        if (giveawayEntriesResult.status)
            setGiveawayEntriesPayload({ ...giveawayEntriesPayload, page: giveawayEntriesPayload.page + 1 });
    }


    return (
        <React.Fragment>
            <InfiniteScroll dataLength={giveaway_entries.length} next={getNextGiveawayEntries} hasMore={true} >
                <Grid container spacing={2} direction="row" justifyContent="left" alignItems="center" >
                    {
                        giveaway_entries.length > 0 && giveaway_entries.map((giveaway_entry, i) => {
                            return <Grid key={i} item xs={12} sm={12} md={6} lg={6} xl={6}><InfluencerCard influencer={giveaway_entry.influencer} /></Grid>;
                        })
                    }
                    {!isEntriesLoading && giveaway_entries.length === 0 && <NoData content1={`Giveaway Entries`} content2={`Not Found`} description={`If using a custom view, try adjusting the filters,Otherwise, create some data.`} minHeight={`75vh`} />}
                    {(isEntriesLoading) && <InfluencerCardLoader count={1} />}
                </Grid>
            </InfiniteScroll>
        </React.Fragment>
    );
}

export { GiveawayEntries }