import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { PostAuthRoutes } from "../../../routes";
import { APP_NAME, handleInputChange, validateForm } from "../../../_helpers";
import { validate } from ".";
import { CancelButton, SubmitButton } from "../../../_components/controls";
import { FormInput } from "../../../_components/form";
import { Card, CardContent, CardHeader } from "../../../_components/card";
import { Box, Grid, Stack } from "@mui/material";
import { PromoAction } from "../../../redux/actions";
import { CSVLink } from "react-csv";
import { useRef } from "react";
import { useEffect } from "react";

// Initialize form input values to null
const inputs = { prefix: '', count: '', description: '' };


function GenerateCouponCodes() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();
    const csvLink = useRef();

    /** Redux actions */
    const generateCouponCodes = (params) => dispatch(PromoAction.generateCouponCodes(params));

    /** Redux reducer states */
    const { generate_coupon_codes_loading: isLoading, generate_coupon_codes_csv } = useSelector((state) => state.PromoReducer);

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, promo_type: '', payment_method: '', issue_coupons_at: '' });
    const [action, setAction] = useState({ isSubmitted: false });
    const [isExporting, setIsExporting] = useState('pending')

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const handleChange = async (e) => {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle create/update promo form submit action
     * @param {object} e form object
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const handleSubmit = async (e) => {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }

        dispatchCreateOrUpdatePromoAction(data);
    }

    /**
     * function to dispatch create/update promotion api
     * @param {Object} formData - The params which are used for the api
     * @param {string} formData.prefix - Prefix
     * @param {string} formData.count - Coupon code count
     * @param {string} formData.description - description
     * @return view
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const dispatchCreateOrUpdatePromoAction = async (formData) => {
        const isGenerated = await generateCouponCodes(formData);
        if (isGenerated && isGenerated.status === 1) {
            setIsExporting('exporting');
        } else {
            setErrors(isGenerated.errors);
        }
    }

    useEffect(() => {
        if (isExporting) {

            if (isExporting === 'exporting' && generate_coupon_codes_csv && generate_coupon_codes_csv.length > 0) {
                csvLink.current.link.click();
                setIsExporting('exported');
            } else if (isExporting === 'exported') {
                history.push({
                    pathname: PostAuthRoutes('promos').path
                });
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isExporting, generate_coupon_codes_csv])

    /**
     * function to cancel promo create or update
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const cancelPromo = () => {
        history.push({
            pathname: PostAuthRoutes('promos').path
        });
    }

    const csvOptions = {
        headers: false // Exclude headers from the exported file
    };
    return (
        <React.Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${PostAuthRoutes('generate_coupons').name}`}</title>
            </Helmet>
            <Box>
                <CardHeader title={PostAuthRoutes('generate_coupons').name} />
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={12} md={6} lg={6}>
                            <Card >
                                <CardContent >
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                            <FormInput
                                                tabIndex={1}
                                                label='Prefix'
                                                name='prefix'
                                                value={data.prefix}
                                                error={action.isSubmitted && (errors.prefix ? errors.prefix : '')}
                                                onChange={handleChange}
                                                autoFocus={true}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                            <FormInput
                                                tabIndex={2}
                                                label='Coupon codes count'
                                                name='count'
                                                value={data.count}
                                                error={action.isSubmitted && (errors.count ? errors.count : '')}
                                                onChange={handleChange}
                                                maskType={'digit-only'}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormInput
                                                tabIndex={3}
                                                label='Description'
                                                name='description'
                                                value={data.description}
                                                error={action.isSubmitted && (errors.description ? errors.description : '')}
                                                onChange={handleChange}
                                                multiline
                                                maxLength={250}
                                                minRows={2}
                                            />
                                        </Grid>
                                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}  >
                                                <SubmitButton label='Generate' loading={isLoading || (isExporting !== 'pending')} disabled={isLoading || (isExporting !== 'pending')} tabIndex={4} />
                                                <CancelButton onClick={cancelPromo} tabIndex={5} />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </form>
            </Box>

            <Box style={{ display: `none` }}>
                <CSVLink data={generate_coupon_codes_csv} filename="coupon-codes.csv" asyncOnClick={true} options={csvOptions} ref={csvLink}>Generate</CSVLink>
            </Box>
        </React.Fragment>
    )
}

export { GenerateCouponCodes };
