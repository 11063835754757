import React, { useState } from 'react'

import { AccountCircle, Logout } from '@mui/icons-material';
import { Divider, IconButton, ListItemIcon, MenuItem, Menu } from '@mui/material';

import { useStyles } from '.'
import { Link, useHistory } from 'react-router-dom';
import { PostAuthRoutes, PreAuthRoutes } from '../../../../routes';
import { useDispatch } from 'react-redux';
import { SignInAction } from '../../../../redux/actions';

function AccountMenu() {

    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    /** Redux actions and state */
    const [anchorEl, setAnchorEl] = useState(null);

    const openMenu = Boolean(anchorEl);

    const signOut = () => dispatch(SignInAction.signOut()).then(() => {
        dispatch({ type: 'RESET_USER' })
        history.push({
            pathname: PreAuthRoutes('login').path
        });
    });

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <IconButton onClick={handleClick}>
                <AccountCircle />
            </IconButton>
            <Menu anchorEl={anchorEl} open={openMenu} onClose={handleClose} onClick={handleClose} PaperProps={{ elevation: 0, className: classes.menuPaper }} transformOrigin={{ horizontal: 'right', vertical: 'top' }} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}   >
                <MenuItem component={Link} to={PostAuthRoutes('profile_details').path}>
                    <ListItemIcon>{React.createElement(PostAuthRoutes('profile_details').menuIcon)} </ListItemIcon>
                    {PostAuthRoutes('profile_details').name}
                </MenuItem>
                <Divider />
                <MenuItem onClick={signOut}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </React.Fragment>
    )
}

export { AccountMenu }
