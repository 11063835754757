
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { InfluencerAction, StoryAction } from "../../../redux/actions";
import { PostAuthRoutes } from "../../../routes";
import { StoryCard } from "../../stories";
import { InfluencerCard, InfluencerCardLoader } from "../influencer-card";
import { CardHeader as PageHeader } from "../../../_components/card";
import { NoData } from "../../../_components/layout";

function InfluencerDetails() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const { search } = useLocation();

    const params = new URLSearchParams(search);
    const influencerId = params.get('influencerId'); // plan id from url

    /** Redux reducer state */
    const { influencer_details_loading: isLoading, influencer_details } = useSelector((state) => state.InfluencerReducer);
    const { influencer_stories_loading: isStoriesLoading, influencer_stories } = useSelector((state) => state.StoryReducer);

    /** Redux reducer actions */
    const influencerDetails = (params) => dispatch(InfluencerAction.influencerDetails(params));
    const listInfluencerStories = (params) => dispatch(StoryAction.listInfluencerStories(params));

    /** Initialize and declare state */
    const [payload, setPayload] = useState({ limit: 100, page: 1, filter: { influencer_id: influencerId } });

    useEffect(() => {
        influencerDetails({ influencer_id: influencerId });
        //eslint-disable-next-line
    }, [influencerId])

    useEffect(() => {
        getNext() // Initially load participants list
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * function to fetch influencer list
     * @param {Object} payload - The params which are used for the api
     * @param {string} payload.page - Page number to load
     * @param {string} payload.limit -  Count of the documents per page
     * @param {string} payload.filter - Filter data
     * @return json
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    const getNext = async () => {
        const result = await listInfluencerStories(payload);
        if (result.status)
            setPayload({ ...payload, page: payload.page + 1 });
    }


    return (
        <React.Fragment>
            {!isLoading && Object.keys(influencer_details).length === 0 && <NoData content1={`No`} content2={`Influencers available`} minHeight={`55vh`} ></NoData>}
            {(!isLoading && Object.keys(influencer_details).length > 0) &&
                <React.Fragment>
                    <PageHeader title={PostAuthRoutes('influencer_details').name} />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <InfluencerCard influencer={influencer_details} height="80vh" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <InfiniteScroll dataLength={influencer_stories.length} next={getNext} hasMore={true} height={700}>
                                <Grid container spacing={2} direction="row" justifyContent="left" alignItems="center" >
                                    {
                                        influencer_stories.length > 0 && influencer_stories.map((story, i) => {
                                            return (
                                                <Grid item xs={12} sm={6} md={3} lg={3} xl={3} key={i}><StoryCard story={story} /> </Grid>
                                            );
                                        })
                                    }
                                    {isStoriesLoading && <div>lodaing</div>}
                                </Grid>
                            </InfiniteScroll>
                        </Grid>
                    </Grid>

                </React.Fragment>}
            {isLoading && <InfluencerCardLoader />}
            
        </React.Fragment>
    );
}

export { InfluencerDetails }