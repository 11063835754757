import { apiServices, cookieServices } from '../../_helpers';


export const RegisterServices = {

    /**
    * Service for instaSignin 
    * @param {Object} params - The params which are used for signin api.
    * @author Jasin
    * @created_at 28 October 2022
    */
    createAccount: (params) => {

        return apiServices.post('/brand/create-account', params)
            .then((response) => {
                return response;
            })
    },

    /**
    * Service to add billing address
    * @param {Object} params - The params which are used add billing address api.
    * @param {String} params.address
    * @param {String} params.city
    * @param {String} params.state
    * @param {String} params.country
    * @param {String} params.pincode
    * @author Akshay N 
    * @created_at 30 May 2022
    */
    addBillingAddress: (params) => {
        return apiServices.post('/brand/add-billing-address', params)
            .then((response) => {
                let date = new Date();
                date.setTime(date.getTime() + (30 * 60 * 24 * 60 * 1000));
                const options = { path: '/', expires: date };
                cookieServices.set('accessToken', response.data.token, options);
                return response;
            })
    },

    /**
     * Service for sign out 
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    confirmEmail: (verificationString) => {

        return apiServices.get(`/brand/confirm-email/${verificationString}`)
            .then((response) => {
                cookieServices.remove('accessToken');
                return response;
            })
    },

};
