import React from 'react';
import { Grid, Typography } from '@mui/material';
import ErrorImage from './404.svg'
import { StyledNoData } from '.';

function NoData({ content1, content2, description, showImage = true, children, ...props }) {


    return (
        <StyledNoData  {...props} >
            <Grid item xs={12} sm={12}>
                {showImage && <img src={ErrorImage} alt="No data found" />}
                <Typography variant="h1">{content1} <span className='error'>{content2}</span></Typography>
                <Typography component="h1" variant="caption" mb={2}>{description}</Typography>
                {children}
            </Grid>
        </StyledNoData>
    )
}


export { NoData };
