import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import queryString from 'query-string';
import { FilterAlt } from "@mui/icons-material";
import { Box, Stack, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { StoryAction } from "../../redux/actions";
import { PostAuthRoutes } from "../../routes";
import { CardHeader } from "../../_components/card";
import { GeneralButton, FilterMenu } from "../../_components/controls";
import { FormSearchInput } from "../../_components/form";
import { NoData } from "../../_components/layout";
import { StoryFilter, StoryCard, StoryCardLoader, StoryExport } from "./";

function Stories() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    /** Redux reducer states */
    const { stories_loading: isLoading, stories } = useSelector((state) => state.StoryReducer);

    /** Redux reducer actions */
    const listStories = (params) => dispatch(StoryAction.listStories(params));

    /** Initialize and declare state */
    const [showStoryFilterModal, setShowStoryFilterModal] = useState(false);
    const [payload, setPayload] = useState({ limit: 100, page: 1, filter: { status: searchParams.get('status') ?? '', posted_on: searchParams.get('posted_on') ?? '' }, search: searchParams.get('q') ?? '' });

    useEffect(() => {

        const queryParam = { ...payload.filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });

        queryParam.p = payload.page;
        if (payload.search) queryParam.q = payload.search; else delete queryParam.q;
        if (payload.filter.status) queryParam.status = payload.filter.status; else delete queryParam.status;
        if (payload.filter.posted_on) queryParam.posted_on = payload.filter.posted_on; else delete queryParam.posted_on;

        history.push({
            pathname: PostAuthRoutes('stories').path,
            search: `?${queryString.stringify(queryParam)}`
        });

        getNext() // Initially load participants list

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payload.filter, payload.search])

    /**
     * function to fetch Story list
     * @param {Object} payload - The params which are used for the api
     * @param {string} payload.page - Page number to load
     * @param {string} payload.limit -  Count of the documents per page
     * @param {string} payload.filter - Filter data
     * @param {string} payload.search - Search key
     * @return json
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    const getNext = async () => {

        const result = await listStories(payload);
        if (result.status) {
            setPayload({ ...payload, page: payload.page + 1 });
        }
    }

    /**
     * function to open/close story filter modal
     * @param {string} null 
     * @return view
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    const storyFilterModal = () => {
        setShowStoryFilterModal(!showStoryFilterModal);
    }

    /**
     * function to apply the selected filter options on the story list
     * @param {Object} filterData 
     * @param {String} filterData.status Selected status from the story filter modal
     * @param {String} filterData.posted_on Selected start date form the story filter modal
     * @return view
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    const applyFilter = (filterData) => {
        setPayload({ ...payload, page: 1, filter: filterData });
    }

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const applySearch = (text) => {
        setPayload({ ...payload, page: 1, search: text });
    }


    return (
        <React.Fragment>
            <Box>
                <CardHeader title={PostAuthRoutes('stories').name} action={
                    <Stack spacing={1} direction="row">
                        <FormSearchInput getSearchText={applySearch} searchText={payload.search} />
                        <GeneralButton label={<FilterAlt />} onClick={() => storyFilterModal()} />
                        <FilterMenu>
                            <StoryExport filter={payload.filter} />
                        </FilterMenu>
                    </Stack>
                } />
                <StoryFilter show={showStoryFilterModal} closeModal={storyFilterModal} applyFilter={applyFilter} storyFilter={payload.filter} />
                <InfiniteScroll dataLength={stories.length} next={getNext} hasMore={true}>
                    <Grid container spacing={2} direction="row" justifyContent="left" alignItems="center" >
                        {
                            stories.length > 0 && stories.map((story, i) => {
                                return (
                                    <Grid item xs={12} sm={6} md={3} lg={2} xl={2} key={i}><StoryCard story={story} /> </Grid>
                                );
                            })
                        }
                        {isLoading && <StoryCardLoader />}
                        {!isLoading && stories.length === 0 && <NoData content1={`Stories`} content2={`Not Found`} description={`If using a custom view, try adjusting the filters,Otherwise, create some data.`} minHeight={`75vh`} > </NoData>}
                    </Grid>
                </InfiniteScroll>
            </Box>
        </React.Fragment>

    )
}

export { Stories }