import React from 'react'
import { Grid } from '@mui/material'
import { StoryPromoDetails, StoryDetails, StoryTimeline } from '.';
import { CardHeader as PageHeader } from "../../../_components/card";
import { PostAuthRoutes } from "../../../routes";

function StoriesDetail() {


    return (
        <React.Fragment>
            <PageHeader title={PostAuthRoutes('stories_detail').name} />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} display="flex" alignItems="center"  >
                    <StoryPromoDetails />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} display="flex" alignItems="center"  >
                    <StoryDetails />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={`flex`} alignItems={`center`} justifyContent={`center`}  >
                    <StoryTimeline />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export { StoriesDetail }