import React, { useEffect } from 'react';
import { StatisticsWidget } from '../../../_components';
import { useDispatch, useSelector } from 'react-redux';
import { WalletAction } from '../../../redux/actions';
import { Box } from '@mui/material';
import { CurrencyRupee } from '@mui/icons-material';

function WalletMonthlyCreditWidget() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    const { wallet_monthly_credit } = useSelector((state) => state.WalletReducer);

    /** Redux reducer actions */
    const getWalletMonthlyCredit = (params) => dispatch(WalletAction.getWalletMonthlyCredit(params));

    useEffect(() => {
        getWalletMonthlyCredit({ type: "monthly" });
        //eslint-disable-next-line
    }, [])

    return (
        <Box>
            <StatisticsWidget
                color="dark"
                icon={<CurrencyRupee />}
                title="Monthly Credit"
                count={wallet_monthly_credit}
                percentage={{
                    label: `Your monthly credit is ${wallet_monthly_credit} rupees`
                }}
            />
        </Box>
    )
}

export { WalletMonthlyCreditWidget }