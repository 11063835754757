import React, { useEffect, useState } from "react";
import { CardHeader, CardHeader as PageHeader } from "../../../_components/card";
import { Grid, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { PostAuthRoutes } from "../../../routes";
import InfiniteScroll from "react-infinite-scroll-component";
import { FilterMenu, FormSearchInput, GeneralButton, NoData, UpdateStatusModal } from "../../../_components";
import { CouponCodeCard, CouponCodeCardLoader } from "../../coupon-codes";
import { GiveawayAction } from "../../../redux/actions";
import { GiveawayCard, GiveawayCardLoader } from "../giveaway-card";
import { GiveawayGenerateWinner } from "../giveaways-generate-winner";
import { GiveawayWinnerCard } from "../giveaways-winner-card";
import { Tabs } from "../../../_components/tabs";
import { Add, FilterAlt, Share } from "@mui/icons-material";
import queryString from 'query-string';
import { CreateOrUpdateGiveawaysCouponCode, GiveawaysCouponCodesFilter } from "../giveaways-coupon-codes";
import { GiveawayEntries } from "./giveaway-entries";
import { GiveawayShare } from "./giveaway-share";

function GiveawayDetails() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    let { search } = useLocation();
    const history = useHistory();

    const params = new URLSearchParams(search);
    const giveawayId = params.get('gId'); // plan promo from url

    /** Redux actions and state */
    const { brand_giveaway_details_loading: isLoading, brand_giveaway_details: giveaway_details } = useSelector((state) => state.GiveawayReducer);
    const { brand_giveaway_coupon_codes: coupon_codes,brand_giveaway_coupon_codes_loading: isCouponLoading } = useSelector((state) => state.GiveawayReducer);
    const { giveaway_winner_loading: isWinnerLoading, giveaway_winner } = useSelector((state) => state.GiveawayReducer);
    const giveawayDetails = (params) => dispatch(GiveawayAction.giveawayDetails(params));
    const listBrandGiveawayCouponCodes = (params) => dispatch(GiveawayAction.listBrandGiveawayCouponCodes(params));
    const getGiveawayWinner = (params) => dispatch(GiveawayAction.getGiveawayWinner(params));
    const changeCouponStatus = (params) => dispatch(GiveawayAction.changeCouponStatus(params));
    const getRunningGiveaways = (params) => dispatch(GiveawayAction.getRunningGiveaways(params));
    

    /** Initialize and declare state */
    const [couponCodesPayload, setCouponCodesPayload] = useState({ limit: 100, page: 1, filter: { status: params.get('status') ?? '' }, search: params.get('q') ?? '' });
    const [showCouponStatusUpdateModal, setShowCouponStatusUpdateModal] = useState(false);
    const [couponStatusData, setCouponStatusData] = useState({ id: '', status: '', item: '' });
    const [showCreateCouponModal, setShowCreateCouponModal] = useState(false);
    const [showCouponFilterModal, setShowCouponFilterModal] = useState(false);

    const [showGenerateWinnerModal, setShowGenerateWinnerModal] = useState(false);

    useEffect(() => {
        giveawayDetails({ giveaway_id: giveawayId });
        getGiveawayWinner({ giveaway_id: giveawayId });
        getRunningGiveaways();

        //eslint-disable-next-line
    }, [giveawayId])

    useEffect(() => {

        const queryParam = { ...couponCodesPayload.filter };
        Object.keys(queryParam).forEach(element => {
            if (queryParam[element] === "" || queryParam[element] === null) {
                delete queryParam[element];
            }
        });

        queryParam.gId = giveawayId;
        queryParam.p = couponCodesPayload.page;
        if (couponCodesPayload.search) queryParam.q = couponCodesPayload.search; else delete queryParam.q;
        if (couponCodesPayload.filter.status) queryParam.status = couponCodesPayload.filter.status; else delete queryParam.status;

        history.push({
            search: `?${queryString.stringify(queryParam)}`
        });

        getNextGiveawayCouponCodes();
        //eslint-disable-next-line
    }, [giveawayId, couponCodesPayload.filter, couponCodesPayload.search])
    /**
     * function to list giveaways
     * @param {string} null 
     * @return view
     * @author
     * @created_at 14 June 2022
     */
    const getNextGiveawayCouponCodes = async () => {
        let giveawayCouponCodesResult = await listBrandGiveawayCouponCodes({ giveaway_id: giveawayId, ...couponCodesPayload });
        if (giveawayCouponCodesResult.status)
            setCouponCodesPayload({ ...couponCodesPayload, page: couponCodesPayload.page + 1 });
    }


    /**
     * function to open/close generate winner modal
     * @param {string} null
     * @return view
     * @author 
     * @created_at 30 May 2022
     */
    const generateWinnerModal = () => {
        setShowGenerateWinnerModal(!showGenerateWinnerModal);
    }

    /**
     * function to open/close generate winner modal
     * @param {string} null
     * @return view
     * @author 
     * @created_at 30 May 2022
     */
    const closeGenerateWinnerModal = () => {
        setShowGenerateWinnerModal(!showGenerateWinnerModal);
    }

        /**
     * function to open/close status update modal
     * @param {string} id
     * @param {string} status 
     * @return view
     * @author 
     * @created_at 30 May 2022
     */
    const couponStatusUpdateModal = ({ id, status, item }) => {
        setCouponStatusData({ id: id, status: status, item: item });
        setShowCouponStatusUpdateModal(!showCouponStatusUpdateModal);
    }

    /**
     * function to change coupon code status
     * @param {string} id 
     * @return view
     * @author 
     * @created_at 30 May 2022
     */
    const updateCouponStatusAction = async (id) => {
        setShowCouponStatusUpdateModal(!showCouponStatusUpdateModal);
        await changeCouponStatus({ 'coupon_id': id });
    }

    /**
     * function to open/close create coupon code modal
     * @param {string} null
     * @return view
     * @author 
     * @created_at 30 May 2022
     */
    const createCouponCodesModal = () => {
        setShowCreateCouponModal(!showCreateCouponModal);
    }

        /**
     * function to open/close filter modal
     * @param {string} null
     * @return view
     * @author 
     * @created_at 30 May 2022
     */
    const couponFilterModal = () => {
        setShowCouponFilterModal(!showCouponFilterModal);
    }

    /**
     * function to apply filter data to payload
     * @param {string} null
     * @return view
     * @author 
     * @created_at 30 May 2022
     */
    const applyCouponFilter = (filterData) => {
        setCouponCodesPayload({ ...couponCodesPayload, page: 1, filter: filterData });
    }

    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     * @created_at 30 May 2022
     */
    const applyCouponSearch = (text) => {
        setCouponCodesPayload({ ...couponCodesPayload, page: 1, search: text });
    }

    function GiveawayDetailsTabPanel() {
        return (
            <React.Fragment>
                {
                    (!isLoading && Object.keys(giveaway_details).length > 0) &&
                    <Grid container spacing={2} display="flex" justifyContent="center" alignItems="center">
                        <GiveawayWinnerCard isLoading={isWinnerLoading} giveaway_winner={giveaway_winner} />
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                            <GiveawayCard giveaway={giveaway_details} hasAction={false} />
                            {isLoading && <GiveawayCardLoader count={1} />}
                        </Grid>
                    </Grid>
                }
                {(isLoading) && (
                    <Grid container spacing={2} display="flex" justifyContent="center" alignItems="center">
                        <GiveawayCardLoader count={1}/>
                    </Grid>
                )}

            
            </React.Fragment>
        )
    }

    function CouponCodeTabPanel() {
        return (
            <React.Fragment>
                <CardHeader
                    action={(
                        <Stack spacing={1} direction="row">
                            <GeneralButton startIcon={<Add />} label='Create Coupon Codes' onClick={() => createCouponCodesModal()} />
                            <FormSearchInput getSearchText={applyCouponSearch} searchText={couponCodesPayload.search} />
                            <GeneralButton label={<FilterAlt />} onClick={() => couponFilterModal()} />
                        </Stack>
                    )}
                />
                <GiveawaysCouponCodesFilter show={showCouponFilterModal} closeModal={couponFilterModal} applyFilter={applyCouponFilter} couponFilter={couponCodesPayload.filter} />
                <InfiniteScroll dataLength={coupon_codes.length} next={getNextGiveawayCouponCodes} hasMore={true} >
                    <Grid container spacing={2} direction="row" justifyContent="left" alignItems="center" >
                        {
                            coupon_codes.length > 0 && coupon_codes.map((coupon_code, i) => {
                                return <Grid key={i} item xs={12} sm={12} md={3} lg={3} xl={3}><CouponCodeCard category="giveaway" promo={{_id: giveawayId}} {...coupon_code} height='170px' isClickable={true} hasAction={true} canChangeStatus={true} statusUpdateModal={couponStatusUpdateModal} canRedeem={true} /></Grid>;
                            })
                        }
                        {(isCouponLoading && coupon_codes.length === 0) && (<CouponCodeCardLoader />)}
                        {!isCouponLoading && coupon_codes.length === 0 && <NoData content1={`No`} content2={`Coupon codes  available`} description={`If using a custom view, try adjusting the filters,Otherwise, create some data.`} />}
                    </Grid>
                </InfiniteScroll>
                <UpdateStatusModal show={showCouponStatusUpdateModal} closeModal={couponStatusUpdateModal} data={couponStatusData} updateStatus={updateCouponStatusAction} />
                <CreateOrUpdateGiveawaysCouponCode show={showCreateCouponModal} closeModal={createCouponCodesModal} giveaway_id={giveawayId} />
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <React.Fragment>
                <PageHeader title={PostAuthRoutes('giveaway_details').name} action={(
                    <Stack spacing={1} direction="row">
                        <GeneralButton label='Generate Winner' onClick={() => generateWinnerModal()} />
                        <FilterMenu label='Share' startIcon={<Share />}>
                            <GiveawayShare giveawayId={giveawayId} giveaway_details={giveaway_details} />
                        </FilterMenu>
                    </Stack>
                )} />
                {!isLoading && Object.keys(giveaway_details).length === 0 && <NoData content1={`No`} content2={`Promo available`} minHeight={`55vh`} ></NoData>}
                <Tabs
                    groupName={'gaTab'}
                    data={[
                        { slug: 'giveaway', label: "Giveaway", component: <GiveawayDetailsTabPanel /> },
                        { slug: 'entries', label: "Giveaway Entries", component: <GiveawayEntries /> },
                        { slug: 'codes', label: "Coupon Codes", component: <CouponCodeTabPanel />, disabled: ((Object.keys(giveaway_details).length > 0 && giveaway_details.send_coupon_code !== 'no') ? false : true) }
                    ]}
                />
            </React.Fragment>
        <GiveawayGenerateWinner show={showGenerateWinnerModal} closeModal={closeGenerateWinnerModal} />
        </React.Fragment>
    );
}

export { GiveawayDetails }