import { InfluencerServices } from "../services/InfluencerServices";

export const INFLUENCER_REQUEST = 'INFLUENCER_REQUEST';
export const INFLUENCER_SUCCESS = 'INFLUENCER_SUCCESS';
export const INFLUENCER_FAILURE = 'INFLUENCER_FAILURE';

export const INFLUENCER_DETAILS_REQUEST = 'INFLUENCER_DETAILS_REQUEST';
export const INFLUENCER_DETAILS_SUCCESS = 'INFLUENCER_DETAILS_SUCCESS';
export const INFLUENCER_DETAILS_FAILURE = 'INFLUENCER_DETAILS_FAILURE';

export const INFLUENCER_COUNT_REQUEST = 'INFLUENCER_COUNT_REQUEST';
export const INFLUENCER_COUNT_SUCCESS = 'INFLUENCER_COUNT_SUCCESS';
export const INFLUENCER_COUNT_FAILURE = 'INFLUENCER_COUNT_FAILURE';

export const EXPORT_INFLUENCER_REQUEST = 'EXPORT_INFLUENCER_REQUEST';
export const EXPORT_INFLUENCER_SUCCESS = 'EXPORT_INFLUENCER_SUCCESS';
export const EXPORT_INFLUENCER_FAILURE = 'EXPORT_INFLUENCER_FAILURE';

export const InfluencerAction = {

    /**
    * Action to get influencer count
    * @param {Object} params - The params which are used for the api
    * @author Akshay N
    * @created_at 30 May 2022
    */
    getInfluencerCount: (params) => {
        return dispatch => {
            dispatch(request(params));
            return InfluencerServices.getInfluencerCount(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: INFLUENCER_COUNT_REQUEST, request: request } }
        function success(request, response) { return { type: INFLUENCER_COUNT_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: INFLUENCER_COUNT_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },


    /**
     * Action for list influencer
     * @param {Object} params - The params which are used for the api
     * @author 
     * @created_at 30 May 2022
     */
    listInfluencer: (params) => {

        return dispatch => {
            dispatch(request(params));
            return InfluencerServices.listInfluencer(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: INFLUENCER_REQUEST, request: request } }
        function success(request, response) { return { type: INFLUENCER_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: INFLUENCER_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to get influencer details
     * @param {Object} params - The params which are used for the api
     * @param {String} req.body.influencer_id
     * @author Akshay N 
     * @created_at 30 May 2022
    */
    influencerDetails: (params) => {
        return dispatch => {
            dispatch(request(params));
            return InfluencerServices.influencerDetails(params)
                .then(
                    response => { return dispatch(success(params, response)); },
                    error => { return dispatch(failure(params, error)); }
                );
        };

        function request(request) { return { type: INFLUENCER_DETAILS_REQUEST, request: request } }
        function success(request, response) { return { type: INFLUENCER_DETAILS_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: INFLUENCER_DETAILS_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },

    /**
     * Action to get top influencers by followers count
     * @param {Object} params - The params which are used for the api
     * @author Akshay N
     * @created_at 30 May 2022
     */
    getInfluencersWithMostFollowers: (params) => {
        return dispatch => {
            return InfluencerServices.getInfluencersWithMostFollowers(params)
                .then(
                    response => { return response; },
                    error => { return error; }
                );
        }
    },

    /**
    * Action to get top influencers by stories count
    * @param {Object} params - The params which are used for the api
    * @author Akshay N
    * @created_at 30 May 2022
    */
    getInfluencersWithMostStories: (params) => {
        return dispatch => {
            return InfluencerServices.getInfluencersWithMostStories(params)
                .then(
                    response => { return response; },
                    error => { return error; }
                );
        }
    },
    
    /**
    * Action to export influencer csv
    * @param {Object} params - The params which are used for the api
    * @author Jasin
    * @created_at 30 May 2022
    */
     exportInfluencerCsv: (params) => {
        return dispatch => {
            dispatch(request(params));
            return InfluencerServices.exportInfluencerCsv(params)
                .then(
                    response => { return dispatch(success(request, response)); },
                    error => { return dispatch(failure(request, error)); }
                );
        };

        function request(request) { return { type: EXPORT_INFLUENCER_REQUEST, request: request } }
        function success(request, response) { return { type: EXPORT_INFLUENCER_SUCCESS, request: request, message: response.message, data: response.data, status: response.status, status_code: response.status_code } }
        function failure(request, error) { return { type: EXPORT_INFLUENCER_FAILURE, request: request, data: error.data, errors: error.errors, message: error.message, status: error.status, status_code: error.status_code } };
    },
}