import React from 'react'
import { CalendarToday, Instagram } from '@mui/icons-material'
import { Box, Card, CardActionArea, CardContent, CardMedia, Divider, LinearProgress, Stack, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { PostAuthRoutes } from '../../../routes'
import { POST_BROKEN_IMAGE } from '../../../_helpers'

function StoryCard({ story }) {

    /** Initialize plugins and variables */
    const history = useHistory();

    /** Initialize form values from story */
    const { story_url, promo_name, promo_insta_handle, created_at, current_checkpoint, _id, coupon_code, description, influencer_insta_handle, influencer_name } = story;

    /**
     * function to handle click to direct to story details page
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    const handleClick = () => {
        history.push({
            pathname: PostAuthRoutes('stories_detail').path,
            search: `?sId=${_id}`
        });
    }

    /**
     * function to direct to promo details page
     * @return view
     * @author 
     * @created_at 30 May 2022
     */
    function handlePromoDetailsClick() {
        history.push({
            pathname: PostAuthRoutes('promo_details').path,
            search: `?pId=${story.promo_id}`
        });
    }

    return (
        <Card>
            <CardActionArea style={{ height: `100%`, borderRadius: `0.75rem` }} onClick={handleClick}>
                <CardMedia onError={e => { e.target.src = POST_BROKEN_IMAGE }} style={{ margin: 0, width: `100%`, borderRadius: `0.75rem` }} component="img" image={story_url} />
                <CardContent style={{ position: "absolute", height: `100%`, top: 0, right: 0, left: 0, bottom: 0, backgroundImage: "linear-gradient(360deg, rgba(0, 0, 0, 0.8) 40%, rgba(51, 51, 53, 0) 100%)", borderRadius: `0.75rem` }}>
                    <Box style={{ position: `absolute`, right: 0, left: 0, bottom: 0, padding: `30px`, width: `100%` }} >
                        {promo_name &&
                            <React.Fragment>
                                <Stack>
                                    <Typography variant={`h5`} onClick={handlePromoDetailsClick} sx={{ cursor: `pointer` }}>{promo_name}</Typography>
                                    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0.5}> <Instagram /><Typography variant={`body2`} component="div" sx={{ fontWeight: 500, lineHeight: 1.625 }} >{`@${promo_insta_handle}`}</Typography></Stack>
                                    <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={0.5}><CalendarToday /> <Typography variant={`caption`} component="div" sx={{ fontWeight: 500, lineHeight: 1.625 }} >{created_at}</Typography></Stack>
                                </Stack>
                                <Divider />
                            </React.Fragment>
                        }
                        <Box>
                            <Stack direction="column" justifyContent="center" alignItems="flex-start">
                                <Typography variant={`h4`} color={`#fff`} style={{ cursor: "pointer" }}>{coupon_code}</Typography>
                                <Typography variant={`caption`} color={`#fff`} style={{ paddingTop: 3, paddingBottom: 3 }}>{description}</Typography>
                            </Stack>
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: `100%` }} mb={1}>
                            <Box sx={{ width: `${current_checkpoint ? current_checkpoint.percentage : 0}%`, mr: 1 }}>
                                <LinearProgress variant="determinate" value={current_checkpoint ? current_checkpoint.percentage : 0} />
                            </Box>
                            <Box>
                                <Typography variant="body2" color="text.secondary">{`${current_checkpoint ? current_checkpoint.percentage : 0}%`}</Typography>
                            </Box>
                        </Box>
                        {influencer_name &&
                            <Typography variant={`body2`}>{`${influencer_name}`}  <br /> {`@${influencer_insta_handle}`}</Typography>
                        }

                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export { StoryCard }