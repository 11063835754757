import { apiServices } from "../../_helpers";


export const BrandCalendarServices = {

    getBrandCalendar: (params) => {

		return apiServices.post('/brand/calendar', params)
			.then((response) => { return response; })
	},
}
