
module.exports = {
    /**
     * function to validate the promo create or update form inputs
     * @param {string} name Input name
     * @param {string} value Input value
     * @param {object} [data={}] All input values stored in the state
     * @author Akshay N
     * @created_at 28 May 2022
     */
    validate: (name, value, data = {}) => {
        const errors = {};
        switch (name) {

            case 'account_holder_name': {
                errors.account_holder_name = '';
                if (value === '')
                    errors.account_holder_name = 'Enter account holder name. ';
                break;
            } case 'routing_number': {
                errors.routing_number = value.length < 9 ? 'Routing number must be 9 characters long.' : '';
                if (value === '')
                    errors.routing_number = 'Enter routing number. ';
                break;
            } case 'confirm_routing_number': {
                const routing_number = data.routing_number ?? '';
                errors.confirm_routing_number = value.length < 9 ? 'Routing number must be 9 characters long.' : '';
                if (routing_number !== '' && routing_number !== value)
                    errors.confirm_routing_number = 'Confirm routing number should match with the routing number.';
                if (value === '')
                    errors.confirm_routing_number = 'Re-enter routing number.';
                break;
            } case 'account_number': {
                errors.account_number = '';
                if (value === '')
                    errors.account_number = 'Enter account number. ';
                break;
            } case 'confirm_account_number': {

                const account_number = data.account_number ?? '';
                errors.confirm_account_number = '';
                if (account_number !== '' && account_number !== value)
                    errors.confirm_account_number = 'Confirm account number should match with the account number.';
                if (value === '')
                    errors.confirm_account_number = 'Re-enter account number.';
                break;
            } case 'account_holder_type': {
                errors.account_holder_type = '';
                if (value === '')
                    errors.account_holder_type = 'Select an account holder type. ';
                break;
            } case 'name': {
                errors.name = '';
                if (value === '')
                    errors.name = 'Enter full name. ';
                break;
            } case 'address': {
                errors.address = '';
                if (value === '')
                    errors.address = 'Enter address. ';
                break;
            } case 'city': {
                errors.city = '';
                if (value === '')
                    errors.city = 'Enter city. ';
                break;
            } case 'state': {
                errors.state = '';
                if (value === '')
                    errors.state = 'Enter state. ';
                break;
            } case 'pincode': {
                errors.pincode = '';
                if (value === '')
                    errors.pincode = 'Enter pincode. ';
                break;
            } case 'country': {
                errors.country = '';
                if (value === '')
                    errors.country = 'Enter country. ';
                break;
            } case 'mobile_number': {
                errors.mobile_number = '';
                if (value === 'undefined') {
                    errors.mobile_number = 'Mobile number is invalid. '
                }
                if (value === '')
                    errors.mobile_number = 'Enter mobile number. ';
                break;
            } default:
                errors[name] = '';
                break;
        }
        return errors;
    }
}