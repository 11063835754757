import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { PostAuthRoutes } from "../../../routes";
import { APP_NAME, handleInputChange, validateForm } from "../../../_helpers";
import { validate } from ".";
import { CancelButton, SubmitButton } from "../../../_components/controls";
import { FormBrowseFile, FormDateTimePicker, FormInput, FormMultiSelect, FormRadio } from "../../../_components/form";
import { Card, CardContent, CardHeader } from "../../../_components/card";
import { Alert, Box, Grid, Stack } from "@mui/material";
import { SchedulePostAction } from "../../../redux/actions";
import { Facebook, Info, Instagram } from '@mui/icons-material';
import { makeStyles } from "@mui/styles";

// Initialize form input values to null
const inputs = { post_id: '', platforms: [], post_type: 'post' , ig_post_type: 'post', fb_post_type: 'post', customize_caption: 'no', caption: '', ig_caption: '', fb_caption: '', scheduled_time: '', scheduling_option: 'schedule', media_files: []};

// Declare scheduling options types select options
const schedulingOptions = [
    { value: 'publish_now', label: 'Publish Now' },
    { value: 'schedule', label: 'Schedule' },
    { value: 'draft', label: 'Draft' }
]

// Declare Post type options types select options
const postTypeOptions = [
    { value: 'post', label: 'Post' },
    { value: 'reels', label: 'Reels' }
]

const useStyles = makeStyles((theme) => ({
    icon: {
        marginRight: `10px`
    }
}))

const customizeCaption = [{ value: 'yes', label: 'Yes' }, { value: 'no', label: 'No' }]

function CreateOrUpdateSchedulePost() {

    const classes = useStyles();

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { post } = location.state ?? {};

    /** Redux actions */
    const createSchedulePost = (params) => dispatch(SchedulePostAction.createSchedulePost(params));
    const updateSchedulePost = (params) => dispatch(SchedulePostAction.updateSchedulePost(params));

    /** Redux reducer states */
    const { create_schedule_post_loading: isLoading } = useSelector((state) => state.SchedulePostReducer);
    const { user_loading: isUserLoading, user } = useSelector((state) => state.UserReducer);

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, ig_post_type: '', fb_post_type: '', customize_caption: '', scheduling_option: '', post_type: '' });
    const [action, setAction] = useState({ isSubmitted: false });
    const [isPostLoading, setIsPostLoading] = useState(true);
    const [mediaTypes, setMediaTypes] = useState("");

    /**
     * function to get platform select list
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const platformSelect = () => {
        const options = [
            { value: 'instagram', label: `${data.insta_handle ? data.insta_handle : (!isUserLoading && user) ? user.insta_handle : ''}`, icon: <Instagram className={classes.icon} /> },
            { value: 'facebook', label: `${data.facebook_page ? data.facebook_page : (!isUserLoading && user && user.ig_token) ? user.ig_token.page_name : ''}`, icon: <Facebook className={classes.icon} /> },
        ]
        return options;
    }

    useEffect(() => {
        if (post && post._id) {
            setData({
                post_id: post._id,
                platforms: [post.platform],
                ig_post_type: post.post_type,
                fb_post_type: post.post_type,
                caption: post.caption,
                ig_caption: post.caption,
                fb_caption: post.caption,
                customize_caption: "no",
                scheduled_time: (post.scheduled_time ? (new Date(post.scheduled_time.split(" ").map(part => part.split("/").reverse().join("/")).join(" ")).toISOString()) : ''),
                scheduling_option: post.scheduling_option,
                media_files: (post.post_type === 'carousel') ? post.carousel_medias.map(obj => obj.media_url) : [post.media_url],
                post_type: (post.post_type === 'carousel' ) ? 'post' : post.post_type,
            });
            setIsPostLoading(false);
        } else {
            setIsPostLoading(false);
        }
    }, [post])

    useEffect(() => {
        if (!isPostLoading && data.customize_caption === 'no') {
            setData({
                ...data,
                ig_caption: data.caption,
                fb_caption: data.caption,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.customize_caption, data.caption])

    useEffect(() => {
        if (!isPostLoading && data.platforms && !(data.platforms.includes('instagram') && data.platforms.includes('facebook'))) {
            setData({
                ...data,
                customize_caption: 'no',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.platforms])

    useEffect(() => {
        if (!isPostLoading) {
            if ((data.post_type !== 'reels') && data.media_files && data.media_files.length > 1) {
                setData({
                    ...data,
                    ig_post_type: 'carousel',
                    fb_post_type: 'carousel'
                });
            } else {
                setData({
                    ...data,
                    ig_post_type: data.ig_post_type === 'carousel' ? 'post' : data.post_type,
                    fb_post_type: data.fb_post_type === 'carousel' ? 'post' : data.post_type,
                });
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.media_files, data.post_type])


    /**
     * function to handle input changes and modify the value
     * @param {string} e.name input name
     * @param {string} e.value input value
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const handleChange = async (e) => {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle create/update schedule post form submit action
     * @param {object} e form object
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        for (let i = 0; i < data.media_files.length; i++) {
            formData.append("media_files", data.media_files[i]);
        }
        formData.append("post_id", data.post_id);
        formData.append("platforms", data.platforms);
        formData.append("ig_post_type", data.ig_post_type);
        formData.append("fb_post_type", data.fb_post_type);
        formData.append("caption", data.caption);
        formData.append("ig_caption", data.ig_caption);
        formData.append("fb_caption", data.fb_caption);
        formData.append("customize_caption", data.customize_caption);
        formData.append("scheduled_time", data.scheduled_time);
        formData.append("scheduling_option", data.scheduling_option);
        
        dispatchCreateOrUpdatePostAction(formData);
    }

    /**
     * function to dispatch create/update schedule post api
     * @param {Object} formData - The params which are used for the api
     * @return view
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const dispatchCreateOrUpdatePostAction = async (formData) => {
        const isCreated = post && post._id ? await updateSchedulePost(formData) : await createSchedulePost(formData);
        if (isCreated && isCreated.status === 1) {
            history.push({
                pathname: PostAuthRoutes('schedule_post').path
            });
        } else {
            setErrors(isCreated.errors);
        }
    }

    /**
     * function to cancel schedule post create or update
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 28 May 2022
     */
    const cancelPost = () => {
        history.push({
            pathname: PostAuthRoutes('schedule_post').path
        });
    }

    useEffect(() => {
        const allowedIgTypes = ".jpeg,.jpg,.mov,.mp4";
        const allowedFbTypes = ".jpeg,.jpg,.png,.tiff,.bmp,.3gpp2,.3gpp,.asf,.avi,.dat,.divx,.dv,.f4v,.flv,.gif,.mp2t,.m4v, .mkv,.mod,.mov,.mp4,.mpeg,.mpg,.wmv";

        if (data.platforms && data.platforms.includes('instagram')) {
            setMediaTypes(allowedIgTypes);
        } else {
            setMediaTypes(allowedFbTypes)
        }


    }, [data.platforms])

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${post && post._id ? PostAuthRoutes('create_schedule_post').name : PostAuthRoutes('create_schedule_post').name}`}</title>
            </Helmet>
            <Box>
                <CardHeader title={post && post._id ? PostAuthRoutes('create_schedule_post').name : PostAuthRoutes('create_schedule_post').name} />
                <form onSubmit={handleSubmit} noValidate>
                    <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center">
                        <Grid item xs={12} md={6} lg={6}>
                            <Card >
                                {!isPostLoading && !isUserLoading && <CardContent >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormMultiSelect
                                                tabIndex={1}
                                                label={`Publishing platform`}
                                                name={`platforms`}
                                                placeholder={`Select publishing platform`}
                                                onChange={handleChange}
                                                data={platformSelect()}
                                                value={data.platforms}
                                                readOnly={post && post._id ? true : false}
                                                error={(action.isSubmitted && (errors.platforms)) ? (errors.platforms) : ''}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <FormRadio
                                                tabIndex={3}
                                                label={`Post Type`}
                                                name={`post_type`}
                                                onChange={handleChange}
                                                data={postTypeOptions}
                                                value={data.post_type}
                                                error={(action.isSubmitted && ((errors.post_type)) ? (errors.post_type) : '')}
                                                isReadOnly={(post && post._id) ? true : false}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <Box>
                                                <FormBrowseFile
                                                    tabIndex={2}
                                                    name="media_files"
                                                    error={(action.isSubmitted && (errors.media_files)) ? (errors.media_files) : ''}
                                                    placeholder="your post/reels"
                                                    value={data.media_files}
                                                    onChange={handleChange}
                                                    acceptType={mediaTypes}
                                                    info="Instagram | Allowed Format: jpg, jpeg, mov, mp4 | Allowed Maximum Size: Image-8 MB, Video-100 MB </br> Facebook | Allowed Maximum Size: Image-10 MB, Video-1 GB"
                                                    multiple={true}
                                                />
                                                <Alert icon={<Info sx={{ fontSize: `14px` }} color="info" />} style={{ background: `#00000000`, padding: `0 4px`, marginTop: `4px`, fontSize: `12px` }}><a target="_blank" rel="noreferrer" style={{ fontSize: `12px` }} href={`${PostAuthRoutes('schedule_post_specifications').path}`}>Click here to view media specifications</a></Alert>
                                            </Box> 
                                        </Grid>
                                        {data.platforms && data.platforms.includes('instagram') && data.platforms.includes('facebook') &&
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <FormRadio
                                                    tabIndex={3}
                                                    label={`Would you like customize caption for Facebook and Instagram?`}
                                                    name={`customize_caption`}
                                                    onChange={handleChange}
                                                    data={customizeCaption}
                                                    value={data.customize_caption}
                                                    error={(action.isSubmitted && ((errors.customize_caption)) ? (errors.customize_caption) : '')}
                                                />
                                            </Grid>
                                        }
                                        {data.customize_caption === 'no' &&
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <FormInput
                                                    tabIndex={4}
                                                    label='Caption'
                                                    name='caption'
                                                    value={data.caption}
                                                    error={action.isSubmitted && (errors.caption ? errors.caption : '')}
                                                    onChange={handleChange}
                                                    multiline
                                                    maxLength={250}
                                                    minRows={2}
                                                />
                                            </Grid>
                                        }
                                        {data.customize_caption === 'yes' &&
                                            <React.Fragment>
                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <FormInput
                                                        tabIndex={5}
                                                        label='Facebook Caption'
                                                        name='fb_caption'
                                                        value={data.fb_caption}
                                                        error={action.isSubmitted && (errors.fb_caption ? errors.fb_caption : '')}
                                                        onChange={handleChange}
                                                        multiline
                                                        maxLength={250}
                                                        minRows={2}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <FormInput
                                                        tabIndex={6}
                                                        label='Instagram Caption'
                                                        name='ig_caption'
                                                        value={data.ig_caption}
                                                        error={action.isSubmitted && (errors.ig_caption ? errors.ig_caption : '')}
                                                        onChange={handleChange}
                                                        multiline
                                                        maxLength={250}
                                                        minRows={2}
                                                    />
                                                </Grid>
                                            </React.Fragment>
                                        }
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                            <FormRadio
                                                tabIndex={7}
                                                label={`Scheduling options`}
                                                name={`scheduling_option`}
                                                onChange={handleChange}
                                                data={schedulingOptions}
                                                value={data.scheduling_option}
                                                error={(action.isSubmitted && ((errors.scheduling_option)) ? (errors.scheduling_option) : '')}
                                            />
                                        </Grid>
                                        {data.scheduling_option !== 'publish_now' &&
                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <FormDateTimePicker
                                                    tabIndex={8}
                                                    label='Schedule date'
                                                    name='scheduled_time'
                                                    value={data.scheduled_time}
                                                    error={action.isSubmitted && errors.scheduled_time ? errors.scheduled_time : ''}
                                                    onChange={handleChange}
                                                    minDateTime={new Date(new Date().getTime() + 15 * 60000)}
                                                />
                                            </Grid>
                                        }
                                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                            <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}  >
                                                <SubmitButton label={post && post._id ? 'Update' : 'Create'} loading={isLoading} tabIndex={9} />
                                                <CancelButton onClick={cancelPost} tabIndex={10} />
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                </CardContent>}
                            </Card>
                        </Grid>
                    </Grid>
                </form>
            </Box>
        </React.Fragment>
    )
}

export { CreateOrUpdateSchedulePost };
