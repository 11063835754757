import { Payment } from '@mui/icons-material'
import { Box, Button, Divider, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { getCode, getNames } from 'country-list'
import moment from 'moment'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PaymentAction } from '../../../../redux/actions'
import { FormInput, FormPhoneInput, FormSelect, Modal, ModalBody, ModalFooter, ModalFooterCloseBtn, ModalFooterSaveBtn, ModalHeader } from '../../../../_components'
import { handleInputChange, validateForm } from '../../../../_helpers'
import { validate } from ".";

const Years = () => {
    const years = []
    const dateStart = moment()
    const dateEnd = moment().add(10, 'y')
    while (dateEnd.diff(dateStart, 'years') >= 0) {
        years.push({ "label": dateStart.format('YYYY'), "value": dateStart.format('YYYY') })
        dateStart.add(1, 'year')
    }
    return years
}

const Months = () => {
    const months = []
    for (var i = 1; i <= 12; i++) {
        const month = (i < 10) ? (`0${i}`) : i;
        months.push({ "label": month, "value": month })
    }
    return months
}

// Get country select list
const getCountrySelectList = () => {
    const countries = getNames();
    return countries.map(country => ({
        value: country,
        label: country
    }));
}

const years = Years();
const months = Months();

const inputs = { number: '', card_holder: '', exp_month: '', exp_year: '', cvc: '', name: '', address: '', city: '', state: '', pincode: '', country: '', country_code: '', email: '', mobile_number: '' }

const useStyles = makeStyles((theme) => ({
    titleContainer: {
        marginBottom: theme.spacing(2),
        borderBottom: `1px solid #b0bec5`
    },
    headIcon: {
        paddingRight: theme.spacing(1)
    },
    headTitle: {
        fontWeight: 900,
        paddingBottom: theme.spacing(1),
    },
    headButton: {
        margin: '0 5px',
    },
    paymentPreferenceBox: {
        padding: theme.spacing(2)
    },
    paymentPreferenceContainer: {
        marginBottom: theme.spacing(2)
    },
    paymentPrefercenceTitle: {
        paddingBottom: theme.spacing(1)
    },
    paymentMethodTitle: {
        marginTop: theme.spacing(2)
    },
    table: {
        marginBottom: theme.spacing(2)
    },
    creditCardDetailsGrid: {
        paddingBottom: theme.spacing(2)
    }
}));


function AddUpdateCreditCard() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();

    const [show, setShow] = useState(false);

    const classes = useStyles();

    const { user } = useSelector((state) => state.UserReducer);

    const addUpdateCard = (params) => dispatch(PaymentAction.addUpdateCard(params));
    const getPaymentDetails = () => dispatch(PaymentAction.getPaymentDetails());
    const setDefaultPayment = (params) => dispatch(PaymentAction.setDefaultPaymentMethod(params));

    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs, country: '' });
    const [action, setAction] = useState({ isProcessing: false, isSubmitted: false });

    const [showLoader, setShowLoader] = useState(false)
    const [defaultPaymentModal, setDefaultPaymentModal] = useState(false)

    useEffect(() => {
        setData({
            ...inputs,
            name: user.name,
            card_holder: user.name,
            email: user.email,
            mobile_number: user.mobile_number,
            country: ((user.billing_address) ? (user.billing_address.country) : ''),
            address: ((user.billing_address) ? (user.billing_address.address) : ''),
            city: ((user.billing_address) ? (user.billing_address.city) : ''),
            pincode: ((user.billing_address) ? (user.billing_address.pincode) : ''),
            state: ((user.billing_address) ? (user.billing_address.state) : ''),
            country_code: ((user.billing_address) ? (getCode(user.billing_address.country)) : '')
        })
    }, [user])


    /**
    * function description
    * @param Null
    * @return view
    * @author Akshay N
    * @created_at 
    */
    const handleChange = (e) => {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value)) });
        data[name] = value;
        if (name === 'country') {
            data['country_code'] = value ? getCode(value) : '';
        }
        setData({ ...data });
        console.log(data)
    }

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        setAction({ ...action, isSubmitted: true, isProcessing: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);
        if (!valid) {
            setErrors(formErrors);
            setAction({ ...action, isSubmitted: true, isProcessing: false });
            return;
        }

        dispatchAddUpdateCard(data);
    }

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    const dispatchAddUpdateCard = (formData) => {
        console.log(formData, 'formData');
        addUpdateCard(formData).then((response) => {
            if (response.status === 1) {
                setShow(false);
                setAction({ isSubmitted: false, isProcessing: false });
                setErrors({ ...response.errors })
                if (!response.data.default_payment) {
                    setDefaultPaymentModal(true);
                } else {
                    getPaymentDetails();
                }
            } else {
                setAction({ isSubmitted: true, isProcessing: false });
                setErrors({ ...response.errors })
            }
        });
    }

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    const addUpdateCardModal = (e) => {
        e.preventDefault();
        setData({
            ...inputs,
            name: user.name,
            card_holder: user.name,
            email: user.email,
            mobile_number: user.mobile_number,
            country: ((user.billing_address) ? (user.billing_address.country) : ''),
            address: ((user.billing_address) ? (user.billing_address.address) : ''),
            city: ((user.billing_address) ? (user.billing_address.city) : ''),
            pincode: ((user.billing_address) ? (user.billing_address.pincode) : ''),
            state: ((user.billing_address) ? (user.billing_address.state) : ''),
            country_code: ((user.billing_address) ? (getCode(user.billing_address.country)) : '')
        });
        setShow(true);
    }

    /**
     * function description
     * @param Null
     * @return view
     * @author Akshay N
     * @created_at 
     */
    const onClose = () => {
        setShow(false);
        setErrors({});
        setDefaultPaymentModal(false);
        getPaymentDetails();
    }


    const setDefaultPaymentMethod = (e) => {
        setShowLoader(true);
        setDefaultPayment({ method: 'card' }).then(() => {
            getPaymentDetails();
            setDefaultPaymentModal(false);
            setShowLoader(false);
        })
    }



    return (
        <Box>

            {/* BEGIN: Confirmation modal for setting default payment method */}
            <Modal isDialogOpen={defaultPaymentModal} onClose={onClose} loader={showLoader} size="md">
                <ModalHeader id="defaultPaymentMethodModal" title="Default Payment Method" onClose={onClose} />
                <ModalBody className="text-center">
                    <h1>New Card Added</h1>
                    <p>{`Do you want to change, card as your default payment method?`}</p>
                </ModalBody>
                <ModalFooter>
                    <Button variant="contained" color="primary" size="small" className={classes.button} onClick={setDefaultPaymentMethod}>Confirm</Button>
                    <Button variant="contained" color="secondary" size="small" className={classes.button} onClick={onClose}>Close</Button>
                </ModalFooter>
            </Modal>
            {/* END: Confirmation modal for setting default payment method */}

            <Modal isDialogOpen={show} size={`lg`} onClose={onClose} className={`addUpdateCreditCard`}>
                <form onSubmit={handleSubmit} noValidate className='ns-form'>
                    <ModalHeader id="addUpdateCreditCardModal" title="Add Credit Card Details" onClose={onClose} />
                    <ModalBody>
                        <Grid container spacing={2} className={classes.creditCardDetailsGrid}>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="Credit Card Number"
                                    name="number"
                                    tabIndex={1}
                                    value={data.number}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.number)) ? (errors.number) : ''}
                                    maxLength={16}
                                    maskType={'digit-only'}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="Card Holder Name"
                                    name="card_holder"
                                    tabIndex={2}
                                    value={data.card_holder}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.card_holder)) ? (errors.card_holder) : ''}
                                    maxLength={20}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormSelect
                                    label="Expiration Month"
                                    name="exp_month"
                                    onChange={handleChange}
                                    value={data.exp_month}
                                    error={(action.isSubmitted && (errors.exp_month)) ? (errors.exp_month) : ''}
                                    data={months}
                                    tabIndex={3}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormSelect
                                    label="Expiration Year"
                                    name="exp_year"
                                    onChange={handleChange}
                                    value={data.exp_year}
                                    error={(action.isSubmitted && (errors.exp_year)) ? (errors.exp_year) : ''}
                                    data={years}
                                    tabIndex={4}
                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <FormInput
                                    type="password"
                                    label="CVC"
                                    name="cvc"
                                    tabIndex={5}
                                    value={data.cvc}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.cvc)) ? (errors.cvc) : ''}
                                    maxLength={3}
                                    maskType={'digit-only'}
                                />
                            </Grid>
                        </Grid>
                        <Divider />
                        <Grid container spacing={2} style={{ marginTop: `20px` }} >
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="Full Name"
                                    name="name"
                                    tabIndex={6}
                                    value={data.name}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.name)) ? (errors.name) : ''}
                                    maxLength={100}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="Address"
                                    name="address"
                                    tabIndex={7}
                                    value={`${data.address}`}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.address)) ? (errors.address) : ''}
                                    maxLength={100}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="City"
                                    name="city"
                                    tabIndex={9}
                                    value={data.city}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.city)) ? (errors.city) : ''}
                                    maxLength={100}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="State"
                                    name="state"
                                    tabIndex={10}
                                    value={data.state}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.state)) ? (errors.state) : ''}
                                    maxLength={100}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormInput
                                    label="Pincode"
                                    name="pincode"
                                    tabIndex={11}
                                    value={data.pincode}
                                    onChange={handleChange}
                                    error={(action.isSubmitted && (errors.pincode)) ? (errors.pincode) : ''}
                                    maskType={'digit-only'}
                                    maxLength={10}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormSelect
                                    label="Country"
                                    name="country"
                                    onChange={handleChange}
                                    value={data.country}
                                    error={(action.isSubmitted && (errors.country)) ? (errors.country) : ''}
                                    data={getCountrySelectList()}
                                    tabIndex={12}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormPhoneInput
                                    tabIndex={13}
                                    label='Mobile Number'
                                    name='mobile_number'
                                    value={data.mobile_number}
                                    error={action.isSubmitted && errors.mobile_number ? errors.mobile_number : ''}
                                    onChange={handleChange}
                                    maskType={`digit-only`}
                                    inputProps={{ maxLength: 12 }}
                                    autoComplete={`mobile_number`}
                                    placeholder="Enter phone number"
                                />
                            </Grid>
                        </Grid>
                    </ModalBody>
                    <ModalFooter>
                        <ModalFooterSaveBtn tabIndex={14} loading={action.isProcessing} text="Ok" />
                        <ModalFooterCloseBtn tabIndex={15} onClose={onClose} text="Close" />
                    </ModalFooter>
                </form>
            </Modal>
            <Button style={{ marginTop: '10px' }} variant="contained" color="primary" startIcon={<Payment>Add</Payment>} onClick={addUpdateCardModal}>Add Credit Card</Button>
        </Box >
    )
}


export { AddUpdateCreditCard }
