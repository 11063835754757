import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { RegisterAction, SignInAction } from '../../../redux/actions';
import { PreAuthRoutes } from '../../../routes';
import { FormInput, SubmitButton, SweetAlert, FormSelect } from '../../../_components';
import { APP_NAME, handleInputChange, preRegisterRoutes, validateForm } from '../../../_helpers';
import { PreSignIn } from '../../../_layouts/pre-signin';
import { Header } from '../../../_layouts/pre-signin/header';
import { validate } from '.';
const { getNames } = require('country-list');

function BillingAddress() {

    /** Initialize plugins and variables */
    const dispatch = useDispatch();
    const history = useHistory();
    const { search } = useLocation();

    const params = new URLSearchParams(search);
    const approvalKey = params.get('aKey'); // invoice id from url

    // Initialize form input values to null
    const inputs = { address: '', city: '', state: '', country: '', pincode: '', approval_key: approvalKey };

    // Get country select list
    const getCountrySelectList = () => {
        const countries = getNames();
        return countries.map(country => ({
            value: country,
            label: country
        }));
    }

    /** Redux reducer state */
    const { add_billing_address_loading: isLoading, add_billing_address_errors: addBillingAddressErrors } = useSelector((state) => state.RegisterReducer);

    /** Redux reducer actions */
    const addBillingAddress = (params) => dispatch(RegisterAction.addBillingAddress(params));

    /** Initialize and declare state */
    const [data, setData] = useState({ ...inputs });
    const [errors, setErrors] = useState({ ...inputs });
    const [action, setAction] = useState({ isSubmitted: false });

    useEffect(() => {
        setErrors({ ...addBillingAddressErrors });
    }, [addBillingAddressErrors])


    useEffect(() => {

        if (approvalKey) {
            SignInAction.getUserStatus({ approval_key: approvalKey })
                .then((data) => {
                    if (data && data.status === 1) {
                        let brand = data.data;
                        const redirectRoute = preRegisterRoutes(brand);
                        history.push(redirectRoute);
                    } else {
                        history.push({ pathname: PreAuthRoutes('login').path, search: `` });
                    }
                })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    /**
     * function to handle input changes and alter the value
     * @param object e input object with name and value
     * @author Akshay N
     * @created_at 30 May 2022
     */
    function handleChange(e) {
        const { name, value } = handleInputChange(e);
        setErrors({ ...errors, ...(validate(name, value, data)) });
        data[name] = value;
        setData({ ...data });
    }

    /**
     * function to handle submit
     * @param object e form object
     * @author Akshay N
     * @created_at 30 May 2022
     */
    function handleSubmit(e) {
        e.preventDefault();
        setAction({ isSubmitted: true });
        const formErrors = {};
        for (const [name, value] of Object.entries(data)) {
            const inputError = validate(name, value, data);
            formErrors[name] = inputError[name];
        }
        let valid = validateForm(formErrors);

        if (!valid) {
            setErrors(formErrors);
            setAction({ isSubmitted: true });
            return;
        }
        dispatchAddBillingAddressAction();
    }

    /**
     * function to dispatch sign in
     * @author Akshay N
     * @created_at 30 May 2022
     */
    async function dispatchAddBillingAddressAction() {
        const isUpdated = await addBillingAddress(data);
        if (isUpdated && isUpdated.status === 1) {
            let brand = isUpdated.data;
            if (brand.status === 'active') {
                SweetAlert.fire({
                    title: 'Please verify your email',
                    text: "Check on your registered email to complete the registration process",
                    icon: 'success',
                    confirmButtonText: 'Ok!',
                    reverseButtons: true,
                    showCancelButton: false,
                    allowOutsideClick: false
                }).then((result) => {
                    if (result.isConfirmed) {
                        const redirectRoute = preRegisterRoutes(brand);
                        history.push(redirectRoute);
                    }
                })
            } else {
                const redirectRoute = preRegisterRoutes(brand);
                history.push(redirectRoute);
            }
        }
    }


    return (
        <React.Fragment>
            <Helmet>
                <title>{`${APP_NAME} | ${PreAuthRoutes('billing_address').name}`}</title>
            </Helmet>
            <PreSignIn>
                <form onSubmit={handleSubmit} >
                    <Grid container spacing={3}>
                        <Grid item xs={5} sm={5} m={0} p={0}>
                            <Header title={`${PreAuthRoutes('billing_address').name}`} />
                        </Grid>
                        <Grid item xs={7} sm={7} >
                            <Grid container spacing={2} >
                                <Grid item xs={12} sm={12} p="0">
                                    <Typography variant="h4" fontWeight="medium" color="white" align="center" mt={2} >{`${PreAuthRoutes('billing_address').name}`}</Typography>
                                    <Typography variant="subtitle2" align='center'>Please add your billing address.</Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} p="0">
                                    <FormInput
                                        tabIndex={1}
                                        label='Address'
                                        name='address'
                                        value={data.address}
                                        error={action.isSubmitted && errors.address ? errors.address : ''}
                                        onChange={handleChange}
                                        autoFocus={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} p="0">
                                    <FormInput
                                        tabIndex={2}
                                        label='City'
                                        name='city'
                                        value={data.city}
                                        error={action.isSubmitted && errors.city ? errors.city : ''}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} p="0">
                                    <FormInput
                                        tabIndex={3}
                                        label='State'
                                        name='state'
                                        value={data.state}
                                        error={action.isSubmitted && errors.state ? errors.state : ''}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} p="0">
                                    <FormSelect
                                        tabIndex={1}
                                        label={`Country`}
                                        name={`country`}
                                        placeholder={`Select country`}
                                        onChange={handleChange}
                                        data={getCountrySelectList()}
                                        value={data.country}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} p="0">
                                    <FormInput
                                        tabIndex={5}
                                        label='Pincode'
                                        name='pincode'
                                        value={data.pincode}
                                        error={action.isSubmitted && errors.pincode ? errors.pincode : ''}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={`flex`} alignItems={`center`} justifyContent={`center`}>
                                    <SubmitButton label={'Add Billing Address'} fullWidth loading={isLoading} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </PreSignIn>
        </React.Fragment>
    )
}

export { BillingAddress };