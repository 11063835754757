import React from 'react'
import { Logo } from '../../../_components/logo'
import { Box } from '../../../_components'
import { Divider, Typography } from '@mui/material'

function Header({ title }) {
    return (
        <React.Fragment>
            <Box variant="gradient" bgColor="dark" borderRadius="lg" coloredShadow="dark" mt={0} pt={4} pb={4} sx={{ width: `100%`, marginTop: { md: -8 }, minHeight: `100%`, display: `flex`, alignItems: `center`, justifyContent: `center` }}>
                <Box>
                    <Logo height="45" fill="white" />
                    <Divider sx={{ backgroundColor: `transparent`, backgroundImage: `linear-gradient(to right, rgba(52, 71, 103, 0), #ffffff, rgba(52, 71, 103, 0))!important` }} />
                    <Typography variant="h6" fontWeight="medium" align="center" color="common.white">BRAND PORTAL</Typography>
                </Box>
            </Box>
        </React.Fragment>
    )
}

export { Header }
