import React, { useEffect, useState } from "react"
import { Email, Facebook, LinkedIn, WhatsApp } from "@mui/icons-material";
import { ListItemIcon, MenuItem } from "@mui/material";
import { EmailShareButton, FacebookShareButton, LinkedinShareButton, WhatsappShareButton } from "react-share";
const { INFLUENCER_APP_URL } = require("../../../../_helpers/config");

function PromoShare({ promo_details={}, ...props }) {

    /** Initialize props values */
    const { promoId,  } = props;
    const { name } = promo_details;

    const [link, setLink] = useState('');

    useEffect(() => {
        if (promoId) {
            setLink(`${INFLUENCER_APP_URL}/share/promo/${promoId}`);
        }
    }, [promoId])
    
    /**
     * function email share
     * @return view
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    const EmailShare = () => {
        return(
        <EmailShareButton 
            subject={`Promo - ${name ?? ''}`} 
            url={link} 
        >
            <React.Fragment>
                <ListItemIcon>
                    <Email fontSize="small" />
                </ListItemIcon>
                Email
            </React.Fragment>
            
        </EmailShareButton>
        )
    }

    /**
     * function facebook share
     * @return view
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    const FacebookShare = () => {
        return(
        <FacebookShareButton 
            quote={`Promo - ${name ?? ''}`}
            url={link}
        >
            <ListItemIcon>
                <Facebook fontSize="small" />
            </ListItemIcon>
                Facebook
        </FacebookShareButton>
        )
    }

     /**
     * function linkedin share
     * @return view
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    const LinkedInShare = () => {
        return(
        <LinkedinShareButton 
            title={`Promo - ${name ?? ''}`}
            url={link}
        >
            <ListItemIcon>
                <LinkedIn fontSize="small" />
            </ListItemIcon>
                LinkedIn
        </LinkedinShareButton>
        )
    }

    /**
     * function linkedin share
     * @return view
     * @author Akshay N 
     * @created_at 30 May 2022
     */
    const WhatsAppShare = () => {
        return(
        <WhatsappShareButton 
            title={`Promo - ${name ?? ''}`}
            url={link}
        >
            <ListItemIcon>
                <WhatsApp fontSize="small" />
            </ListItemIcon>
                WhatsApp
        </WhatsappShareButton>
        )
    }



    return (
        <React.Fragment>
            <MenuItem disabled={link ? false : true} onClick={(e) => {e.stopPropagation();}} key={1}>
                <EmailShare />
            </MenuItem>
            <MenuItem disabled={link ? false : true} onClick={(e) => {e.stopPropagation();}} key={2}>
                <FacebookShare />
            </MenuItem>
            <MenuItem disabled={link ? false : true} onClick={(e) => {e.stopPropagation();}} key={3}>
                <LinkedInShare />
            </MenuItem>
            <MenuItem disabled={link ? false : true} onClick={(e) => {e.stopPropagation();}} key={4}>
                <WhatsAppShare />
            </MenuItem>
        </React.Fragment>
    )
}

export { PromoShare }