
import { makeStyles } from "@mui/styles";

const FormPhoneInputStyle = makeStyles((theme) => ({

    formControl: {
        borderRadius: `50%`,
    },
    label: {
        padding: `10px`,
        color: theme.palette.text.primary,
        fontWeight: 600,
        fontSize: 14,
        letterSpacing: `0.5px`
    },
    intlTelInputs: {
        height: `47px`,
        width: '100%',
        '& .iti-flag': {
            // marginTop: '10px',
        },
        '& .selected-dial-code': {
            paddingLeft: '10px !important',
        },
        '& .country-list':{
            color: 'inherit',
            backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900]
        }
    },

    inputClass: {
        width: '100%',
        height: '100%',
        borderRadius: '.25rem',
        border: '1px solid #ced4da',
        color: 'inherit',
        backgroundColor: 'inherit'
    },
    info:
    {
        marginLeft: `0!important`,
        fontSize: 10,
        marginTop: 0,
        padding: 0,
        color: theme.palette.text.secondary,
    }
}))

export { FormPhoneInputStyle };